import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import { BlogArticle, GetUrlFromArticle } from "../../Data/BlogArticles";
import BlogAuthorFinnFreitag from "../../Data/BlogAuthorProps";
import { AuthorProps } from "../../Pages/BlogPage/BlogAuthor";
import BlogJavaCode from "../../Pages/BlogPage/BlogJavaCode";
import BlogPage from "../../Pages/BlogPage/BlogPage";
import BlogPicture from "../../Pages/BlogPage/BlogPicture";
import InsertionSortBlogPage from "../InsertionSortBlogPage/InsertionSortBlogPage";

export default function SelectionSortBlogPage(): BlogArticle {
    var url: string = "selectionsort";
    var title: string = "Selection Sort";
    var imageUrl: string = "/assets/blog/selectionsort/header.jpg";
    var author: AuthorProps = BlogAuthorFinnFreitag();
    var keywords: string = "sort, algorithm, selection";
    var description: string = "Selection sort is one of the simplest sorting algorithms and serves as a great introduction to the fundamentals of sorting. While it may not be the most efficient method for handling large datasets, its straightforward approach provides a clear understanding of basic sorting principles.";
    var releaseDate: string = "26.05.2024";
    return {
        Title: title,
        UrlName: url,
        ImageUrl: imageUrl,
        Keywords: keywords,
        Description: description,
        ReleaseDate: releaseDate,
        Author: author,
        ArticlePage: (
            <BlogPage
                title={title}
                description={description}
                keywords={keywords}
                releaseDate={releaseDate}
                imageUrl={imageUrl}
                author={author}>
                <div>

<h2>How Selection Sort Works</h2>
<p>
Selection sort operates by iterating through the input
list and moving the smallest remaining unsorted value
to the current position. This process continues until
the entire list is sorted.
</p>

<h3>Step-by-step breakdown:</h3>
<ol>
<li><strong>Start with the first element:</strong><br />Consider the first element as the current position.</li>
<li><strong>Find the smallest element:</strong><br />Look for the smallest element in the remaining unsorted portion of the list.</li>
<li><strong>Swap elements:</strong><br />Swap the smallest element found with the element at the current position.</li>
<li><strong>Move to the next position:</strong><br />Repeat the process for the next position in the list.</li>
<li><strong>Continue:</strong><br />Continue this process until the entire list is sorted.</li>
</ol>

<BlogPicture
    imageUrl="/assets/blog/selectionsort/selectionsortdark.gif"
    altText="Selectionsort Animation"
    height={200}
    description="Animation of Selection Sort" />

<h2>Performance and Practicality</h2>
<p>
Selection sort is not the most efficient sorting algorithm,
particularly for large datasets. Its time complexity
is <i>O{"("}n<sup>2</sup>{")"}</i>, meaning that the time
required to sort the list grows quadratically with the number
of elements. However, selection sort can be quite efficient
for small datasets or specific educational purposes.
</p>

<h3>When to Use Selection Sort</h3>
<ul>
<li><strong>Educational Purposes:</strong><br />Ideal for teaching basic sorting concepts and algorithmic thinking.</li>
<li><strong>Small Datasets:</strong><br />Suitable for small lists where its simplicity outweighs its inefficiency.</li>
<li><strong>Fixed Memory Usage:</strong><br />Selection sort has the advantage of requiring a constant amount of memory, making it suitable in environments where memory usage is a concern.</li>
</ul>

<h3>Alternatives to Selection Sort</h3>
<p>For practical purposes and larger datasets, more efficient sorting algorithms are preferred:</p>
<ul>
<li><strong>Quicksort:</strong><br />A fast, comparison-based sorting algorithm that uses divide-and-conquer.</li>
<li><strong>Timsort:</strong><br />A hybrid sorting algorithm derived from merge sort and <Hyperlink href={GetUrlFromArticle(InsertionSortBlogPage())} text="insertion sort" textStyle />, used in Python's built-in sort function.</li>
<li><strong>Merge Sort:</strong><br />A stable, comparison-based sorting algorithm that also uses divide-and-conquer.</li>
</ul>

<h2>Selection Sort Code {"("}Java{")"}</h2>
<BlogJavaCode>
{`public static void selectionSort(int[] array) {
    int n = array.length;
    for (int w = 0; w < n - 1; w++) {
        int j = w;
        for(int k = w + 1; k < n; k++) {
            if(array[k] < array[j]) {
                j = k;
            }
        }
        int temp = array[w];
        array[w] = array[j];
        array[j] = temp;
    }
}`}
</BlogJavaCode>

<h2>Selection Sort Structogram</h2>
<BlogPicture
    imageUrl="/assets/blog/selectionsort/selectionsortdark.png"
    altText="Selectionsort Structogram"
    height={600}
    description="Structogram of Selection Sort" />

<h2>Conclusion</h2>
<p>
While selection sort may not be the best choice for
large datasets due to its <i>O{"("}n<sup>2</sup>{")"}</i> time
complexity, it remains an important algorithm in the realm of
computer science education. Its simple yet effective approach
to sorting provides a stepping stone to understanding more
advanced algorithms. Whether you're a beginner learning about
sorting or an educator explaining fundamental concepts, selection
sort serves as a valuable tool.
</p>

                </div>
            </BlogPage>
    )};
}