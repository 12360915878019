import React, { useState } from 'react';
import styles from './Timeline.module.scss';

type TimelineProps = {
    entries: [string, React.ReactNode][];
}

// https://codepen.io/TajShireen/pen/JjGvVzg
export default function Timeline(props: TimelineProps) {
    return (
        <ul className={styles.sessions}>
            {props.entries.map((value) => {
                return (
                    <li className={styles.entry}>
                        <div className={styles.time}>{value[0]}</div>
                        <div className={styles.content}>{value[1]}</div>
                    </li>
                );
            })}
        </ul>
    );
}