import React, { useState } from 'react';
import Card from "../../Components/Card/Card";
import styles from './Project.module.scss';
import Button from '../Button/Button';
import Hyperlink from '../Hyperlink/Hyperlink';

type ProjectProps = {
    imgUrl: string,
    title: string,
    children: React.ReactNode,
    urls: [string, string, boolean?][],
    authors: [string, string][]
    hint?: string
}

export default function Project(props: ProjectProps) {
    return (
        <Card>
            <div className={styles.cardInner}>
                <img src={props.imgUrl} alt="Logo" className={styles.image} />
                <div className={styles.infobox}>
                    <div className={styles.headerSplitter}>
                        <div className={styles.subbox}>
                            <h1 className={styles.title}>{props.title}</h1>
                            <span>by {props.authors.map((author) => {
                                return (<span><Hyperlink text={"@" + author[1]} href={author[0]} newTab={author[1] !== "Finn Freitag"} /> </span>);
                            })}</span>
                        </div>
                        <span className={styles.hint}><i>{props.hint}</i></span>
                    </div>
                    <div>{props.children}</div>
                    <div className={styles.buttonarray}>
                        {props.urls.map((url) => {
                            return (<Button href={url[0]} text={url[1]} newTab={!(url.length === 3 && url[2] === false)} />);
                        })}
                    </div>
                </div>
            </div>
        </Card>
    );
}