export class Haschiwokakero {
    public static readonly EMPTYSLOT: number = -1;
    public bridges: [x1: number, y1: number, x2: number, y2: number, double: boolean][] = [];
    public readonly size: number = 7;

    constructor(size: number = 7, generateField: boolean = false){
        this.size = size;
        this.clear();
        if(generateField) this.generate();
    }

    clear(){
        this.bridges = [];
    }

    public doBridgeCycle(x1: number, y1: number, x2: number, y2: number){
        for(var i = 0; i < this.bridges.length; i++){
            var bridge = this.bridges[i];
            if(bridge[0] == x1 && bridge[1] == y1 && bridge[2] == x2 && bridge[3] == y2){
                if(bridge[4]) {
                    this.bridges.splice(i, 1);
                    return;
                } else {
                    this.bridges[i] = [x1, y1, x2, y2, true];
                    return;
                }
            }
        }
        this.bridges.push([x1, y1, x2, y2, false]);
    }

    public isValidSolved(haschi: Haschiwokakero): boolean{
        if(haschi.size !== this.size) return false;
        for(var x = 0; x < this.size; x++){
            for(var y = 0; y < this.size; y++){
                if(haschi.getBridgeCount(x, y) !== this.getBridgeCount(x, y)) return false;
            }
        }
        return true;
    }

    getBridge(x1: number, y1: number, x2: number, y2: number): 0|1|2 {
        for(var i = 0; i < this.bridges.length; i++){
            var bridge = this.bridges[i];
            if(bridge[0] == x1 && bridge[1] == y1 && bridge[2] == x2 && bridge[3] == y2){
                if(bridge[4]) return 2;
                return 1;
            }
        }
        return 0;
    }

    getBridgeCount(x: number, y: number): number {
        var count: number = 0;
        for(var i = 0; i < this.bridges.length; i++){
            var bridge = this.bridges[i];
            if((bridge[0] == x && bridge[1] == y) || (bridge[2] == x && bridge[3] == y)){
                count++;
                if(bridge[4])count++;
            }
        }
        return count;
    }

    generate(){
        var nodes: [x: number, y: number][] = [[Math.floor(Math.random() * 8), Math.floor(Math.random() * 8)]];
        var nodeCount = Math.floor(Math.random() * this.size + this.size * 2)*3;
        for(var i = 0; i < nodeCount; i++){
            var node = nodes[Math.floor(Math.random() * nodes.length)];
            var freeNeighbors = this.getFreeNeighbors(...node);
            if(freeNeighbors.length === 0) continue;
            var newNode = freeNeighbors[Math.floor(Math.random() * freeNeighbors.length)];
            if(node[0] === newNode[0] && node[1] === newNode[1]) continue;
            nodes.push(newNode);
            var double = Math.floor(Math.random() * 2) == 1;
            this.bridges.push([node[0], node[1], newNode[0], newNode[1], double]);
        }
    }

    getFreeNeighbors(x: number, y: number): [x: number, y:number][] {
        var neighbors = [[x, y - 1], [x - 1, y], [x, y + 1], [x + 1, y]];
        var res: [x: number, y: number][] = [];
        neighbors.forEach((element) => {
            if(element[0] >= 0 && element[0] < this.size && element[1] >= 0 && element[1] < this.size)
            if(this.isFree(element[0], element[1])) res.push([element[0], element[1]]);
        });
        return res;
    }

    isFree(x: number, y: number): boolean {
        return this.getBridgeCount(x, y) === 0;
    }
}