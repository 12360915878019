import { RouteObject } from "react-router-dom";
import BubbleSortBlogPage from "../BlogPages/BubbleSortBlogPage/BubbleSortBlogPage";
import InsertionSortBlogPage from "../BlogPages/InsertionSortBlogPage/InsertionSortBlogPage";
import SelectionSortBlogPage from "../BlogPages/SelectionSortBlogPage/SelectionSortBlogPage";
import { AuthorProps } from "../Pages/BlogPage/BlogAuthor"

export type BlogArticle = {
    ArticlePage: React.ReactNode,
    UrlName: string,
    Title: string,
    Description: string,
    ImageUrl: string,
    ReleaseDate: string,
    Keywords: string,
    Author: AuthorProps
}

export default function GetBlogArticles(){
    return [
        BubbleSortBlogPage(),
        InsertionSortBlogPage(),
        SelectionSortBlogPage(),
    ];
}

export function GetRouterList(){
    var list: RouteObject[] = [];
    GetBlogArticles().forEach(element => {
        list.push({
            path: GetUrlFromArticle(element),
            element: element.ArticlePage
        })
    });
    return list;
}

export function GetUrlFromArticle(article: BlogArticle): string {
    return "/blog/" + article.UrlName;
}