import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./SudokuSolverPage.module.scss"
import buttonStyles from "../../Components/Button/Button.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import { useEffect } from "react";
import { Sudoku, SudokuGenerator } from "../SudokuPage/sudoku";
import {Helmet} from 'react-helmet';

var inputs: HTMLInputElement[][];
var sudoku: Sudoku;

export default function SudokuSolverPage(){
    useEffect(()=>{
        inputs = [];
        for(let x = 0; x < 9; x++){
            inputs[x] = [];
            for(let y = 0; y < 9; y++){
                inputs[x][y] = removeNull(document.getElementById("" + x + "" + y));
                inputs[x][y].addEventListener('input',somethingChanged);
            }
        }
    },[]);

    return(
        <BasePage>
            <Helmet>
                <title>Sudoku Solver | Finn Freitag</title>
                <meta name="description" content="Sudoku solver by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, sudoku, solver, tool" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardHalf}>
                <Card>
                    <div>
                        <div className={styles.block}>{/* Sudoku div */}
                            <div className={styles.row}>{/* Block row 1 */}
                                <div className={styles.block}>{/* Block 1 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="00"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="10"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="20"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="01"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="11"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="21"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="02"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="12"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="22"></input>{/* field 3 */}
                                    </div>
                                </div>
                                <div className={styles.block}>{/* Block 2 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="30"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="40"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="50"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="31"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="41"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="51"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="32"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="42"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="52"></input>{/* field 3 */}
                                    </div>
                                </div>
                                <div className={styles.block}>{/* Block 3 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="60"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="70"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="80"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="61"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="71"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="81"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="62"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="72"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="82"></input>{/* field 3 */}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row}>{/* Block row 2 */}
                                <div className={styles.block}>{/* Block 1 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="03"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="13"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="23"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="04"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="14"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="24"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="05"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="15"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="25"></input>{/* field 3 */}
                                    </div>
                                </div>
                                <div className={styles.block}>{/* Block 2 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="33"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="43"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="53"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="34"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="44"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="54"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="35"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="45"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="55"></input>{/* field 3 */}
                                    </div>
                                </div>
                                <div className={styles.block}>{/* Block 3 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="63"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="73"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="83"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="64"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="74"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="84"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="65"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="75"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="85"></input>{/* field 3 */}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row}>{/* Block row 3 */}
                                <div className={styles.block}>{/* Block 1 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="06"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="16"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="26"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="07"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="17"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="27"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="08"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="18"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="28"></input>{/* field 3 */}
                                    </div>
                                </div>
                                <div className={styles.block}>{/* Block 2 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="36"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="46"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="56"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="37"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="47"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="57"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="38"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="48"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="58"></input>{/* field 3 */}
                                    </div>
                                </div>
                                <div className={styles.block}>{/* Block 3 */}
                                    <div className={styles.row}>{/* row 1 */}
                                        <input type="text" className={styles.field} id="66"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="76"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="86"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 2 */}
                                        <input type="text" className={styles.field} id="67"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="77"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="87"></input>{/* field 3 */}
                                    </div>
                                    <div className={styles.row}>{/* row 3 */}
                                        <input type="text" className={styles.field} id="68"></input>{/* field 1 */}
                                        <input type="text" className={styles.field} id="78"></input>{/* field 2 */}
                                        <input type="text" className={styles.field} id="88"></input>{/* field 3 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.btnMenu}>
                            <button className={buttonStyles.button} onClick={solve}>Solve</button>
                            <button className={buttonStyles.button} onClick={clear}>Clear</button>
                            <button className={buttonStyles.button} onClick={addRequired}>Make inferable</button>
                            <a href="/games/sudoku"><button className={buttonStyles.button}>Play</button></a>
                        </div>
                    </div>
                </Card>
            </div>
        </BasePage>
    );
}

function removeNull(obj: any): any{
    if(obj === null){
        return undefined;
    }
    return obj;
}

function solve(){
    setSudokuToInput(new SudokuGenerator().GenerateValidBasePatternFromSudoku(getSudokuFromInput()));
}

function clear(){
    setSudokuToInput(new Sudoku());
}

function addRequired(){
    var sudoku = getSudokuFromInput();
    let gen = new SudokuGenerator();
    sudoku = gen.GenerateValidBasePatternFromSudoku(sudoku);
    const remaining: { x: number, y: number }[] = [];
    for (let x = 0; x < 9; x++) for (let y = 0; y < 9; y++) remaining.push({ x, y });
    sudoku = gen.MakeHarder(sudoku, remaining);
    setSudokuToInput(sudoku);
}

function somethingChanged(){
    let nums = "123456789";
    for(let y = 0; y < 9; y++){
        for(let x = 0; x < 9; x++){
            if(inputs[x][y].value.length > 1){
                inputs[x][y].value = inputs[x][y].value.substring(0, 1);
            }
            if(!nums.includes(inputs[x][y].value)){
                inputs[x][y].value = "";
            }
        }
    }
}

function getSudokuFromInput() : Sudoku{
    var sudoku = new Sudoku();
    for(let y = 0; y < 9; y++){
        for(let x = 0; x < 9; x++){
            sudoku.set(x, y, Number(inputs[x][y].value));
            if(inputs[x][y].value === "")sudoku.set(x, y, Sudoku.EMPTYSLOT);
        }
    }
    return sudoku;
}

function setSudokuToInput(sudoku : Sudoku){
    for(let y = 0; y < 9; y++){
        for(let x = 0; x < 9; x++){
            inputs[x][y].value = "" + sudoku.get(x, y);
            if(sudoku.get(x, y) === Sudoku.EMPTYSLOT){
                inputs[x][y].value = "";
            }
        }
    }
}