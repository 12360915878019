import BasePage from "../BasePage/BasePage";
import cardStyles from "../../Components/Card/CardStyle.module.scss";
import styles from "./BlogOverviewPage.module.scss";
import Card from "../../Components/Card/Card";
import GetBlogArticles from "../../Data/BlogArticles";
import { Helmet } from "react-helmet";
import Project from "../../Components/Project/Project";

export default function BlogOverviewPage() {
    return (
        <BasePage>
            <Helmet>
                <title>Blog | Finn Freitag</title>
                <meta name="description" content="Blog articles around software development by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, blog, software, article, development" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardTrible}>
                <h1 className={styles.headline}>Blog Articles</h1>
            </div>
            {GetBlogArticles()/*.reverse()*/.map((article) => {return (
                <a href={"/blog/" + article.UrlName} className={styles.link}>
                    <div className={cardStyles.cardHalf}>
                        <Project title={article.Title} imgUrl={article.ImageUrl} hint={article.ReleaseDate} authors={[[article.Author.authorUrl,article.Author.author]]} urls={[["/blog/" + article.UrlName,"Read", false]]}>
                            {article.Description}
                        </Project>
                    </div>
                </a>
            );})}
            <div className={cardStyles.cardTrible}>
                <div className={styles.info}>
                    <div className={styles.legal}>Please note that the author makes no representation or warranty of any kind regarding the accuracy, adequacy, validity, reliability, availability, or completeness of these articles.</div>
                </div>
            </div>
        </BasePage>
    );
}