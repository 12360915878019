import React, { useEffect } from "react";
import styles from "./HaschiwokakeroPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import BasePage from "../BasePage/BasePage";
import { Haschiwokakero } from "./haschiwokakero";
import Card from "../../Components/Card/Card";
import {Helmet} from 'react-helmet';

var haschiwokakero: Haschiwokakero;
var userhaschi: Haschiwokakero;

export default function HaschiwokakeroPage(){
    useEffect(() => {
        haschiwokakero = new Haschiwokakero(7, true);
        userhaschi = new Haschiwokakero(7, false);
        loadIntoGUI(haschiwokakero);

        /*var hovers = Array.from(document.getElementsByClassName("hoverLines")) as SVGPathElement[];
        hovers.forEach((element) => {
            element.addEventListener("click", function (this: SVGPathElement, e: MouseEvent) { bridgeClicked(this.id) });
        })*/
        /*for(var i = 0; i < hovers.length; i++){
            hovers[i].addEventListener('click', () => bridgeClicked(hovers[i].id));
        }*/
    }, []);

    return (
        <BasePage>
            <Helmet>
                <title>Haschiwokakero | Finn Freitag</title>
                <meta name="description" content="Haschiwokakero by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, puzzle, Haschiwokakero" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardHalf}>
                <Card>
                    <div className={styles.container}>
                        <svg className={styles.svg} id="svg" viewBox="0 0 200 200" version="1.1">
                            <g>
                                <path className={styles.backgroundLines} d="M 10,10 H 190" />
                                <path className={styles.backgroundLines} d="M 10,40 H 190" />
                                <path className={styles.backgroundLines} d="M 10,70 H 190" />
                                <path className={styles.backgroundLines} d="M 10,100 H 190" />
                                <path className={styles.backgroundLines} d="M 10,130 H 190" />
                                <path className={styles.backgroundLines} d="M 10,160 H 190" />
                                <path className={styles.backgroundLines} d="M 10,190 H 190" />
                                <path className={styles.backgroundLines} d="M 10,10 V 190" />
                                <path className={styles.backgroundLines} d="M 40,10 V 190" />
                                <path className={styles.backgroundLines} d="M 70,10 V 190" />
                                <path className={styles.backgroundLines} d="M 100,10 V 190" />
                                <path className={styles.backgroundLines} d="M 130,10 V 190" />
                                <path className={styles.backgroundLines} d="M 160,10 V 190" />
                                <path className={styles.backgroundLines} d="M 190,10 V 190" />
                            </g>
                            <g id="singleLineGroup">
                                <path className={styles.singleLines} d="M 10,10 H 40" id="c00c10s" />
                                <path className={styles.singleLines} d="M 40,10 H 70" id="c10c20s" />
                                <path className={styles.singleLines} d="m 70,10 h 30" id="c20c30s" />
                                <path className={styles.singleLines} d="m 100,10 h 30" id="c30c40s" />
                                <path className={styles.singleLines} d="m 130,10 h 30" id="c40c50s" />
                                <path className={styles.singleLines} d="m 160,10 h 30" id="c50c60s" />
                                <path className={styles.singleLines} d="M 10,40 H 40" id="c01c11s" />
                                <path className={styles.singleLines} d="M 40,40 H 70" id="c11c21s" />
                                <path className={styles.singleLines} d="m 70,40 h 30" id="c21c31s" />
                                <path className={styles.singleLines} d="m 100,40 h 30" id="c31c41s" />
                                <path className={styles.singleLines} d="m 130,40 h 30" id="c41c51s" />
                                <path className={styles.singleLines} d="m 160,40 h 30" id="c51c61s" />
                                <path className={styles.singleLines} d="M 10,70 H 40" id="c02c12s" />
                                <path className={styles.singleLines} d="M 40,70 H 70" id="c12c22s" />
                                <path className={styles.singleLines} d="m 70,70 h 30" id="c22c32s" />
                                <path className={styles.singleLines} d="m 100,70 h 30" id="c32c42s" />
                                <path className={styles.singleLines} d="m 130,70 h 30" id="c42c52s" />
                                <path className={styles.singleLines} d="m 160,70 h 30" id="c52c62s" />
                                <path className={styles.singleLines} d="M 10,100 H 40" id="c03c13s" />
                                <path className={styles.singleLines} d="M 40,100 H 70" id="c13c23s" />
                                <path className={styles.singleLines} d="m 70,100 h 30" id="c23c33s" />
                                <path className={styles.singleLines} d="m 100,100 h 30" id="c33c43s" />
                                <path className={styles.singleLines} d="m 130,100 h 30" id="c43c53s" />
                                <path className={styles.singleLines} d="m 160,100 h 30" id="c53c63s" />
                                <path className={styles.singleLines} d="M 10,130 H 40" id="c04c14s" />
                                <path className={styles.singleLines} d="M 40,130 H 70" id="c14c24s" />
                                <path className={styles.singleLines} d="m 70,130 h 30" id="c24c34s" />
                                <path className={styles.singleLines} d="m 100,130 h 30" id="c34c44s" />
                                <path className={styles.singleLines} d="m 130,130 h 30" id="c44c54s" />
                                <path className={styles.singleLines} d="m 160,130 h 30" id="c54c64s" />
                                <path className={styles.singleLines} d="M 10,160 H 40" id="c05c15s" />
                                <path className={styles.singleLines} d="M 40,160 H 70" id="c15c25s" />
                                <path className={styles.singleLines} d="m 70,160 h 30" id="c25c35s" />
                                <path className={styles.singleLines} d="m 100,160 h 30" id="c35c45s" />
                                <path className={styles.singleLines} d="m 130,160 h 30" id="c45c55s" />
                                <path className={styles.singleLines} d="m 160,160 h 30" id="c55c65s" />
                                <path className={styles.singleLines} d="M 10,190 H 40" id="c06c16s" />
                                <path className={styles.singleLines} d="M 40,190 H 70" id="c16c26s" />
                                <path className={styles.singleLines} d="m 70,190 h 30" id="c26c36s" />
                                <path className={styles.singleLines} d="m 100,190 h 30" id="c36c46s" />
                                <path className={styles.singleLines} d="m 130,190 h 30" id="c46c56s" />
                                <path className={styles.singleLines} d="m 160,190 h 30" id="c56c66s" />
                                <path className={styles.singleLines} d="M 10,10 V 40" id="c00c01s" />
                                <path className={styles.singleLines} d="M 10,40 V 70" id="c01c02s" />
                                <path className={styles.singleLines} d="m 10,70 v 30" id="c02c03s" />
                                <path className={styles.singleLines} d="m 10,100 v 30" id="c03c04s" />
                                <path className={styles.singleLines} d="m 10,130 v 30" id="c04c05s" />
                                <path className={styles.singleLines} d="m 10,160 v 30" id="c05c06s" />
                                <path className={styles.singleLines} d="M 40,10 V 40" id="c10c11s" />
                                <path className={styles.singleLines} d="M 40,40 V 70" id="c11c12s" />
                                <path className={styles.singleLines} d="m 40,70 v 30" id="c12c13s" />
                                <path className={styles.singleLines} d="m 40,100 v 30" id="c13c14s" />
                                <path className={styles.singleLines} d="m 40,130 v 30" id="c14c15s" />
                                <path className={styles.singleLines} d="m 40,160 v 30" id="c15c16s" />
                                <path className={styles.singleLines} d="M 70,10 V 40" id="c20c21s" />
                                <path className={styles.singleLines} d="M 70,40 V 70" id="c21c22s" />
                                <path className={styles.singleLines} d="m 70,70 v 30" id="c22c23s" />
                                <path className={styles.singleLines} d="m 70,100 v 30" id="c23c24s" />
                                <path className={styles.singleLines} d="m 70,130 v 30" id="c24c25s" />
                                <path className={styles.singleLines} d="m 70,160 v 30" id="c25c26s" />
                                <path className={styles.singleLines} d="M 100,10 V 40" id="c30c31s" />
                                <path className={styles.singleLines} d="M 100,40 V 70" id="c31c32s" />
                                <path className={styles.singleLines} d="m 100,70 v 30" id="c32c33s" />
                                <path className={styles.singleLines} d="m 100,100 v 30" id="c33c34s" />
                                <path className={styles.singleLines} d="m 100,130 v 30" id="c34c35s" />
                                <path className={styles.singleLines} d="m 100,160 v 30" id="c35c36s" />
                                <path className={styles.singleLines} d="M 130,10 V 40" id="c40c41s" />
                                <path className={styles.singleLines} d="M 130,40 V 70" id="c41c42s" />
                                <path className={styles.singleLines} d="m 130,70 v 30" id="c42c43s" />
                                <path className={styles.singleLines} d="m 130,100 v 30" id="c43c44s" />
                                <path className={styles.singleLines} d="m 130,130 v 30" id="c44c45s" />
                                <path className={styles.singleLines} d="m 130,160 v 30" id="c45c46s" />
                                <path className={styles.singleLines} d="M 160,10 V 40" id="c50c51s" />
                                <path className={styles.singleLines} d="M 160,40 V 70" id="c51c52s" />
                                <path className={styles.singleLines} d="m 160,70 v 30" id="c52c53s" />
                                <path className={styles.singleLines} d="m 160,100 v 30" id="c53c54s" />
                                <path className={styles.singleLines} d="m 160,130 v 30" id="c54c55s" />
                                <path className={styles.singleLines} d="m 160,160 v 30" id="c55c56s" />
                                <path className={styles.singleLines} d="M 190,10 V 40" id="c60c61s" />
                                <path className={styles.singleLines} d="M 190,40 V 70" id="c61c62s" />
                                <path className={styles.singleLines} d="m 190,70 v 30" id="c62c63s" />
                                <path className={styles.singleLines} d="m 190,100 v 30" id="c63c64s" />
                                <path className={styles.singleLines} d="m 190,130 v 30" id="c64c65s" />
                                <path className={styles.singleLines} d="m 190,160 v 30" id="c65c66s" />
                            </g>
                            <g id="doubleLineGroup">
                                <path className={styles.singleLines} d="m 10,8.25 h 30 m -30,3.5 h 30" id="c00c10d" />
                                <path className={styles.singleLines} d="m 40,8.25 h 30 m -30,3.5 h 30" id="c10c20d" />
                                <path className={styles.singleLines} d="m 70,8.25 h 30 m -30,3.5 h 30" id="c20c30d" />
                                <path className={styles.singleLines} d="m 100,8.25 h 30 m -30,3.5 h 30" id="c30c40d" />
                                <path className={styles.singleLines} d="m 130,8.25 h 30 m -30,3.5 h 30" id="c40c50d" />
                                <path className={styles.singleLines} d="m 160,8.25 h 30 m -30,3.5 h 30" id="c50c60d" />
                                <path className={styles.singleLines} d="m 10,38.25 h 30 m -30,3.5 h 30" id="c01c11d" />
                                <path className={styles.singleLines} d="m 40,38.25 h 30 m -30,3.5 h 30" id="c11c21d" />
                                <path className={styles.singleLines} d="m 70,38.25 h 30 m -30,3.5 h 30" id="c21c31d" />
                                <path className={styles.singleLines} d="m 100,38.25 h 30 m -30,3.5 h 30" id="c31c41d" />
                                <path className={styles.singleLines} d="m 130,38.25 h 30 m -30,3.5 h 30" id="c41c51d" />
                                <path className={styles.singleLines} d="m 160,38.25 h 30 m -30,3.5 h 30" id="c51c61d" />
                                <path className={styles.singleLines} d="m 10,68.25 h 30 m -30,3.5 h 30" id="c02c12d" />
                                <path className={styles.singleLines} d="m 40,68.25 h 30 m -30,3.5 h 30" id="c12c22d" />
                                <path className={styles.singleLines} d="m 70,68.25 h 30 m -30,3.5 h 30" id="c22c32d" />
                                <path className={styles.singleLines} d="m 100,68.25 h 30 m -30,3.5 h 30" id="c32c42d" />
                                <path className={styles.singleLines} d="m 130,68.25 h 30 m -30,3.5 h 30" id="c42c52d" />
                                <path className={styles.singleLines} d="m 160,68.25 h 30 m -30,3.5 h 30" id="c52c62d" />
                                <path className={styles.singleLines} d="m 10,98.25 h 30 m -30,3.5 h 30" id="c03c13d" />
                                <path className={styles.singleLines} d="m 40,98.25 h 30 m -30,3.5 h 30" id="c13c23d" />
                                <path className={styles.singleLines} d="m 70,98.25 h 30 m -30,3.5 h 30" id="c23c33d" />
                                <path className={styles.singleLines} d="m 100,98.25 h 30 m -30,3.5 h 30" id="c33c43d" />
                                <path className={styles.singleLines} d="m 130,98.25 h 30 m -30,3.5 h 30" id="c43c53d" />
                                <path className={styles.singleLines} d="m 160,98.25 h 30 m -30,3.5 h 30" id="c53c63d" />
                                <path className={styles.singleLines} d="m 10,128.25 h 30 m -30,3.5 h 30" id="c04c14d" />
                                <path className={styles.singleLines} d="m 40,128.25 h 30 m -30,3.5 h 30" id="c14c24d" />
                                <path className={styles.singleLines} d="m 70,128.25 h 30 m -30,3.5 h 30" id="c24c34d" />
                                <path className={styles.singleLines} d="m 100,128.25 h 30 m -30,3.5 h 30" id="c34c44d" />
                                <path className={styles.singleLines} d="m 130,128.25 h 30 m -30,3.5 h 30" id="c44c54d" />
                                <path className={styles.singleLines} d="m 160,128.25 h 30 m -30,3.5 h 30" id="c54c64d" />
                                <path className={styles.singleLines} d="m 10,158.25 h 30 m -30,3.5 h 30" id="c05c15d" />
                                <path className={styles.singleLines} d="m 40,158.25 h 30 m -30,3.5 h 30" id="c15c25d" />
                                <path className={styles.singleLines} d="m 70,158.25 h 30 m -30,3.5 h 30" id="c25c35d" />
                                <path className={styles.singleLines} d="m 100,158.25 h 30 m -30,3.5 h 30" id="c35c45d" />
                                <path className={styles.singleLines} d="m 130,158.25 h 30 m -30,3.5 h 30" id="c45c55d" />
                                <path className={styles.singleLines} d="m 160,158.25 h 30 m -30,3.5 h 30" id="c55c65d" />
                                <path className={styles.singleLines} d="m 10,188.25 h 30 m -30,3.5 h 30" id="c06c16d" />
                                <path className={styles.singleLines} d="m 40,188.25 h 30 m -30,3.5 h 30" id="c16c26d" />
                                <path className={styles.singleLines} d="m 70,188.25 h 30 m -30,3.5 h 30" id="c26c36d" />
                                <path className={styles.singleLines} d="m 100,188.25 h 30 m -30,3.5 h 30" id="c36c46d" />
                                <path className={styles.singleLines} d="m 130,188.25 h 30 m -30,3.5 h 30" id="c46c56d" />
                                <path className={styles.singleLines} d="m 160,188.25 h 30 m -30,3.5 h 30" id="c56c66d" />
                                <path className={styles.singleLines} d="m 8.25,10 v 30 m 3.5,-30 v 30" id="c00c01d" />
                                <path className={styles.singleLines} d="m 8.25,40 v 30 m 3.5,-30 v 30" id="c01c02d" />
                                <path className={styles.singleLines} d="m 8.25,70 v 30 m 3.5,-30 v 30" id="c02c03d" />
                                <path className={styles.singleLines} d="m 8.25,100 v 30 m 3.5,-30 v 30" id="c03c04d" />
                                <path className={styles.singleLines} d="m 8.25,130 v 30 m 3.5,-30 v 30" id="c04c05d" />
                                <path className={styles.singleLines} d="m 8.25,160 v 30 m 3.5,-30 v 30" id="c05c06d" />
                                <path className={styles.singleLines} d="m 38.25,10 v 30 m 3.5,-30 v 30" id="c10c11d" />
                                <path className={styles.singleLines} d="m 38.25,40 v 30 m 3.5,-30 v 30" id="c11c12d" />
                                <path className={styles.singleLines} d="m 38.25,70 v 30 m 3.5,-30 v 30" id="c12c13d" />
                                <path className={styles.singleLines} d="m 38.25,100 v 30 m 3.5,-30 v 30" id="c13c14d" />
                                <path className={styles.singleLines} d="m 38.25,130 v 30 m 3.5,-30 v 30" id="c14c15d" />
                                <path className={styles.singleLines} d="m 38.25,160 v 30 m 3.5,-30 v 30" id="c15c16d" />
                                <path className={styles.singleLines} d="m 68.25,10 v 30 m 3.5,-30 v 30" id="c20c21d" />
                                <path className={styles.singleLines} d="m 68.25,40 v 30 m 3.5,-30 v 30" id="c21c22d" />
                                <path className={styles.singleLines} d="m 68.25,70 v 30 m 3.5,-30 v 30" id="c22c23d" />
                                <path className={styles.singleLines} d="m 68.25,100 v 30 m 3.5,-30 v 30" id="c23c24d" />
                                <path className={styles.singleLines} d="m 68.25,130 v 30 m 3.5,-30 v 30" id="c24c25d" />
                                <path className={styles.singleLines} d="m 68.25,160 v 30 m 3.5,-30 v 30" id="c25c26d" />
                                <path className={styles.singleLines} d="m 98.25,10 v 30 m 3.5,-30 v 30" id="c30c31d" />
                                <path className={styles.singleLines} d="m 98.25,40 v 30 m 3.5,-30 v 30" id="c31c32d" />
                                <path className={styles.singleLines} d="m 98.25,70 v 30 m 3.5,-30 v 30" id="c32c33d" />
                                <path className={styles.singleLines} d="m 98.25,100 v 30 m 3.5,-30 v 30" id="c33c34d" />
                                <path className={styles.singleLines} d="m 98.25,130 v 30 m 3.5,-30 v 30" id="c34c35d" />
                                <path className={styles.singleLines} d="m 98.25,160 v 30 m 3.5,-30 v 30" id="c35c36d" />
                                <path className={styles.singleLines} d="m 128.25,10 v 30 m 3.5,-30 v 30" id="c40c41d" />
                                <path className={styles.singleLines} d="m 128.25,40 v 30 m 3.5,-30 v 30" id="c41c42d" />
                                <path className={styles.singleLines} d="m 128.25,70 v 30 m 3.5,-30 v 30" id="c42c43d" />
                                <path className={styles.singleLines} d="m 128.25,100 v 30 m 3.5,-30 v 30" id="c43c44d" />
                                <path className={styles.singleLines} d="m 128.25,130 v 30 m 3.5,-30 v 30" id="c44c45d" />
                                <path className={styles.singleLines} d="m 128.25,160 v 30 m 3.5,-30 v 30" id="c45c46d" />
                                <path className={styles.singleLines} d="m 158.25,10 v 30 m 3.5,-30 v 30" id="c50c51d" />
                                <path className={styles.singleLines} d="m 158.25,40 v 30 m 3.5,-30 v 30" id="c51c52d" />
                                <path className={styles.singleLines} d="m 158.25,70 v 30 m 3.5,-30 v 30" id="c52c53d" />
                                <path className={styles.singleLines} d="m 158.25,100 v 30 m 3.5,-30 v 30" id="c53c54d" />
                                <path className={styles.singleLines} d="m 158.25,130 v 30 m 3.5,-30 v 30" id="c54c55d" />
                                <path className={styles.singleLines} d="m 158.25,160 v 30 m 3.5,-30 v 30" id="c55c56d" />
                                <path className={styles.singleLines} d="m 188.25,10 v 30 m 3.5,-30 v 30" id="c60c61d" />
                                <path className={styles.singleLines} d="m 188.25,40 v 30 m 3.5,-30 v 30" id="c61c62d" />
                                <path className={styles.singleLines} d="m 188.25,70 v 30 m 3.5,-30 v 30" id="c62c63d" />
                                <path className={styles.singleLines} d="m 188.25,100 v 30 m 3.5,-30 v 30" id="c63c64d" />
                                <path className={styles.singleLines} d="m 188.25,130 v 30 m 3.5,-30 v 30" id="c64c65d" />
                                <path className={styles.singleLines} d="m 188.25,160 v 30 m 3.5,-30 v 30" id="c65c66d" />
                            </g>
                            <g id="hoverLineGroup">
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,0,1,0)}} d="M 10,10 H 40" id="c00c10h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,0,2,0)}} d="M 40,10 H 70" id="c10c20h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,0,3,0)}} d="m 70,10 h 30" id="c20c30h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,0,4,0)}} d="m 100,10 h 30" id="c30c40h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,0,5,0)}} d="m 130,10 h 30" id="c40c50h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,0,6,0)}} d="m 160,10 h 30" id="c50c60h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,1,1,1)}} d="M 10,40 H 40" id="c01c11h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,1,2,1)}} d="M 40,40 H 70" id="c11c21h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,1,3,1)}} d="m 70,40 h 30" id="c21c31h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,1,4,1)}} d="m 100,40 h 30" id="c31c41h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,1,5,1)}} d="m 130,40 h 30" id="c41c51h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,1,6,1)}} d="m 160,40 h 30" id="c51c61h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,2,1,2)}} d="M 10,70 H 40" id="c02c12h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,2,2,2)}} d="M 40,70 H 70" id="c12c22h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,2,3,2)}} d="m 70,70 h 30" id="c22c32h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,2,4,2)}} d="m 100,70 h 30" id="c32c42h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,2,5,2)}} d="m 130,70 h 30" id="c42c52h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,2,6,2)}} d="m 160,70 h 30" id="c52c62h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,3,1,3)}} d="M 10,100 H 40" id="c03c13h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,3,2,3)}} d="M 40,100 H 70" id="c13c23h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,3,3,3)}} d="m 70,100 h 30" id="c23c33h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,3,4,3)}} d="m 100,100 h 30" id="c33c43h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,3,5,3)}} d="m 130,100 h 30" id="c43c53h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,3,6,3)}} d="m 160,100 h 30" id="c53c63h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,4,1,4)}} d="M 10,130 H 40" id="c04c14h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,4,2,4)}} d="M 40,130 H 70" id="c14c24h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,4,3,4)}} d="m 70,130 h 30" id="c24c34h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,4,4,4)}} d="m 100,130 h 30" id="c34c44h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,4,5,4)}} d="m 130,130 h 30" id="c44c54h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,4,6,4)}} d="m 160,130 h 30" id="c54c64h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,5,1,5)}} d="M 10,160 H 40" id="c05c15h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,5,2,5)}} d="M 40,160 H 70" id="c15c25h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,5,3,5)}} d="m 70,160 h 30" id="c25c35h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,5,4,5)}} d="m 100,160 h 30" id="c35c45h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,5,5,5)}} d="m 130,160 h 30" id="c45c55h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,5,6,5)}} d="m 160,160 h 30" id="c55c65h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,6,1,6)}} d="M 10,190 H 40" id="c06c16h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,6,2,6)}} d="M 40,190 H 70" id="c16c26h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,6,3,6)}} d="m 70,190 h 30" id="c26c36h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,6,4,6)}} d="m 100,190 h 30" id="c36c46h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,6,5,6)}} d="m 130,190 h 30" id="c46c56h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,6,6,6)}} d="m 160,190 h 30" id="c56c66h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,0,0,1)}} d="M 10,10 V 40" id="c00c01h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,1,0,2)}} d="M 10,40 V 70" id="c01c02h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,2,0,3)}} d="m 10,70 v 30" id="c02c03h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,3,0,4)}} d="m 10,100 v 30" id="c03c04h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,4,0,5)}} d="m 10,130 v 30" id="c04c05h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(0,5,0,6)}} d="m 10,160 v 30" id="c05c06h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,0,1,1)}} d="M 40,10 V 40" id="c10c11h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,1,1,2)}} d="M 40,40 V 70" id="c11c12h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,2,1,3)}} d="m 40,70 v 30" id="c12c13h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,3,1,4)}} d="m 40,100 v 30" id="c13c14h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,4,1,5)}} d="m 40,130 v 30" id="c14c15h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(1,5,1,6)}} d="m 40,160 v 30" id="c15c16h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,0,2,1)}} d="M 70,10 V 40" id="c20c21h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,1,2,2)}} d="M 70,40 V 70" id="c21c22h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,2,2,3)}} d="m 70,70 v 30" id="c22c23h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,3,2,4)}} d="m 70,100 v 30" id="c23c24h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,4,2,5)}} d="m 70,130 v 30" id="c24c25h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(2,5,2,6)}} d="m 70,160 v 30" id="c25c26h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,0,3,1)}} d="M 100,10 V 40" id="c30c31h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,1,3,2)}} d="M 100,40 V 70" id="c31c32h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,2,3,3)}} d="m 100,70 v 30" id="c32c33h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,3,3,4)}} d="m 100,100 v 30" id="c33c34h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,4,3,5)}} d="m 100,130 v 30" id="c34c35h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(3,5,3,6)}} d="m 100,160 v 30" id="c35c36h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,0,4,1)}} d="M 130,10 V 40" id="c40c41h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,1,4,2)}} d="M 130,40 V 70" id="c41c42h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,2,4,3)}} d="m 130,70 v 30" id="c42c43h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,3,4,4)}} d="m 130,100 v 30" id="c43c44h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,4,4,5)}} d="m 130,130 v 30" id="c44c45h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(4,5,4,6)}} d="m 130,160 v 30" id="c45c46h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,0,5,1)}} d="M 160,10 V 40" id="c50c51h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,1,5,2)}} d="M 160,40 V 70" id="c51c52h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,2,5,3)}} d="m 160,70 v 30" id="c52c53h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,3,5,4)}} d="m 160,100 v 30" id="c53c54h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,4,5,5)}} d="m 160,130 v 30" id="c54c55h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(5,5,5,6)}} d="m 160,160 v 30" id="c55c56h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(6,0,6,1)}} d="M 190,10 V 40" id="c60c61h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(6,1,6,2)}} d="M 190,40 V 70" id="c61c62h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(6,2,6,3)}} d="m 190,70 v 30" id="c62c63h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(6,3,6,4)}} d="m 190,100 v 30" id="c63c64h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(6,4,6,5)}} d="m 190,130 v 30" id="c64c65h" />
                                <path className={styles.hoverLines} onClick={()=>{bridgeClicked(6,5,6,6)}} d="m 190,160 v 30" id="c65c66h" />
                            </g>
                            <g>
                                <circle className={styles.circle} id="c10" cx="40" cy="10" r="10" />
                                <circle className={styles.circle} id="c20" cx="70" cy="10" r="10" />
                                <circle className={styles.circle} id="c30" cx="100" cy="10" r="10" />
                                <circle className={styles.circle} id="c40" cx="130" cy="10" r="10" />
                                <circle className={styles.circle} id="c50" cx="160" cy="10" r="10" />
                                <circle className={styles.circle} id="c60" cx="190" cy="10" r="10" />
                                <circle className={styles.circle} id="c00" cx="10" cy="10" r="10" />
                                <circle className={styles.circle} id="c01" cx="10" cy="40" r="10" />
                                <circle className={styles.circle} id="c11" cx="40" cy="40" r="10" />
                                <circle className={styles.circle} id="c21" cx="70" cy="40" r="10" />
                                <circle className={styles.circle} id="c31" cx="100" cy="40" r="10" />
                                <circle className={styles.circle} id="c41" cx="130" cy="40" r="10" />
                                <circle className={styles.circle} id="c51" cx="160" cy="40" r="10" />
                                <circle className={styles.circle} id="c61" cx="190" cy="40" r="10" />
                                <circle className={styles.circle} id="c12" cx="40" cy="70" r="10" />
                                <circle className={styles.circle} id="c22" cx="70" cy="70" r="10" />
                                <circle className={styles.circle} id="c32" cx="100" cy="70" r="10" />
                                <circle className={styles.circle} id="c42" cx="130" cy="70" r="10" />
                                <circle className={styles.circle} id="c52" cx="160" cy="70" r="10" />
                                <circle className={styles.circle} id="c62" cx="190" cy="70" r="10" />
                                <circle className={styles.circle} id="c02" cx="10" cy="70" r="10" />
                                <circle className={styles.circle} id="c03" cx="10" cy="100" r="10" />
                                <circle className={styles.circle} id="c13" cx="40" cy="100" r="10" />
                                <circle className={styles.circle} id="c23" cx="70" cy="100" r="10" />
                                <circle className={styles.circle} id="c33" cx="100" cy="100" r="10" />
                                <circle className={styles.circle} id="c43" cx="130" cy="100" r="10" />
                                <circle className={styles.circle} id="c53" cx="160" cy="100" r="10" />
                                <circle className={styles.circle} id="c63" cx="190" cy="100" r="10" />
                                <circle className={styles.circle} id="c14" cx="40" cy="130" r="10" />
                                <circle className={styles.circle} id="c24" cx="70" cy="130" r="10" />
                                <circle className={styles.circle} id="c34" cx="100" cy="130" r="10" />
                                <circle className={styles.circle} id="c44" cx="130" cy="130" r="10" />
                                <circle className={styles.circle} id="c54" cx="160" cy="130" r="10" />
                                <circle className={styles.circle} id="c64" cx="190" cy="130" r="10" />
                                <circle className={styles.circle} id="c04" cx="10" cy="130" r="10" />
                                <circle className={styles.circle} id="c05" cx="10" cy="160" r="10" />
                                <circle className={styles.circle} id="c15" cx="40" cy="160" r="10" />
                                <circle className={styles.circle} id="c25" cx="70" cy="160" r="10" />
                                <circle className={styles.circle} id="c35" cx="100" cy="160" r="10" />
                                <circle className={styles.circle} id="c45" cx="130" cy="160" r="10" />
                                <circle className={styles.circle} id="c55" cx="160" cy="160" r="10" />
                                <circle className={styles.circle} id="c65" cx="190" cy="160" r="10" />
                                <circle className={styles.circle} id="c06" cx="10" cy="190" r="10" />
                                <circle className={styles.circle} id="c16" cx="40" cy="190" r="10" />
                                <circle className={styles.circle} id="c26" cx="70" cy="190" r="10" />
                                <circle className={styles.circle} id="c36" cx="100" cy="190" r="10" />
                                <circle className={styles.circle} id="c46" cx="130" cy="190" r="10" />
                                <circle className={styles.circle} id="c56" cx="160" cy="190" r="10" />
                                <circle className={styles.circle} id="c66" cx="190" cy="190" r="10" />
                            </g>
                            <g>
                                <text className={styles.numbers} id="t10" x="40" y="14">0</text>
                                <text className={styles.numbers} id="t20" x="70" y="14">0</text>
                                <text className={styles.numbers} id="t30" x="100" y="14">0</text>
                                <text className={styles.numbers} id="t40" x="130" y="14">0</text>
                                <text className={styles.numbers} id="t50" x="160" y="14">0</text>
                                <text className={styles.numbers} id="t60" x="190" y="14">0</text>
                                <text className={styles.numbers} id="t00" x="10" y="14">0</text>
                                <text className={styles.numbers} id="t01" x="10" y="44">0</text>
                                <text className={styles.numbers} id="t11" x="40" y="44">0</text>
                                <text className={styles.numbers} id="t21" x="70" y="44">0</text>
                                <text className={styles.numbers} id="t31" x="100" y="44">0</text>
                                <text className={styles.numbers} id="t41" x="130" y="44">0</text>
                                <text className={styles.numbers} id="t51" x="160" y="44">0</text>
                                <text className={styles.numbers} id="t61" x="190" y="44">0</text>
                                <text className={styles.numbers} id="t12" x="40" y="74">0</text>
                                <text className={styles.numbers} id="t22" x="70" y="74">0</text>
                                <text className={styles.numbers} id="t32" x="100" y="74">0</text>
                                <text className={styles.numbers} id="t42" x="130" y="74">0</text>
                                <text className={styles.numbers} id="t52" x="160" y="74">0</text>
                                <text className={styles.numbers} id="t62" x="190" y="74">0</text>
                                <text className={styles.numbers} id="t02" x="10" y="74">0</text>
                                <text className={styles.numbers} id="t03" x="10" y="104">0</text>
                                <text className={styles.numbers} id="t13" x="40" y="104">0</text>
                                <text className={styles.numbers} id="t23" x="70" y="104">0</text>
                                <text className={styles.numbers} id="t33" x="100" y="104">0</text>
                                <text className={styles.numbers} id="t43" x="130" y="104">0</text>
                                <text className={styles.numbers} id="t53" x="160" y="104">0</text>
                                <text className={styles.numbers} id="t63" x="190" y="104">0</text>
                                <text className={styles.numbers} id="t14" x="40" y="134">0</text>
                                <text className={styles.numbers} id="t24" x="70" y="134">0</text>
                                <text className={styles.numbers} id="t34" x="100" y="134">0</text>
                                <text className={styles.numbers} id="t44" x="130" y="134">0</text>
                                <text className={styles.numbers} id="t54" x="160" y="134">0</text>
                                <text className={styles.numbers} id="t64" x="190" y="134">0</text>
                                <text className={styles.numbers} id="t04" x="10" y="134">0</text>
                                <text className={styles.numbers} id="t05" x="10" y="164">0</text>
                                <text className={styles.numbers} id="t15" x="40" y="164">0</text>
                                <text className={styles.numbers} id="t25" x="70" y="164">0</text>
                                <text className={styles.numbers} id="t35" x="100" y="164">0</text>
                                <text className={styles.numbers} id="t45" x="130" y="164">0</text>
                                <text className={styles.numbers} id="t55" x="160" y="164">0</text>
                                <text className={styles.numbers} id="t65" x="190" y="164">0</text>
                                <text className={styles.numbers} id="t06" x="10" y="194">0</text>
                                <text className={styles.numbers} id="t16" x="40" y="194">0</text>
                                <text className={styles.numbers} id="t26" x="70" y="194">0</text>
                                <text className={styles.numbers} id="t36" x="100" y="194">0</text>
                                <text className={styles.numbers} id="t46" x="130" y="194">0</text>
                                <text className={styles.numbers} id="t56" x="160" y="194">0</text>
                                <text className={styles.numbers} id="t66" x="190" y="194">0</text>
                            </g>
                        </svg>
                    </div>
                </Card>
            </div>
        </BasePage>
    );
}

function bridgeClicked(x1: number, y1: number, x2: number, y2: number){
    if(haschiwokakero.getBridgeCount(x1,y1) === 0 || haschiwokakero.getBridgeCount(x2,y2) === 0) return;
    userhaschi.doBridgeCycle(x1,y1,x2,y2);
    let cir1: SVGCircleElement = removeNull(document.getElementById("c" + x1 + y1));
    let cir2: SVGCircleElement = removeNull(document.getElementById("c" + x2 + y2));
    if(haschiwokakero.getBridgeCount(x1,y1) === userhaschi.getBridgeCount(x1,y1)){
        cir1.style.fill = "var(--backgroundLight)";
    }else{
        cir1.style.fill = "var(--background)";
    }
    if(haschiwokakero.getBridgeCount(x2,y2) === userhaschi.getBridgeCount(x2,y2)){
        cir2.style.fill = "var(--backgroundLight)";
    }else{
        cir2.style.fill = "var(--background)";
    }
    loadIntoGUI(userhaschi, true);
    if(haschiwokakero.isValidSolved(userhaschi)){
        setTimeout(() => {
            alert("You solved this haschiwokakero correctly!");
            window.location.reload();
        }, 300);
    }
}

function loadIntoGUI(haschi: Haschiwokakero, justbridges = false){
    if(!justbridges){
        var col: HTMLCollection = removeNull(document.getElementById("hoverLineGroup")?.children);
        for(var i = 0; i < col.length; i++){
            var element: SVGPathElement = removeNull(col.item(i))
            element.style.pointerEvents = "visiblePainted";
        }
        var c = 0;
        for(var y = 0; y < 7; y++){
            for(var x = 0; x < 7; x++){
                let txt: SVGTextElement = removeNull(document.getElementById("t" + x + y));
                let cir: SVGCircleElement = removeNull(document.getElementById("c" + x + y));
                var count = haschi.getBridgeCount(x, y);
                c += count;
                if(count > 0){
                    txt.textContent = "" + count;
                    txt.style.display = "block";
                    cir.style.display = "block";
                }else{
                    txt.style.display = "none";
                    cir.style.display = "none";
                    var collection: HTMLCollection = removeNull(document.getElementById("hoverLineGroup")?.children);
                    for(var i = 0; i < collection.length; i++){
                        var element: SVGPathElement = removeNull(collection.item(i))
                        if(element.id.includes("c" + x + y)) element.style.pointerEvents = "none";
                    }
                }
            }
        }
        if(c === 0){
            haschiwokakero = new Haschiwokakero(7, false);
            loadIntoGUI(haschiwokakero);
            return;
        }
    }
    clearBridges();
    for(var i = 0; i < haschi.bridges.length; i++){
        let bridge = haschi.bridges[i];
        let val: 0|1|2 = 1;
        if (bridge[4]) val = 2;
        var res = showBridge(bridge[0], bridge[1], bridge[2], bridge[3], val, !justbridges);
        if(!res){
            haschiwokakero = new Haschiwokakero(7, true);
            loadIntoGUI(haschiwokakero);
            return;
        }
    }
}

function clearBridges(){
    var bridges: SVGPathElement[] = [];
    var res: HTMLCollection = removeNull(document.getElementById("singleLineGroup")?.children);
    for(var i = 0; i < res.length; i++){
        bridges.push(removeNull(res.item(i)));
    }
    res = removeNull(document.getElementById("doubleLineGroup")?.children);
    for(var i = 0; i < res.length; i++){
        bridges.push(removeNull(res.item(i)));
    }
    for(var i = 0; i < bridges.length; i++){
        bridges[i].style.display = "none";
    }
}

function showBridge(x1: number, y1: number, x2: number, y2: number, value: 0|1|2, test: boolean): boolean{
    var single: SVGPathElement;
    var singleVal = document.getElementById("c" + x1 + y1 + "c" + x2 + y2 + "s");
    if(singleVal === null) single = removeNull(document.getElementById("c" + x2 + y2 + "c" + x1 + y1 + "s"));
    else single = removeNull(singleVal);
    let double: SVGPathElement;
    var doubleVal = document.getElementById("c" + x1 + y1 + "c" + x2 + y2 + "d");
    if(doubleVal === null) double = removeNull(document.getElementById("c" + x2 + y2 + "c" + x1 + y1 + "d"));
    else double = removeNull(doubleVal);
    if(single === undefined || double === undefined){
        return false;
    }
    if(test) return true;
    if(value === 0){
        single.style.display = "none";
        double.style.display = "none";
    }
    if(value === 1){
        single.style.display = "block";
        double.style.display = "none";
    }
    if(value === 2){
        single.style.display = "none";
        double.style.display = "block";
    }
    return true;
}

function removeNull(obj: any): any{
    if(obj === null){
        return undefined;
    }
    return obj;
}