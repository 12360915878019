import React, { useState } from 'react';
import styles from "./BlogPage.module.scss";
import BasePage from '../BasePage/BasePage';
import Card from "../../Components/Card/Card";
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import { Helmet } from 'react-helmet';
import Hyperlink from '../../Components/Hyperlink/Hyperlink';
import SocialButton from '../../Components/SocialButton/SocialButton';
import BlogAuthor, { AuthorProps } from './BlogAuthor';

type BlogProps = {
    children: React.ReactNode;
    title: string,
    description: string,
    keywords: string,
    imageUrl: string,
    releaseDate: string,
    author: AuthorProps,
}

export default function BlogPage(props: BlogProps) {
    return (
        <BasePage>
            <Helmet>
                <title>{props.title} | Finn Freitag</title>
                <meta name="author" content={props.author.author} />
                <meta name="copyright" content={props.author.author} />
                <meta name="description" content={props.description} />
                <meta name="keywords" content={"finn, freitag, finn freitag, finnfreitag, website, SEO, personal, " + props.keywords} />
                <meta property="og:image" content={props.imageUrl} />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardDouble}>
                <Card>
                    <div className={styles.holder}>
                        <div className={styles.blogpost}>
                            <h1 className={styles.headline}>{props.title}</h1>
                            <div className={styles.description}>
                                {props.description}
                            </div>
                            <div className={styles.releaseDate}>
                                {props.releaseDate}
                            </div>
                            <div className={styles.content}>
                                {props.children}
                            </div>
                            <BlogAuthor author={props.author.author} authorUrl={props.author.authorUrl} authorDescription={props.author.authorDescription} authorImageUrl={props.author.authorImageUrl} authorSocial={props.author.authorSocial} authorSocialUrl={props.author.authorSocialUrl} />
                            <span className={styles.sharetext}>Share this post:</span>
                            <div className={styles.share}>
                                <SocialButton href={"https://x.com/intent/post?text=" + window.location} text="X" color="#000000" />
                                <SocialButton href={"https://www.addtoany.com/add_to/mastodon?linkurl=" + window.location + "&linkname=Check out this article: " + window.location} text="Mastodon" color="#6364ff" />
                                <SocialButton href={"mailto:?subject=Check%20out%20this%20blog%3A%20FinnFreitag.com%2Fblog&body=Hey! I've%20found%20an%20interesting%20article%3A%20" + window.location + " Hope%20you%20enjoy%20it! Best%20wishes!"} text="EMail" color="#aaaaaa" />
                                <SocialButton href={"https://www.linkedin.com/shareArticle?url=" + window.location + "&title=Finn Freitag's Blog&summary=Check out this article: " + window.location} text="LinkedIn" color="#2977c9" />
                                <SocialButton href={"https://api.whatsapp.com/send?text=Check out this article: " + window.location} text="WhatsApp" color="#25d366" />
                                <SocialButton href={"https://telegram.me/share/url?url=" + window.location + "&text=Check out this article: " + window.location} text="Telegram" color="#28a8e9" />
                                <SocialButton href={"https://www.pinterest.de/pin/create/button/?media=&url=" + window.location + "&description=Check out this article: " + window.location} text="Pinterest" color="#e60023" />
                                <SocialButton href={"https://www.reddit.com/submit?url=" + window.location + "&title=Check out this article: " + window.location} text="Reddit" color="#d93900" />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </BasePage>
    );
}