import { FNumber, IFormula } from "./parser";

export function plot(canvas: HTMLCanvasElement, formula: IFormula, variable: string, offsetX: number = 0, offsetY: number = 0, pixPerUnit: number = 10): boolean {
    var gridStrokeWidth = 1.5;
    var graphStrokeWidth = 2.5;
    var gridStrokeColor = "white"
    var graphStrokeColor = "red";
    var backColor = "#302c2d";

    //var formStr = formula.ToString();
    //if(formStr.includes("%") || formStr.includes("⌊") || formStr.includes("⌋") || formStr.includes("⌈") || formStr.includes("⌉")) return false;
    var ct2d = canvas.getContext("2d");
    if(ct2d === null) return false;
    var width = canvas.clientWidth;
    var height = canvas.clientHeight;

    ct2d.fillStyle = backColor;
    ct2d.fillRect(0, 0, canvas.width, height);

    ct2d.beginPath();
    ct2d.strokeStyle = gridStrokeColor;
    ct2d.lineWidth = gridStrokeWidth;
    ct2d.moveTo(width / 2 - offsetX, 0);
    ct2d.lineTo(width / 2 - offsetX, height);
    ct2d.moveTo(0, height / 2 - offsetY);
    ct2d.lineTo(height, height / 2 - offsetY);
    ct2d.stroke();
    
    ct2d.beginPath();
    ct2d.strokeStyle = graphStrokeColor;
    ct2d.lineWidth = graphStrokeWidth;

    var xVal = - width / 2 / pixPerUnit;
    var fnum = new FNumber();
    fnum.num = xVal;
    formula.OverwriteVariable(variable, fnum);
    var yVal = formula.calc();
    ct2d.moveTo(-offsetX, -yVal * pixPerUnit + height / 2 - offsetY);
    
    for(var x = - width / 2 + 1; x < width / 2 + 1; x++){
        var xVal = x / pixPerUnit;
        var fnum = new FNumber();
        fnum.num = xVal;
        formula.OverwriteVariable(variable, fnum);
        var yVal = formula.calc();
        ct2d.lineTo(x + width / 2 - offsetX, -yVal * pixPerUnit + height / 2 - offsetY);
    }

    ct2d.stroke();

    return true;
}