import React, { useEffect, useState } from 'react';
import styles from './MastodonCard.module.scss';
import cardStyles from '../Card/CardStyle.module.scss';
import Hyperlink from '../Hyperlink/Hyperlink';

type MastodonCardProps = {
    rss: string;
}

export default function MastodonCard(props: MastodonCardProps) {
    const [msgItems, setMsgItems] = useState<Element[]>();
    const [image, setImage] = useState<string>();
    const [name, setName] = useState<string>();
    const [link, setLink] = useState<string>();
    const [time, setTime] = useState<string>();

    useEffect(()=>{
        fetch(props.rss)
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => {
                var elements: Element[] = [];
                setImage(data.getElementsByTagName("webfeeds:icon").item(0)?.innerHTML);
                setName(data.querySelectorAll("title").item(0).innerHTML);
                setLink(data.querySelectorAll("link").item(0).innerHTML);
                setTime(convertDate(data.querySelectorAll("pubDate").item(0).innerHTML));

                data.querySelectorAll("item").forEach((val)=>{
                    elements.push(val);
                });
                setMsgItems(elements);
            });
    },[]);

    return (
        <div className={cardStyles.cardSingle}>
            <div className={styles.postholder}>
                {msgItems?.map((value, index) => {
                    return (
                        <article className={styles.article}>
                            <div className={styles.header}>
                                <img src={image} alt="" width="50em" height="50em" />
                                <h3><Hyperlink href={fixString(link)} text={fixString(name)} newTab /></h3>
                            </div>
                            <h2>
                                <a href={value.querySelector("link")?.innerHTML} target="_blank" className={styles.mastodonLink}>
                                    <span dangerouslySetInnerHTML={{ __html: DecodeHtmlEntities(value.querySelector("description")?.innerHTML)}} />
                                    <img className={styles.media} src={fixNullString(value.getElementsByTagName("media:content").item(0)?.getAttribute("url"))} width="100%" />
                                </a>
                            </h2>
                            <div className={styles.dateStr}>{time}</div>
                        </article>
                    );
                })}
            </div>
        </div>
    );
}

function fixNullString(str: string|undefined|null) : string|undefined {
    if(str === null)
        return undefined;
    return str;
}

function fixString(str: string|undefined|null) : string{
    if(str === undefined || str === null)
        return "";
    return str;
}

function DecodeHtmlEntities(str?: string) : string {
    if(str === undefined || str === null)
        return "";
    var element = document.createElement('div');
    element.innerHTML = str;
    return element.textContent || element.innerText;
}

function convertDate(dateStr: string): string {
    const date = new Date(dateStr);

    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedDay} ${month} ${year} ${hours}:${formattedMinutes}`;
}