import { useState } from "react";
import Card from "../../Components/Card/Card";
import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import styles from "./BasePage.module.scss";
import {Helmet} from 'react-helmet';

type BasePageProps = {
    children: React.ReactNode;
}

export default function BasePage(props: BasePageProps){
    const [isVisible, setIsVisible] = useState(false);
    const toggle = () => {
        setIsVisible(!isVisible);
    };
    return(
        <div className={styles.page}>
            <Helmet>
                <title>Finn Freitag</title>
                <meta name="author" content="Finn Freitag" />
                <meta name="copyright" content="Finn Freitag" />
                <meta name="description" content="Personal website of Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={styles.navbar}>
                <Card>
                    <div className={styles.navbarInner}>
                        <div className={styles.owner}>
                            <img src="/logo192.png" className={styles.icon} />
                            <span className={styles.ownerName}><Hyperlink href="/" text="Finn Freitag" /></span>
                            <a className={styles.smallMenu} onClick={toggle}>☰</a>
                        </div>
                        <div className={isVisible ? styles.menu : styles.menuhidden}>
                            <Hyperlink href="/" text="Home" />
                            <Hyperlink href="/blog" text="Blog" />
                            <Hyperlink href="/projects" text="Software Projects" />
                            <Hyperlink href="/tools" text="Online Tools" />
                            <Hyperlink href="/geography" text="Geography" />
                            <Hyperlink href="/games" text="Games" />
                        </div>
                    </div>
                </Card>
            </div>
            <div className={styles.content}>
                {props.children}
            </div>
            <div className={styles.footer}>
                <Card>
                    <span className={styles.headline}>See also:</span>
                    <ul className={styles.ul}>
                        <li><Hyperlink text="Github" href="https://github.com/finn-freitag" /></li>
                        <li><Hyperlink text="Stackoverflow" href="https://stackoverflow.com/users/17138043/finn" /></li>
                        <li><Hyperlink text="Mastodon" href="https://mastodon.social/@FinnFreitag" /></li>
                    </ul>
                </Card>
                <Card>
                    <span className={styles.headline}>Legal:</span>
                    <ul className={styles.ul}>
                        <li><Hyperlink text="Imprint" href="/Imprint.txt" /></li>
                        <li>Copyright &copy; 2024 Finn Freitag</li>
                        <li><Hyperlink text="Other copyright notices" href="/copyright_notes.txt" /></li>
                    </ul>
                </Card>
            </div>
        </div>
    );
}