import Hyperlink from "../../Components/Hyperlink/Hyperlink";

export function getHelp(operation: string){
    switch(operation){
        case "1":
            return (<span>Number 1</span>);
        case "2":
            return (<span>Number 2</span>);
        case "3":
            return (<span>Number 3</span>);
        case "4":
            return (<span>Number 4</span>);
        case "5":
            return (<span>Number 5</span>);
        case "6":
            return (<span>Number 6</span>);
        case "7":
            return (<span>Number 7</span>);
        case "8":
            return (<span>Number 8</span>);
        case "9":
            return (<span>Number 9</span>);
        case "0":
            return (<span>Number 0</span>);

        case "+":
            return (<span>Addition operator<br />Adds two numbers.<br /><br />Example:<br />3 + 2 = 5</span>);
        case "-":
            return (<span>Subtraction operator<br />Subtracts two numbers.<br /><br />Example:<br />5 - 2 = 3</span>);
        case "*":
            return (<span>Multiplication operator<br />Multiplies two numbers.<br /><br />Example:<br />3 * 2 = 6</span>);
        case "/":
            return (<span>Division operator<br />Divides two numbers.<br /><br />Example:<br />6 / 3 = 2</span>);
        case "%":
            return (<span>Modulo operator<br />Divides two numbers and returns the remainder.<br /><br />Example:<br />8 % 3 = 2</span>);

        case "sin":
            return (<span>Sine function<br />Returns the sine of a number.<br /><br />Example:<br />sin{"("}90{")"} = 1</span>);
        case "cos":
            return (<span>Cosine function<br />Returns the cosine of a number.<br /><br />Example:<br />cos{"("}180{")"} = 1</span>);
        case "tan":
            return (<span>Tangent function<br />Returns the tangent of a number.<br /><br />Example:<br />tan{"("}0{")"} = 0</span>);

        case "asin":
            return (<span>Arcus sine function<br />Returns the arcus sine of a sine.<br /><br />Example:<br />asin{"("}1{")"} = 90</span>);
        case "acos":
            return (<span>Arcus cosine function<br />Returns the arcus cosine of a cosine.<br /><br />Example:<br />acos{"("}-1{")"} = 180</span>);
        case "atan":
            return (<span>Arcus tangent function<br />Returns the arcus tangent of a tangent.<br /><br />Example:<br />atan{"("}0{")"} = 0</span>);

        case "^":
            return (<span>Exponentiation<br />Returns a base raised to a power.<br />Syntax: {"("}base{")"}^{"("}power{")"}<br /><br />Example:<br />2^3 = 8</span>);
        case "sqrt":
            return (<span>Square root<br />Returns the square root of a number, base is optional.<br /><br />Example:<br />sqrt{"("}64{")"} = 8<br />sqrt{"("}3, 27{"}"} = 3</span>);
        case "log":
            return (<span>Logarithm<br />Returns the logarithm to a base of a number.<br />Syntax: log{"("}base, number{")"}<br /><br />Example:<br />log{"("}10, 100{")"} = 2</span>);
        case "abs":
            return (<span>Absolute value<br />Returns the absolute value of a number.<br />Syntax: abs{"("}number{")"}<br /><br />Example:<br />abs{"("}-5{")"} = 5</span>);
        case "sum":
            return (<span>Summation<br />Returns the sum of all function values of a function in a specific span with an increment of 1.<br />Specify a variable with the first parameter.<br />Syntax: sum{"("}variable, from, to, function{")"}<br /><br />Example:<br />sum{"("}x, 1, 5, 0.5*x{")"} = 7.5</span>);
        case "product":
            return (<span>Product function<br />Returns the product of all function values of a function in a specific span with an increment of 1.<br />Specify a variable with the first parameter.<br />Syntax: product{"("}variable, from, to, function{")"}<br /><br />Example:<br />product{"("}x, 1, 5, 0.5*x{")"} = 3,75<br /><br /><br />You could use this as faculty like this:<br />product{"("}x, 1, NUMBER, x{")"}</span>);
        
        case "π":
            return (<span>Pi<br />Constant with an approximate value of <Hyperlink href="/assets/calculator/pi.txt" text="3.1415926536" newTab />. You can also use your keyboard: \pi</span>);
        case "e":
            return (<span>e<br />Constant with an approximate value of <Hyperlink href="/assets/calculator/e.txt" text="2.7182818284" newTab />. You must enter it like this: \e {"("}just "e" would be a variable{")"}</span>);
        case "?":
            return (<span>?<br />Represents a random value between 0 {"("}inclusive{")"} and 1 {"("}exclusive{")"}.</span>);
        
        case "()":
            return (<span>Parentheses<br />Use parentheses to give priority to a particular expression of a term.<br /><br />Example: {"("}2 + 3{")"} * 2 = 12</span>);
        case "[]":
            return (<span>Brackets<br />Use this kind of bracket to round a particular expression of a term.<br /><br />Example: {"["}3.5{"]"} = 4</span>);
        case "⌊⌋":
            return (<span>Brackets<br />Use this kind of bracket to round down a particular expression of a term.<br /><br />Example: ⌊1.6⌋ = 1</span>);
        case "⌈⌉":
            return (<span>Brackets<br />Use this kind of bracket to round up a particular expression of a term.<br /><br />Example: ⌈2.2⌉ = 3</span>);
    }
    return (<span>No hint available!</span>);
}