import React, { useState } from 'react';
import styles from './SVGCard.module.scss';
import cardStyles from '../Card/CardStyle.module.scss';

type SVGCardProps = {
    src: string;
}

export default function SVGCard(props: SVGCardProps) {
    return (
        <div className={cardStyles.cardSingle}>
            <div className={styles.imageHolder}>
                <div className={styles.imageBox}>
                    <img src={props.src} alt="" />
                </div>
            </div>
        </div>
    );
}