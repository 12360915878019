import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import LandingPage from "./Pages/LandingPage/LandingPage";
import ProjectPage from './Pages/ProjectPage/ProjectPage';
import ToolsPage from './Pages/ToolsPage/ToolsPage';
import CodeFormatterPage from './Pages/CodeFormatterPage/CodeFormatterPage';
import JavaToStructogramPage from './Pages/JavaToStructogramPage/JavaToStructogramPage';
import GeographyPage from './Pages/GeographyPage/GeographyPage';
import GamesPage from './Pages/GamesPage/GamesPage';
import Page404 from './Pages/Page404/Page404';
import CalculatorPage from './Pages/CalculatorPage/CalculatorPage';
import SudokuPage from './Pages/SudokuPage/SudokuPage';
import BrailleTrainerPage from './Pages/BrailleTrainerPage/BrailleTrainerPage';
import SGATrainerPage from './Pages/SGATrainerPage/SGATrainerPage';
import HaschiwokakeroPage from './Pages/HashiwokakeroPage/HaschiwokakeroPage';
import MorseTrainerPage from './Pages/MorseTrainerPage/MorseTrainerPage';
import SudokuSolverPage from './Pages/SudokuSolverPage/SudokuSolverPage';
import BubbleSortBlogPage from './BlogPages/BubbleSortBlogPage/BubbleSortBlogPage';
import InsertionSortBlogPage from './BlogPages/InsertionSortBlogPage/InsertionSortBlogPage';
import SelectionSortBlogPage from './BlogPages/SelectionSortBlogPage/SelectionSortBlogPage';
import { GetRouterList } from './Data/BlogArticles';
import BlogOverviewPage from './Pages/BlogOverviewPage/BlogOverviewPage';

const router = createBrowserRouter([
  ...GetRouterList(),
  {
    path: "*", // URL not found => 404
    element: (<Page404 />),
  },
  {
    path: "/",
    element: (<LandingPage/>),
  },
  {
    path: "/projects",
    element: (<ProjectPage/>),
  },
  {
    path: "/tools",
    element: (<ToolsPage/>),
  },
  {
    path: "/tools/codeformatter",
    element: (<CodeFormatterPage/>),
  },
  {
    path: "/tools/javatostructogram",
    element: (<JavaToStructogramPage/>),
  },
  {
    path: "/tools/calculator",
    element: (<CalculatorPage/>),
  },
  {
    path: "/tools/sudoku",
    element: (<SudokuSolverPage/>),
  },
  {
    path: "/geography",
    element: (<GeographyPage/>),
  },
  {
    path: "/games",
    element: (<GamesPage/>),
  },
  {
    path: "/games/sudoku",
    element: (<SudokuPage/>),
  },
  {
    path: "/games/haschiwokakero",
    element: (<HaschiwokakeroPage/>),
  },
  {
    path: "/games/brailletrainer",
    element: (<BrailleTrainerPage/>),
  },
  {
    path: "/games/sgatrainer",
    element: (<SGATrainerPage/>),
  },
  {
    path: "/games/morsetrainer",
    element: (<MorseTrainerPage/>),
  },
  {
    path: "/blog",
    element: (<BlogOverviewPage/>),
  },
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router} />
);