const clearwhitespaces = (str: string, ignoreInner = "\"'", escapeChar = "\\"): string => {
    let innerChar = " ";
    let isInner = false;
    let res = "";
    let isEscaped = false;

    for (let i = 0; i < str.length; i++) {
        if (!isInner) {
            let Char = " ";
            let found = false;

            for (let c = 0; c < ignoreInner.length; c++) {
                if (str[i] == ignoreInner[c] && (i == 0 || !isEscaped)) {
                    found = true;
                    Char = ignoreInner[c];
                }
            }

            if (found) {
                innerChar = Char;
                isInner = true;
            }
        } else if (str[i] == innerChar && isInner && (i == 0 || !isEscaped)) {
            isInner = false;
        }

        if (isEscaped && str[i] == escapeChar) {
            isEscaped = false;
        } else if (!isEscaped && str[i] == escapeChar) {
            isEscaped = true;
        }

        if (isInner || (!isInner && str[i] != " ")) {
            res += str[i];
        }
    }

    return res;
};

const advreplace = (
    str: string,
    oldStr: string,
    newStr: string,
    ignoreInner = "\"'",
    escapeChar = "\\"
): string => {
    let innerChar = " ";
    let isInner = false;
    let res = "";
    let isEscaped = false;

    for (let i = 0; i < str.length; i++) {
        if (!isInner) {
            let Char = " ";
            let found = false;

            for (let c = 0; c < ignoreInner.length; c++) {
                if (str[i] == ignoreInner[c] && (i == 0 || !isEscaped)) {
                    found = true;
                    Char = ignoreInner[c];
                }
            }

            if (found) {
                innerChar = Char;
                isInner = true;
            }
        } else if (str[i] == innerChar && isInner && (i == 0 || !isEscaped)) {
            isInner = false;
        }

        if (isEscaped && str[i] == escapeChar) {
            isEscaped = false;
        } else if (!isEscaped && str[i] == escapeChar) {
            isEscaped = true;
        }

        res += str[i];

        if (!isInner && res.endsWith(oldStr)) {
            res = res.substring(0, res.length - oldStr.length);
            res += newStr;
        }
    }

    return res;
};

const advReplace = (
    code: string,
    oldStr: RegExp,
    newStr: string,
    ignoreInner: string = "\"'",
    escapeChar: string = '\\'
): string => {
    let innerChar: string = ' ';
    let isInner: boolean = false;
    let res: string = "";
    let isEscaped: boolean = false;
  
    for (let i = 0; i < code.length; i++) {
        if (!isInner) {
            let char: string = ' ';
            let found: boolean = false;
            for (let c = 0; c < ignoreInner.length; c++) {
                if (code[i] === ignoreInner[c] && (i === 0 || !isEscaped)) {
                    found = true;
                    char = ignoreInner[c];
                }
            }
            if (found) {
                innerChar = char;
                isInner = true;
            }
        } else if (code[i] === innerChar && isInner && (i === 0 || !isEscaped)) {
            isInner = false;
        }
    
        if (isEscaped && code[i] === escapeChar) {
            isEscaped = false;
        } else if (!isEscaped && code[i] === escapeChar) {
            isEscaped = true;
        }
    
        res += code[i];
    
        const regexRes = oldStr.exec(res);
        if (!isInner && regexRes && res.endsWith(regexRes[0])) {
            res = res.substring(0, res.length - regexRes[0].length);
            let newStrCopy = newStr;
            for (let g = 1; g < regexRes.length; g++) {
                if (regexRes[g] !== "") {
                    newStrCopy = newStrCopy.replace(new RegExp(`{${g - 1}}`, 'g'), regexRes[g]);
                }
            }
            res += newStrCopy;
        }
    }
  
    return res;
};

const addtabs = (
    str: string,
    tabbedSequenceStart: string,
    tabbedSequenceEnd: string,
    tabSequence = "\t"
): string => {
    let res = "";
    let insideSteps = 0;
    let additionCounter = 0;

    for (let i = 0; i < str.length; i++) {
        res += str[i];

        if (res[i + additionCounter] == "\n") {
            for (let s = 0; s < insideSteps; s++) {
                res += tabSequence;
                additionCounter++;
            }
        }

        if (res.endsWith(tabbedSequenceStart)) {
            insideSteps++;
        }

        if (res.endsWith(tabbedSequenceEnd)) {
            res = res.substring(0, res.length - tabbedSequenceEnd.length - 1);
            res += tabbedSequenceEnd;
            additionCounter--;
            insideSteps--;
        }
    }

    if (insideSteps === 1) {
        res = res.replace(/\n\t/g, "\n");
    }

    return res;
};

const addTabs = (
    code: string,
    tabbedSequenceStart: RegExp,
    tabbedSequenceEnd: RegExp,
    tabSequence: string = "\t"
): string => {
    let res = "";
    let insideSteps = 0;
    let additionCounter = 0;

    for (let i = 0; i < code.length; i++) {
        res += code[i];

        if (res[i + additionCounter] == "\n") {
            for (let s = 0; s < insideSteps; s++) {
                res += tabSequence;
                additionCounter++;
            }
        }

        if (endsWithRegex(res, tabbedSequenceStart)[0] === true) {
            insideSteps++;
        }

        var regexres = endsWithRegex(res, tabbedSequenceEnd);
        if (regexres[0] === true) {
            res = res.substring(0, res.length - regexres[1].length - 1);
            res += regexres[1];
            additionCounter--;
            insideSteps--;
        }
    }

    if (insideSteps === 1) {
        res = res.replace(/\n\t/g, "\n");
    }

    return res;
};

const endsWithRegex = (
    str: string,
    regex: RegExp
): [boolean, string] => {
    const matches = Array.from(str.matchAll(regex));
    if (matches && matches.length > 0) {
        const match = matches[matches.length - 1];
        if (str.endsWith(match[0])) {
            return [true, match[0]];
        }
    }
    return [false,""];
};

const startsWithRegex = (
    str: string,
    regex: RegExp
): [boolean, string] => {
    const matches = Array.from(str.matchAll(regex));
    if (matches && matches.length > 0) {
        const match = matches[matches.length - 1];
        if (str.startsWith(match[0])) {
            return [true, match[0]];
        }
    }
    return [false,""];
};

const firstIndexOf = (
    code: string,
    until: string,
    offset: number = 0,
    ignoreInner: string = "\"'",
    escapeChar: string = '\\'
): number => {
    let innerChar: string = ' ';
    let isInner: boolean = false;
    let res: string = "";
    let isEscaped: boolean = false;
  
    for (let i = offset; i < code.length; i++) {
        if (!isInner) {
            let char: string = ' ';
            let found: boolean = false;
            for (let c = 0; c < ignoreInner.length; c++) {
                if (code[i] === ignoreInner[c] && (i === 0 || !isEscaped)) {
                    found = true;
                    char = ignoreInner[c];
                }
            }
            if (found) {
                innerChar = char;
                isInner = true;
            }
        } else if (code[i] === innerChar && isInner && (i === 0 || !isEscaped)) {
            isInner = false;
        }
  
        if (isEscaped && code[i] === escapeChar) {
            isEscaped = false;
        } else if (!isEscaped && code[i] === escapeChar) {
            isEscaped = true;
        }
    
        res += code[i];
        if (!isInner && res.endsWith(until)) {
            return i - until.length + 1;
        }
    }
  
    return -1;
};

const clearDoubleSpace = (code : string): string => {
    const index = firstIndexOf(code, "  ");
    if (index > -1) {
        return clearDoubleSpace(code.substring(0, index) + code.substring(index + 1));
    }
    return code;
};

const getForLoopFix = (code: string): string => {
    const lines: string[] = code.split(/\r?\n/).filter(s => s !== "");
    let res: string = "";

    for (let i = 0; i < lines.length; i++) {
        if (lines[i].toLowerCase().startsWith("for")) {
            res += `${lines[i]} ${lines[i + 1]} ${lines[i + 2]}\n`;
            i += 2;
        } else {
            res += `${lines[i]}\n`;
        }
    }

    return res;
}

const clearLineStarts = (code: string, regex: RegExp): string => {
    const lines: string[] = code.split(/\r?\n/).filter(s => s !== "");
    let res: string = "";

    for (let i = 0; i < lines.length; i++) {
        let regexRes = startsWithRegex(lines[i], regex);
        if(regexRes[0] === true){
            res += lines[i].substring(regexRes[1].length) + "\n";
        } else {
            res += lines[i] + "\n";
        }
    }

    return res;
}

export const formatjson = (code: string): string => {
    // Clear old formatings
    code = code.replace(/\n/g, '');
    code = code.replace(/\r/g, '');
    code = code.replace(/\t/g, '');
    code = clearwhitespaces(code);

    // Format code
    code = advreplace(code, ',', ',\n');
    code = advreplace(code, '}', '\n}\n');
    code = advreplace(code, '{', '\n{\n');
    code = advreplace(code, '[', '\n[\n');
    code = advreplace(code, ']', '\n]\n');
    code = advreplace(code, ':', ' : ');
    code = advreplace(code, '}\n,', '},');
    code = advreplace(code, ']\n,', '],');

    // Clear useless formattings
    code = code.replace(/\n\n/g, '\n');

    // Add tabs
    code = addtabs(code, '{', '}');
    code = addtabs(code, '[', ']');

    // Clear useless formattings
    // code = this.advReplace(code, "\t}", "}");
    // code = this.advReplace(code, "\t]", "]");
    code = code.replace(/\n\n/g, '\n');
    code = code.replace(/ \n/g, '\n');
    code = advreplace(code, '\t\n"', '\t"');

    if (code.startsWith('\n')) code = code.substring(1);
    if (code.endsWith('\n')) code = code.substring(0, code.length - 1);

    return code;
};

export const formatjs = (code: string): string => {
    // Seperate comments
    code = advReplace(code, /\/\/(.*)\n/g, "//{0};\n");

    // Clear old formattings
    code = advReplace(code, /[\s\n\t\r]+/g, " ");

    // Format code
    code = advreplace(code, "{", "{\n");
    code = advreplace(code, "}", "\n}\n");
    code = advreplace(code, ";", ";\n");
    code = advreplace(code, ",", ", ");
    code = clearLineStarts(code, /\s+/g);
    code = getForLoopFix(code);
    code = advReplace(code, new RegExp("(.)([\\+\\-\\*/\\!\\=])\\=(.)"), "{0} {1}= {2}");
    code = advReplace(code, new RegExp("(.)\\=\\>(.)"), "{0} => {1}");
    code = advReplace(code, new RegExp("([^\\+\\-\\*/\\!\\=])\\=([^\\>\\=])"), "{0} = {1}");
    code = advReplace(code, new RegExp("==[\\s\\n\\t\\r]+="), "===");
    code = advReplace(code, new RegExp("=[\\s\\n\\t\\r]+=="), "===");
    code = advReplace(code, new RegExp("!=[\\s\\n\\t\\r]+="), "!==");
    code = advReplace(code, new RegExp("\\s+\\+\\s+\\+"), "++");
    code = advReplace(code, new RegExp("\\s+\\-\\s+\\-"), "--");
    code = advReplace(code, new RegExp("\\<([^\\=])"), " < {0}");
    code = advReplace(code, new RegExp("([^\\=])\\>([^\\=])"), "{0} > {1}");
    code = advReplace(code, new RegExp("([\\*\\+\\-/])([^\\=])"), " {0} {1}");
    code = advReplace(code, new RegExp("([A-Za-z])\\s\\("), "{0}(");
    code = advReplace(code, new RegExp("\\(\\s([A-Za-z])"), "({0}");
    code = advReplace(code, new RegExp("([A-Za-z])\\s\\)"), "{0})");
    code = advReplace(code, new RegExp("\\)\\s([A-Za-z])"), "){0}");
    code = advreplace(code, ",", ", ");

    // Clear useless formattings
    if (code.startsWith("\n")) code = code.substring(1);
    if (code.endsWith("\n")) code = code.substring(0, code.length - 1);
    code = advReplace(code, new RegExp("}[\\s\\n\\t\\r]+;"), "};");
    code = advReplace(code, new RegExp("{[\\s\\n\\t\\r]+}"), "{}");
    code = advReplace(code, new RegExp("\\([\\s\\n\\t\\r]+\\)"), "()");
    code = advReplace(code, new RegExp("}[\\s\\n\\t\\r]+\\)"), "})");
    code = advReplace(code, new RegExp("}[\\s\\n\\t\\r]+,"), "},");
    code = clearDoubleSpace(code);

    // Format code
    code = clearLineStarts(code, new RegExp("\\s+"));
    code = advreplace(code, "\t ", "\t");
    code = advReplace(code, new RegExp("}[\\t\\s\\n]*function"), "}\n\nfunction");
    code = addtabs(code, "{", "}");

    // Clear comment seperators
    code = advReplace(code, new RegExp("\\/\\/([^\n]*);"), "//{0}");
    code = advReplace(code, new RegExp("[\\s\\t]*\\n[\\s\\t]*;([\\s\\t]*\\n)"), ";{0}");

    // Clear code
    code = clearDoubleSpace(code);
    if (code.startsWith("\n")) code = code.substring(1);
    if (code.endsWith("\n")) code = code.substring(0, code.length - 1);

    return code;
};

export const formatxml = (code: string): string => {
    // Clear old formatings
    code = code.replace(/\n/g, '');
    code = code.replace(/\r/g, '');
    code = code.replace(/\t/g, '');
    code = clearDoubleSpace(code);
    code = advReplace(code, new RegExp("\\<\\s+"), "<");
    code = advReplace(code, new RegExp("\\<\\s*/\\s*"), "</");
    code = advReplace(code, new RegExp("\\s+\\>"), ">");
    code = advReplace(code, new RegExp("\\s*/\\s*\\>"), "/>");

    // Format code
    code = advreplace(code, ">", ">\n");
    code = advreplace(code, "</", "\n</");
    code = addTabs(code, /<\s*[^\/].+?[^\/]\s*>/g, /<\/.+?>/g);

    // Clear useless formattings
    code = advReplace(code, new RegExp("\\n[\\t\\s]*\\n"),"\n");
    if (code.startsWith("\n")) code = code.substring(1);
    if (code.endsWith("\n")) code = code.substring(0, code.length - 1);

    return code;
};