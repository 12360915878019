import SyntaxHighlighter from "react-syntax-highlighter";
import paraisoDark from "react-syntax-highlighter/dist/esm/styles/hljs/paraiso-dark";
import styles from "./BlogJavaCode.module.scss";

type BlogJavaCodeProps = {
    children: string
}

export default function BlogJavaCode(props: BlogJavaCodeProps) {
    return (
        <div className={styles.box}>
            <SyntaxHighlighter
                language="java"
                style={paraisoDark}
                wrapLines={false}
                wrapLongLines={false}
                showLineNumbers={true}
                showInlineLineNumbers={false}
                customStyle={{
                    borderRadius: "var(--borderRadius)",
                    backgroundColor: "var(--backgroundLight)",
                }}
                >
                    {props.children}
            </SyntaxHighlighter>
        </div>
    );
}