import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./ToolsPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import SVGCard from "../../Components/SVGCard/SVGCard";
import {Helmet} from 'react-helmet';

export default function ToolsPage(){
    return(
        <BasePage>
            <Helmet>
                <title>Tools | Finn Freitag</title>
                <meta name="description" content="Tools by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, tool, tools" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardHalf}>
                <Card>
                    <h1 className={styles.headline}>Tools</h1>
                    <ul>
                        <li><Hyperlink href="/tools/codeformatter" text="JSON Formatter" /></li>
                        <li><Hyperlink href="/tools/javatostructogram" text="Java to structogram converter" /></li>
                        <li><Hyperlink href="/tools/calculator" text="Calculator" /></li>
                        <li><Hyperlink href="/tools/sudoku" text="Sudoku solver" /></li>
                    </ul>
                </Card>
            </div>
            <SVGCard src="/assets/svgs/coding.svg" />
        </BasePage>
    );
}