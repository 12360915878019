import React, { useState } from 'react';
import styles from "./Switch.module.scss";

type ButtonProps = {
    colorChange?: boolean,
    checked?: boolean,
    onClick: (checked: boolean) => void,
}

export default function Button(props: ButtonProps) {
    const [isOn, setIsOn] = useState(props.checked);
    const [uniqueId] = useState(`switch-${Math.random().toString(36).substr(2, 9)}`);
    function change(){
        var cb = document.getElementById(uniqueId) as HTMLInputElement;
        setIsOn(cb.checked);
        props.onClick(cb.checked);
    }
    return (
        <div>
            <label className={styles.switch}>
                <input type="checkbox" id={uniqueId} checked={isOn} onChange={change} />
                <span className={styles.slider}></span>
            </label>
        </div>
    );
}