import React, { useState } from 'react';
import Card from "../../Components/Card/Card";
import styles from './Button.module.scss';

type ButtonProps = {
    href: string,
    newTab?: boolean;
    text: string,
}

export default function Button(props: ButtonProps) {
    return (
        <a href={props.href} className={styles.link} target={props.newTab == true ? "_blank" : "_self"}><button className={styles.button}>{props.text}</button></a>
    );
}