export class Sudoku {
    public static readonly EMPTYSLOT: number = -1;
    public gameArea: number[] = new Array(81);

    constructor() {
        for (let i = 0; i < this.gameArea.length; i++) {
            this.gameArea[i] = Sudoku.EMPTYSLOT;
        }
    }

    public get(x: number, y: number): number {
        return this.gameArea[x + y * 9];
    }

    public set(x: number, y: number, value: number): void {
        this.gameArea[x + y * 9] = value;
    }

    public isFree(x: number, y: number): boolean {
        return this.get(x, y) === Sudoku.EMPTYSLOT;
    }

    public fitsNumber(x: number, y: number, number: number): boolean {
        for (let yb = y - (y % 3); yb < 3 + y - (y % 3); yb++) {
            for (let xb = x - (x % 3); xb < 3 + x - (x % 3); xb++) {
                if (this.get(xb, yb) === number) return false;
            }
        }

        for (let xr = 0; xr < 9; xr++) {
            if (this.get(xr, y) === number) return false;
        }

        for (let yc = 0; yc < 9; yc++) {
            if (this.get(x, yc) === number) return false;
        }

        return true;
    }

    public getFreePlaces(): { x: number, y: number }[] {
        const freePlaces: Array<{ x: number, y: number }> = [];
        for (let i = 0; i < this.gameArea.length; i++) {
            if (this.gameArea[i] === Sudoku.EMPTYSLOT) {
                freePlaces.push({ x: i % 9, y: Math.floor(i / 9) });
            }
        }
        return freePlaces;
    }

    public isValidSolved(): boolean {
        //if (this.getFreePlaces().length > 0) return false;

        const blocks: Array<number>[] = new Array(9);
        const rows: Array<number>[] = new Array(9);
        const columns: Array<number>[] = new Array(9);

        for (let i = 0; i < 9; i++) {
            blocks[i] = new Array<number>();
            rows[i] = new Array<number>();
            columns[i] = new Array<number>();
        }

        for (let y = 0; y < 9; y++) {
            for (let x = 0; x < 9; x++) {
                if (this.get(x, y) === Sudoku.EMPTYSLOT) return false;

                const blockIndex = Math.floor(x / 3) + Math.floor(y / 3) * 3;

                if (blocks[blockIndex].includes(this.get(x, y))) {
                    return false;
                } else {
                    blocks[blockIndex].push(this.get(x, y));
                }

                if (rows[y].includes(this.get(x, y))) {
                    return false;
                } else {
                    rows[y].push(this.get(x, y));
                }

                if (columns[x].includes(this.get(x, y))) {
                    return false;
                } else {
                    columns[x].push(this.get(x, y));
                }
            }
        }

        return true;
    }

    public clone(): Sudoku {
        const clone = new Sudoku();
        for (let i = 0; i < this.gameArea.length; i++) {
            clone.gameArea[i] = this.gameArea[i];
        }
        return clone;
    }

    public equals(obj: any): boolean {
        if (!(obj instanceof Sudoku)) return false;

        const s = obj as Sudoku;

        for (let i = 0; i < this.gameArea.length; i++) {
            if (s.gameArea[i] !== this.gameArea[i]) return false;
        }

        return true;
    }
}

enum ExamineResult {
    MultipleSolutions = 0,
    LogicalInferable = 1,
    OneSolutionNotLogicalInferable = 2,
}

enum Difficulty {
    Easy = 0,
    Hard = 1,
}

export class SudokuGenerator {
    public Difficulty: Difficulty = Difficulty.Easy;

    public Generate(): Sudoku {
        const basePattern = this.GenerateValidBasePattern();

        const remaining: { x: number, y: number }[] = [];
        for (let x = 0; x < 9; x++) for (let y = 0; y < 9; y++) remaining.push({ x, y });

        return this.MakeHarder(basePattern, remaining);
    }

    public MakeHarder(sudoku: Sudoku, remaining: { x: number, y: number }[]): Sudoku {
        const n = Math.floor(Math.random() * remaining.length);
        const clone = sudoku.clone();
        clone.set(remaining[n].x, remaining[n].y, Sudoku.EMPTYSLOT);
        const res = this.examine(clone.clone());
        remaining.splice(n, 1);

        if (res === ExamineResult.MultipleSolutions || (res === ExamineResult.OneSolutionNotLogicalInferable && this.Difficulty === Difficulty.Easy)) {
            if (remaining.length === 0) return sudoku;
            return this.MakeHarder(sudoku, remaining);
        }

        if (remaining.length === 0) return clone;
        return this.MakeHarder(clone, remaining);
    }

    public GenerateValidBasePattern(): Sudoku {
        let sudoku = this.GenerateFurther(new Sudoku());
        while (!sudoku.isValidSolved()) sudoku = this.GenerateFurther(new Sudoku());
        return sudoku;
    }

    public GenerateValidBasePatternFromSudoku(sudoku: Sudoku): Sudoku {
        let s = this.GenerateFurther(sudoku.clone());
        while (!s.isValidSolved()) s = this.GenerateFurther(sudoku.clone());
        return s;
    }

    private GenerateFurther(sudoku: Sudoku): Sudoku {
        const possibilities: { x: number, y: number, possibleNums: number[] }[] = [];
        const free = sudoku.getFreePlaces();
        let found = false;

        do {
            possibilities.length = 0;

            for (let i = 0; i < free.length; i++) {
                possibilities.push({ x: free[i].x, y: free[i].y, possibleNums: this.GetPossibleNumbers(sudoku, free[i].x, free[i].y) });
            }

            found = false;

            for (let i = 0; i < possibilities.length; i++) {
                if (possibilities[i].possibleNums.length === 1) {
                    sudoku.set(possibilities[i].x, possibilities[i].y, possibilities[i].possibleNums[0]);
                    free.splice(i, 1);
                    possibilities.splice(i, 1);
                    i--;
                    found = true;
                }
            }
        } while (found);

        if (free.length === 0) return sudoku;

        const possibility = possibilities[Math.floor(Math.random() * possibilities.length)];

        if (possibility.possibleNums.length === 0) return sudoku;
        sudoku.set(possibility.x, possibility.y, possibility.possibleNums[Math.floor(Math.random() * possibility.possibleNums.length)]);

        return this.GenerateFurther(sudoku);
    }

    private GetPossibleNumbers(sudoku: Sudoku, x: number, y: number): number[] {
        const nums: number[] = [];
        for (let i = 1; i < 10; i++) {
            if (sudoku.fitsNumber(x, y, i)) nums.push(i);
        }
        return nums;
    }

    private examine(original: Sudoku): ExamineResult {
        const possibilities: { x: number, y: number, possibleNums: number[] }[] = [];
        const free = original.getFreePlaces();
        let found = false;

        do {
            possibilities.length = 0;

            for (let i = 0; i < free.length; i++) {
                possibilities.push({ x: free[i].x, y: free[i].y, possibleNums: this.GetPossibleNumbers(original, free[i].x, free[i].y) });
            }

            found = false;

            for (let i = 0; i < possibilities.length; i++) {
                if (possibilities[i].possibleNums.length === 1) {
                    original.set(possibilities[i].x, possibilities[i].y, possibilities[i].possibleNums[0]);
                    free.splice(i, 1);
                    possibilities.splice(i, 1);
                    i--;
                    found = true;
                }
            }
        } while (found);

        if (free.length === 0) return ExamineResult.LogicalInferable;

        const possibleEndings: Sudoku[] = [];

        for (let i = 0; i < possibilities[0].possibleNums.length; i++) {
            const newTry = original.clone();
            newTry.set(possibilities[0].x, possibilities[0].y, possibilities[0].possibleNums[i]);
            const res = this.examine(newTry);

            if (res === ExamineResult.MultipleSolutions) return ExamineResult.MultipleSolutions;

            for (let j = 0; j < possibleEndings.length; j++) {
                if (possibleEndings[j].equals(newTry)) return ExamineResult.MultipleSolutions;
            }

            possibleEndings.push(newTry);
        }

        return ExamineResult.OneSolutionNotLogicalInferable;
    }
}