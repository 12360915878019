import { useEffect, useState } from "react";
import styles from "./BlogPicture.module.scss";

type BlogPictureProps = {
    imageUrl: string,
    description?: string,
    altText: string,
    height: number
}

export default function BlogPicture(props: BlogPictureProps){
    const [uniqueId] = useState(`blogpic-${Math.random().toString(36).substr(2, 9)}`);
    useEffect(()=>{
        var img = document.getElementById(uniqueId) as HTMLImageElement;
        img.style.maxHeight = props.height + "px";
    },[]);
    return (
        <div className={styles.picture}>
            <img id={uniqueId} src={props.imageUrl} alt={props.altText} className={styles.image} />
            <div className={styles.description}>{props.description}</div>
        </div>
    );
}