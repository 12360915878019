import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./ProjectPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import Project from "../../Components/Project/Project";
import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import {Helmet} from 'react-helmet';

export default function ProjectPage(){
    return(
        <BasePage>
            <Helmet>
                <title>Projects | Finn Freitag</title>
                <meta name="description" content="Software projects by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, software, project, projects" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardTrible}>
                <h1 className={styles.headline}>Software Projects</h1>
            </div>
            <div className={cardStyles.cardTrible}>
                <Project imgUrl="/assets/projectImages/easepass_banner.png" title="Ease Pass" authors={[["https://frozenassassine.de/","Julius Kirsch"],["https://finnfreitag.com/","Finn Freitag"]]} urls={[["https://apps.microsoft.com/store/detail/ease-pass/9NQPF80923F5?hl=en-us&gl=us&ocid=pdpshare", "Download"], ["https://easepass.frozenassassine.de/", "Website"], ["https://www.github.com/FrozenAssassine/easepass", "Github"]]} >
                    Ease Pass is a simple password manager designed to streamline password management.
                    With this application, you can conveniently store, edit, and organize your passwords securely.
                    The project is a work in progress, focusing on providing a user-friendly interface to save and manage passwords, and future enhancements are underway to further enhance its capabilities.
                </Project>
            </div>
            <div className={cardStyles.cardTrible}>
                <Project imgUrl="/assets/projectImages/Authenticator.png" title="Authenticator" authors={[["https://finnfreitag.com/","Finn Freitag"]]} urls={[["/assets/downloads/Authenticator.exe", "Download"], ["https://github.com/finn-freitag/Authenticator", "Github"]]} >
                    Authenticator is a robust TOTP token generator designed for simplifying two-factor authentication. It allows users to generate, view, and manage TOTP tokens securely. The application offers a user-friendly interface, light/dark mode, and multiple encryption possibilities.
                </Project>
            </div>
            <div className={cardStyles.cardTrible}>
                <Project imgUrl="/assets/projectImages/quicknav_banner.png" title="Quick Nav" authors={[["https://frozenassassine.de/","Julius Kirsch"],["https://finnfreitag.com/","Finn Freitag"]]} urls={[["https://apps.microsoft.com/detail/9n8kbw01wd62?hl=en-us", "Download"], ["https://quicknav.frozenassassine.de/", "Website"], ["https://github.com/FrozenAssassine/QuickNav", "Github"]]} >
                    QuickNav is a powerful Windows tool that simplifies multitasking with just a keystroke. By pressing a Windows + Y, a search window pops up, allowing users to perform various tasks such as searching the web, finding files, running commands, and more. Currently in progress, QuickNav aims to enhance productivity and streamline user interaction with their system.
                </Project>
            </div>
            <div className={cardStyles.cardTrible}>
                <Project imgUrl="/assets/projectImages/sudoku.png" title="Sudoku" authors={[["https://finnfreitag.com/","Finn Freitag"]]} urls={[["https://github.com/finn-freitag/Sudoku", "Github"],["/tools/sudoku/","Open solver"],["/games/sudoku/","Start game"]]} >
                    This Sudoku project is a versatile tool that functions as both a solver and generator for Sudoku puzzles. It has the ability to solve existing puzzles and create new ones for users to tackle.
                </Project>
            </div>
            <div className={cardStyles.cardTrible}>
                <Project imgUrl="/assets/projectImages/kenken.png" title="KenKen" authors={[["https://finnfreitag.com/","Finn Freitag"]]} urls={[["https://github.com/finn-freitag/KenKen", "Github"]]} >
                    This KenKen project is a dual-purpose tool that serves as both a solver and generator for KenKen puzzles. It’s capable of solving existing puzzles and creating new ones for users to challenge themselves with.
                </Project>
            </div>
            <div className={cardStyles.cardTrible}>
                <div className={styles.info}>
                    <div className={styles.legal}><h3>For more projects, <Hyperlink href="https://github.com/finn-freitag?tab=repositories" text="visit my Github" textStyle /></h3></div>
                    <div className={styles.legal}>Please note that the developer makes no representation or warranty of any kind regarding the accuracy, adequacy, validity, reliability, availability, or completeness of this software.</div>
                </div>
            </div>
        </BasePage>
    );
}