import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./MorseTrainerPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import { useEffect } from "react";
import {Helmet} from 'react-helmet';

export default function MorseTrainerPage(){
    useEffect(() => {
        let morseBox: HTMLTextAreaElement = document.getElementById("textareamorse") as HTMLTextAreaElement;
        morseBox.style.fontFamily = "morse";
        generate();
    });
    return(
        <BasePage>
            <Helmet>
                <title>Morse Code Trainer | Finn Freitag</title>
                <meta name="description" content="Morse code trainer by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, morse, trainer" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardTrible}>
                <Card>
                    <textarea className={cardStyles.textbox} id="textareamorse" placeholder="Morse code..." readOnly />
                </Card>
            </div>
            <div className={cardStyles.cardTrible}>
                <Card>
                    <textarea className={cardStyles.textbox} id="textareatranslation" placeholder="Translation text..." onKeyUp={check} />
                </Card>
            </div>
            <div className={styles.legal}><Hyperlink href="https://www.dafont.com/morse.font" text="Font by Furetto Bislacco" newTab /></div>
        </BasePage>
    );
}

function check(){
    let morseBox: HTMLTextAreaElement = document.getElementById("textareamorse") as HTMLTextAreaElement;
    let translationBox: HTMLTextAreaElement = document.getElementById("textareatranslation") as HTMLTextAreaElement;
    if(morseBox.value.replaceAll("*", " ").toUpperCase() === translationBox.value.toUpperCase()){
        alert("Corret!");
        generate();
        translationBox.value = "";
    }
}

function generate(){
    let morseBox: HTMLTextAreaElement = document.getElementById("textareamorse") as HTMLTextAreaElement;
    var num1 = Math.floor(Math.random() * 10);
    var num2 = Math.floor(Math.random() * 10);
    let animals = ["human", "dog", "cat", "fox", "tiger", "lion", "wolf", "rhino", "hippo", "monkey", "rat", "deer", "elephant", "zebra"];
    let names = ["Charles", "Tim", "Harry", "Anna", "Elisabeth", "Lara", "Finn", "James", "Thomas", "Alice", "Arwen", "Eva", "Bob", "Ron", "Luis", "Lily", "Emma", "Mia", "Oliver", "Sophia", "Ethan", "Ava", "Mason", "Olivia", "Elijah", "Grace", "Jackson", "Isabella", "Liam", "Sophie", "Caleb", "Emily", "Nathan", "Aiden", "Zoe", "Lucas", "Logan", "Chloe", "Gabriel", "Hannah", "Oscar", "Ella", "Victoria", "Samuel", "David", "Natalie", "Henry", "Stella"];
    let adjectives = ["smart", "stupid", "strange", "weird", "cool", "nice", "great", "small", "big", "red", "blue", "green", "yellow", "orange", "purple", "black", "white", "pretty", "ancient", "old", "young", "lazy", "brave", "shy", "cunning", "funny", "serious", "playful", "calm", "chaotic", "elegant", "clumsy", "silly", "thoughtful", "carefree", "adventurous", "mysterious", "charming", "graceful", "energetic", "gloomy", "fierce", "gentle", "wise", "foolish", "creative", "logical", "curious", "puzzled", "daring"];
    let foods = ["apple", "steak", "orange", "pizza", "burger", "salad", "chocolate cake", "donut", "hot dog", "sausage"];
    let weatherArray = ["sunny", "rainy", "cloudy", "windy", "snowy", "foggy", "stormy", "clear", "overcast", "breezy", "humid", "chilly", "hot", "mild", "freezing"];
    let activityArray = ["reading", "swimming", "hiking", "cooking", "gardening", "painting", "cycling", "writing", "photography", "yoga", "skiing", "shopping", "traveling", "playing chess", "running"];
    let emotionArray = ["joyful", "excited", "calm", "content", "happy", "grateful", "relaxed", "inspired", "amused", "loving", "hopeful", "optimistic", "peaceful", "cheerful", "ecstatic"];
    let sentenceTemplates = [
        "The %adjective% %animal% %name% is very interested in %food%s.",
        "%name% is very happy about %adjective% %food%s.",
        "%name% likes %name%.",
        "Rotten %food% does not taste very good!",
        "Would a %animal% eat %adjective% %food%s?",
        "The %adjective% %animal% named %name% is very intrigued by %food% and %activity%.",
        "%name% is delighted by the %adjective% taste of %food% and the feeling of %emotion%.",
        "%name%'s fondness for %animal%s is like enjoying a %adjective% %food% on a sunny day.",
        "Spoiled %food% can ruin the experience of %name%'s favorite %activity%.",
        "Can you imagine a %animal% savoring the flavor of %adjective% %food%s during %activity%?",
        "The %adjective% %animal% named %name% is very intrigued by %food% and %activity% under the %weather% sky.",
        "%name%'s joy is palpable when indulging in %adjective% %food%s and participating in %activity%.",
        "%name% finds solace in the companionship of %name% and the delightful taste of %adjective% %food%.",
        "Decayed %food%s never fail to disappoint %name%'s taste buds!",
        "Is it feasible that a %animal% would relish %adjective% %food%s during %activity%?",
        "The lively %adjective% %animal% named %name% is captivated by %food% and enjoys %activity%.",
        "%name%'s happiness reaches new heights with each bite of %adjective% %food% and the thrill of %activity%.",
        "%name% has a unique bond with %name%, similar to savoring %adjective% %food% and relishing %activity%.",
        "Consuming spoiled %food% is as unpleasant as %name%'s experience during %activity%.",
        "Picture a %animal% engaging in %adjective% %food%-related antics while %activity%.",
        "The %adjective% %animal% named %name% is fascinated by %food% and finds joy in %activity%.",
        "%name% expresses pure delight when presented with %adjective% %food%s and engaging in %activity%.",
        "%name%'s affection for %name% rivals the pleasure derived from savoring %adjective% %food%.",
        "Deteriorated %food% leaves an undesirable taste, much like %name%'s feelings after %activity%.",
        "Would a %animal% truly appreciate the nuances of %adjective% %food%s during %activity%?",
        num1  + " + " + num2 + " = " + (num1 + num2),
        num1  + " - " + num2 + " = " + (num1 - num2),
    ];
    let sentence = sentenceTemplates[Math.floor(Math.random() * sentenceTemplates.length)]
        .replace("%animal%", animals[Math.floor(Math.random() * animals.length)])
        .replace("%animal%", animals[Math.floor(Math.random() * animals.length)])
        .replaceAll("%animal%", animals[Math.floor(Math.random() * animals.length)])
        .replace("%name%", names[Math.floor(Math.random() * names.length)])
        .replace("%name%", names[Math.floor(Math.random() * names.length)])
        .replaceAll("%name%", names[Math.floor(Math.random() * names.length)])
        .replace("%adjective%", adjectives[Math.floor(Math.random() * adjectives.length)])
        .replace("%adjective%", adjectives[Math.floor(Math.random() * adjectives.length)])
        .replaceAll("%adjective%", adjectives[Math.floor(Math.random() * adjectives.length)])
        .replace("%food%", foods[Math.floor(Math.random() * foods.length)])
        .replace("%food%", foods[Math.floor(Math.random() * foods.length)])
        .replaceAll("%food%", foods[Math.floor(Math.random() * foods.length)])
        .replace("%weather%", weatherArray[Math.floor(Math.random() * weatherArray.length)])
        .replace("%weather%", weatherArray[Math.floor(Math.random() * weatherArray.length)])
        .replaceAll("%weather%", weatherArray[Math.floor(Math.random() * weatherArray.length)])
        .replace("%emotion%", emotionArray[Math.floor(Math.random() * emotionArray.length)])
        .replace("%emotion%", emotionArray[Math.floor(Math.random() * emotionArray.length)])
        .replaceAll("%emotion%", emotionArray[Math.floor(Math.random() * emotionArray.length)])
        .replace("%activity%", activityArray[Math.floor(Math.random() * activityArray.length)])
        .replace("%activity%", activityArray[Math.floor(Math.random() * activityArray.length)])
        .replaceAll("%activity%", activityArray[Math.floor(Math.random() * activityArray.length)]);
    sentence = sentence.replaceAll(" ","*");
    morseBox.value = sentence.toLowerCase();
}