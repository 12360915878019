import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./GeographyPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import SVGCard from "../../Components/SVGCard/SVGCard";
import {Helmet} from 'react-helmet';

export default function GeographyPage(){
    return(
        <BasePage>
            <Helmet>
                <title>Geography | Finn Freitag</title>
                <meta name="description" content="Geography quizzes by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, geography, quiz" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardSingle}>
                <Card>
                    <h1 className={styles.headline}>Geography quizzes:</h1>
                    <ul>
                        <li><Hyperlink href="/geography/africa.html?lang=en-us" text="Africa" /></li>
                        <li><Hyperlink href="/geography/australia.html" text="Australia" /></li>
                        <li><Hyperlink href="/geography/australia_cities.html" text="Australia Cities" /></li>
                        <li><Hyperlink href="/geography/canarias.html" text="Canarias" /></li>
                        <li><Hyperlink href="/geography/caribbean.html" text="Caribbean" /></li>
                        <li><Hyperlink href="/geography/europe.html" text="Europe" /></li>
                        <li><Hyperlink href="/geography/germany.html?lang=en-us" text="Germany" /></li>
                        <li><Hyperlink href="/geography/southamerica.html" text="South America" /></li>
                        <li><Hyperlink href="/geography/usa.html" text="USA" /></li>
                        <li><Hyperlink href="/geography/usa_nicknames.html" text="USA Nicknames" /></li>
                    </ul>
                </Card>
            </div>
            <SVGCard src="/assets/svgs/nature.svg" />
            <div className={cardStyles.cardSingle}>
                <Card>
                    <h1 className={styles.headline}>German versions:</h1>
                    <ul>
                        <li><Hyperlink href="/geography/africa.html?lang=de" text="Afrika" /></li>
                        <li><Hyperlink href="/geography/australia.html?lang=de" text="Australien" /></li>
                        <li><Hyperlink href="/geography/australia_cities.html" text="Australien Städte" /></li>
                        <li><Hyperlink href="/geography/canarias.html?lang=de" text="Kanaren" /></li>
                        <li><Hyperlink href="/geography/caribbean.html" text="Karibik" /></li>
                        <li><Hyperlink href="/geography/europe.html?lang=de" text="Europa" /></li>
                        <li><Hyperlink href="/geography/germany.html?lang=de" text="Deutschland" /></li>
                        <li><Hyperlink href="/geography/southamerica.html?lang=de" text="Südamerika" /></li>
                        <li><Hyperlink href="/geography/usa.html?lang=de" text="USA" /></li>
                        <li><Hyperlink href="/geography/usa_nicknames.html" text="USA Spitznamen" /></li>
                    </ul>
                </Card>
            </div>
            <div className={styles.legal}>You do not have permission to use these vector graphics for anything other than taking these quizzes.</div>
        </BasePage>
    );
}