import React, { useState } from 'react';
import styles from './Card.module.scss';

type CardProps = {
    children: React.ReactNode;
}

export default function Card(props: CardProps) {
    return (
        <div className={styles.card}>
            {props.children}
        </div>
    );
}