import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import { BlogArticle, GetUrlFromArticle } from "../../Data/BlogArticles";
import BlogAuthorFinnFreitag from "../../Data/BlogAuthorProps";
import { AuthorProps } from "../../Pages/BlogPage/BlogAuthor";
import BlogJavaCode from "../../Pages/BlogPage/BlogJavaCode";
import BlogPage from "../../Pages/BlogPage/BlogPage";
import BlogPicture from "../../Pages/BlogPage/BlogPicture";
import InsertionSortBlogPage from "../InsertionSortBlogPage/InsertionSortBlogPage";

export default function BubbleSortBlogPage(): BlogArticle {
    var url: string = "bubblesort";
    var title: string = "Bubble Sort";
    var imageUrl: string = "/assets/blog/bubblesort/header.jpg";
    var author: AuthorProps = BlogAuthorFinnFreitag();
    var keywords: string = "sort, algorithm, bubble";
    var description: string = "When diving into the world of algorithms, one of the first sorting techniques you'll likely encounter is Bubble Sort, also known as sinking sort. Despite its simplicity, Bubble Sort provides a fundamental understanding of algorithmic thinking and the mechanics behind sorting data.";
    var releaseDate: string = "24.05.2024";
    return {
        Title: title,
        UrlName: url,
        ImageUrl: imageUrl,
        Keywords: keywords,
        Description: description,
        ReleaseDate: releaseDate,
        Author: author,
        ArticlePage: (
            <BlogPage
                title={title}
                description={description}
                keywords={keywords}
                releaseDate={releaseDate}
                imageUrl={imageUrl}
                author={author}>
                <div>

<h2>How Bubble Sort Works</h2>
<p>
Bubble Sort operates by repeatedly iterating
through the input list, comparing each element
with the next one, and swapping them if they
are in the wrong order. This process is repeated
until a complete pass through the list is made
without any swaps, indicating that the list is
fully sorted. Alternatively {"("}or in combination{")"},
you can also omit one more element at the end of
each run, as this area has already been sorted in
the previous runs. The algorithm gets its name from
the way larger elements "bubble" up to the top
of the list during the sorting process.
</p>

<h3>Step-by-step breakdown:</h3>
<ol>
<li><strong>Start at the beginning of the list:</strong><br />Compare the first element with the second element.</li>
<li><strong>Swap if necessary:</strong><br />If the first element is greater than the second element, swap them.</li>
<li><strong>Move to the next pair:</strong><br />Compare the second and third elements, and swap if necessary.</li>
<li><strong>Continue this process:</strong><br />Repeat the comparison and swapping until you reach the end of the list. You could also stop at the end of the list minus the zero-based index of the current cycle.</li>
<li><strong>Repeat:</strong><br />Go back to the beginning of the list and repeat the process for the entire list.</li>
<li><strong>Check for completion:</strong><br />Continue iterating until a complete pass is made without any swaps. This step is only necessary if you don't decrease the number of comparisons per cycle.</li>
</ol>

<BlogPicture
    imageUrl="/assets/blog/bubblesort/bubblesortdark.gif"
    altText="Bubblesort Animation"
    height={200}
    description="Animation of Bubble Sort" />

<h2>Performance and Practicality</h2>
<p>
While Bubble Sort is easy to understand and implement,
it is not efficient for large datasets. Its time
complexity is <i>O{"("}n<sup>2</sup>{")"}</i>, meaning
that the time it takes to sort the list grows
quadratically with the number of elements. This
inefficiency makes Bubble Sort impractical for real-world
applications where faster algorithms are available.
</p>

<h3>When to Use Bubble Sort</h3>
<ul>
<li><strong>Educational Purposes:</strong><br />Bubble Sort is perfect for teaching the basics of sorting algorithms and algorithmic thinking.</li>
<li><strong>Small Datasets:</strong><br />For very small lists, the simplicity of Bubble Sort might be sufficient.</li>
<li><strong>Nearly Sorted Data:</strong><br />If the data is nearly sorted, Bubble Sort can be more efficient, as it can quickly confirm the sorted order.</li>
<li><strong>Fixed Memory Usage:</strong><br />Bubble sort has the advantage of requiring a constant amount of memory, making it suitable in environments where memory usage is a concern.</li>
</ul>

<h3>Alternatives to Bubble Sort</h3>
<p>For practical purposes, other more efficient sorting algorithms are preferred:</p>
<ul>
<li><strong>Quicksort:</strong><br />An efficient, comparison-based sorting algorithm that uses divide-and-conquer.</li>
<li><strong>Timsort:</strong><br />A hybrid sorting algorithm derived from merge sort and <Hyperlink href={GetUrlFromArticle(InsertionSortBlogPage())} text="insertion sort" textStyle />, used in Python's built-in sort function.</li>
<li><strong>Merge Sort:</strong><br />A stable, comparison-based sorting algorithm that uses divide-and-conquer.</li>
</ul>

<h2>Bubble Sort Code {"("}Java{")"}</h2>
<BlogJavaCode>
{`public static void bubbleSort(int[] array) {
    int n = array.length;
    for (int j = 0; j < n - 1; j++) {
        boolean swapRequired = false;
        for (int k = 0; k < n - j - 1; k++) {
            if (array[k] > array[k + 1]) {
                int temp = array[k];
                array[k] = array[k + 1];
                array[k + 1] = temp;
                swapRequired = true;
            }
        }
        if(!swapRequired)
            return;
    }
}`}
</BlogJavaCode>

<h2>Bubble Sort Structogram</h2>
<BlogPicture
    imageUrl="/assets/blog/bubblesort/bubblesortdark.png"
    altText="Bubblesort Structogram"
    height={600}
    description="Structogram of Bubble Sort" />

<h2>Conclusion</h2>
<p>
Bubble Sort may not be the go-to choice for practical applications
due to its <i>O{"("}n<sup>2</sup>{")"}</i> time complexity, but
it holds an important place in the study of algorithms. Its
simplicity provides a gentle introduction to sorting techniques
and lays the foundation for understanding more complex algorithms.
Whether you're a beginner learning the basics or a teacher
explaining sorting concepts, Bubble Sort remains a valuable educational tool.
</p>

                </div>
            </BlogPage>
    )};
}