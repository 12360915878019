export const addXMLHeader = (code: string, name: string = "???"): string => {
    return `<?xml version="1.0" encoding="UTF-8"?><root text="&#34;${name}&#34;" comment="&#34;&#34;" color="ffffff" type="program" style="nice"><children>${code}</children></root>`;
};

export const getXMLFromJava = (code: string): string => {
    // Clear comments
    let lines: string[] = code.split(/\r\n/);
    let newCode = "";
    for (let line of lines) {
        let index = firstIndexOf(line, "//");
        if (index > -1) {
            newCode += line.substring(0, index);
        } else {
            newCode += line;
        }
    }
    code = newCode;
    code = clearMultilineComments(code);
    code = clearDoubleSpace(code);
    code = clearWhitespace(code);

    // Split commands
    let commands: string[] = [];
    let currentCommand = "";
    let bracket = 0;
    let normalBracket = 0;
    let isQuotation = false;
    let isSimpleQuotation = false;

    for (let i = 0; i < code.length; i++) {
        let match = false;

        if (
            code[i] === ";" &&
            !isQuotation &&
            !isSimpleQuotation &&
            bracket === 0 &&
            normalBracket === 0
        ) {
            //console.log(clearWhitespace(currentCommand + ";"));
            commands.push(clearWhitespace(currentCommand + ";"));
            currentCommand = "";
            match = true;
        }

        if (code[i] === "'" && (i === 0 || code[i - 1] !== "\\")) {
            isSimpleQuotation = !isSimpleQuotation;
        }

        if (code[i] === '"' && (i === 0 || code[i - 1] !== "\\")) {
            isQuotation = !isQuotation;
        }

        if (code[i] === "{" && !isQuotation && !isSimpleQuotation) {
            bracket++;
        }

        if (code[i] === "}" && !isQuotation && !isSimpleQuotation) {
            bracket--;
            if (bracket === 0) {
                if (i !== code.length - 1) {
                    if (
                        !isPunctuation(
                            clearWhitespace(code.substring(i + 1))[0]
                        )
                    ) {
                        commands.push(clearWhitespace(currentCommand + "}"));
                        currentCommand = "";
                        match = true;
                    }
                } else {
                    commands.push(clearWhitespace(currentCommand + "}"));
                    currentCommand = "";
                    match = true;
                }
            }
        }

        if (code[i] === "(" && !isQuotation && !isSimpleQuotation) {
            normalBracket++;
        }

        if (code[i] === ")" && !isQuotation && !isSimpleQuotation) {
            normalBracket--;
        }

        if (!match) {
            currentCommand += code[i];
        }
    }

    // Parse to XML
    let xmlCode = "";

    for (let i = 0; i < commands.length; i++) {
        let command = commands[i];
        if (!command.startsWith("//")) {
            if (command.toLowerCase().startsWith("for")) {
                let res = getBetweenBrackets(command);
                if (res[1] < 0) {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket)");
                }
                let conditionParts = res[0].split(";");
                if (
                    conditionParts.length !== 3 &&
                    conditionParts.length !== 1
                ) {
                    console.log("Error: " + "No valid Java-Syntax (Wrong number of parameters)");
                }
                res = getBetweenBrackets(command, res[1], "{", "}");
                if (res[1] < 0) {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket)");
                }
                if (conditionParts.length === 1) {
                    let doublePointIndex = firstIndexOf(conditionParts[0], ":");
                    if (doublePointIndex < 0) {
                        console.log("Error: " + "No valid Java-Syntax (Unknown type of foreach-loop)");
                    }
                    let firstPart = conditionParts[0].substring(
                        0,
                        doublePointIndex
                    );
                    let secondPart = conditionParts[0].substring(
                        doublePointIndex + 1,
                        conditionParts[0].length - doublePointIndex - 2
                    );
                    xmlCode += `<for text="&#34;for ${clearWhitespace(
                        firstPart
                    )} : ${clearWhitespace(
                        secondPart
                    )} &#34;" comment="&#34;&#34;" color="ffffff"><qFor>${getXMLFromJava(
                        res[0]
                    )}</qFor></for>`;
                } else {
                    conditionParts[0] = clearWhitespace(conditionParts[0]);
                    conditionParts[1] = clearWhitespace(conditionParts[1]);
                    conditionParts[2] = clearWhitespace(conditionParts[2]);
                    if (
                        conditionParts[0].length === 0 &&
                        conditionParts[1].length === 0 &&
                        conditionParts[2].length === 0
                    ) {
                        xmlCode += `<forever text="" comment="&#34;&#34;" color="ffffff"><qForever>${getXMLFromJava(
                            res[0]
                        )}</qForever></forever>`;
                        console.log("infinteloop");
                    } else {
                        xmlCode += `<for text="&#34;for ${
                            formatCommand(conditionParts[0])
                        } ; ${formatCommand(conditionParts[1])} ; ${
                            formatCommand(conditionParts[2])
                        } &#34;" comment="&#34;&#34;" color="ffffff"><qFor>${getXMLFromJava(
                            res[0]
                        )}</qFor></for>`;
                        console.log("for");
                    }
                }
            } else if (
                command.toLowerCase().startsWith("while") &&
                !isLetterOrNumber(command[5])
            ) {
                let res = getBetweenBrackets(command);
                let condition = clearWhitespace(res[0]);
                if (res[1] < 0 || condition === "") {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket or missing condition)");
                }
                res = getBetweenBrackets(command, res[1], "{", "}");
                if (res[1] < 0) {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket)");
                }
                if (condition.toLowerCase() === "true") {
                    xmlCode += `<forever text="" comment="&#34;&#34;" color="ffffff"><qForever>${getXMLFromJava(
                        res[0]
                    )}</qForever></forever>`;
                    console.log("infiniteloop");
                } else {
                    xmlCode += `<while text="&#34;while ${formatCommand(condition)} &#34;" comment="&#34;&#34;" color="ffffff"><qWhile>${getXMLFromJava(
                        res[0]
                    )}</qWhile></while>`;
                    console.log("loop: " + condition);
                }
            } else if (
                command.toLowerCase().startsWith("do") &&
                !isLetterOrNumber(command[2])
            ) {
                let res = getBetweenBrackets(command, 0, "{", "}");
                if (res[1] < 0) {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket)");
                }
                let content = res[0];
                i++;
                command = clearWhitespace(commands[i]);
                res = getBetweenBrackets(command);
                let condition = clearWhitespace(res[0]);
                if (res[1] < 0 || condition === "") {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket or missing condition)");
                }
                if (condition.toLowerCase() === "true") {
                    xmlCode += `<forever text="" comment="&#34;&#34;" color="ffffff"><qForever>${getXMLFromJava(
                        content
                    )}</qForever></forever>`;
                    console.log("infiniteloop");
                } else {
                    xmlCode += `<repeat text="&#34;do ${formatCommand(condition)} &#34;" comment="&#34;&#34;" color="ffffff"><qRepeat>${getXMLFromJava(
                        content
                    )}</qRepeat></repeat>`;
                    console.log("loop: " + condition);
                }
            } else if (
                command.toLowerCase().startsWith("if") &&
                !isLetterOrNumber(command[2])
            ) {
                let res = getBetweenBrackets(command);
                let condition = clearWhitespace(res[0]);
                if (res[1] < 0 || condition === "") {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket or missing condition)");
                }
                let lastEndIndex = res[1];
                res = getBetweenBrackets(command, lastEndIndex, "{", "}");
                if (res[1] < 0) {
                    res = [command.substring(lastEndIndex), -1];
                }
                let elseCode = "";
                if (
                    i + 1 < commands.length &&
                    commands[i + 1].toLowerCase().startsWith("else")
                ) {
                    i++;
                    let commandPart2 = clearWhitespace(
                        commands[i].substring(4)
                    );
                    if (commandPart2.startsWith("{")) {
                        elseCode = getXMLFromJava(
                            getBetweenBrackets(commandPart2, 0, "{", "}")[0]
                        );
                    } else {
                        elseCode = getXMLFromJava(commandPart2);
                    }
                }
                xmlCode += `<alternative text="&#34;${formatCommand(condition)} &#34;" comment="&#34;&#34;" color="ffffff"><qTrue>${getXMLFromJava(
                    res[0]
                )}</qTrue><qFalse>${elseCode}</qFalse></alternative>`;
                console.log("if: " + condition);
            } else if (
                command.toLowerCase().startsWith("switch") &&
                !isLetterOrNumber(command[6])
            ) {
                let res = getBetweenBrackets(command);
                let condition = clearWhitespace(res[0]);
                if (res[1] < 0 || condition === "") {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket or missing condition)");
                }
                let lastEndIndex = res[1];
                res = getBetweenBrackets(command, lastEndIndex, "{", "}");
                if (res[1] < 0) {
                    console.log("Error: " + "No valid Java-Syntax (Missing bracket)");
                }
                let cases = getCaseCode(res[0]);
                cases.reverse();
                let headerLine = "&#34;" + formatCommand(condition) + "&#34;";
                let xml = "";
                let caseCount = 0;
                let defaultVal = ["", ""];
                for (let Case of cases) {
                    if (Case[0] === "") {
                        defaultVal = Case;
                    } else {
                        headerLine += ",&#34;" + formatCommand(Case[0]) + "&#34;";
                        xml += `<qCase>${getXMLFromJava(Case[1])}</qCase>`;
                        caseCount++;
                    }
                }
                if (caseCount < 1) {
                    headerLine += ",&#34;null&#34;";
                    xml += "<qCase></qCase>";
                }
                if (defaultVal[0] !== null) {
                    headerLine += ",&#34;default&#34;";
                    xml += `<qCase>${getXMLFromJava(defaultVal[1])}</qCase>`;
                } else {
                    headerLine += ",&#34;default&#34;";
                    xml += "<qCase></qCase>";
                }
                xmlCode += `<case text="${headerLine}" comment="&#34;&#34;" color="ffffff">${xml}</case>`;
                console.log("switch: " + command);
            } else if (
                command.toLowerCase().startsWith("return") &&
                !isLetterOrNumber(command[6])
            ) {
                xmlCode += `<jump text="&#34;${formatCommand(command)} &#34;" comment="&#34;&#34;" color="ffffff"></jump>`;
                console.log("ret: " + command);
            } else {
                xmlCode += `<instruction text="&#34;${formatCommand(command)} &#34;" comment="&#34;&#34;" color="ffffff" rotated="0"></instruction>`;
                console.log("inst: " + command);
            }
        }
    }
    return xmlCode;
};

export const clearMultilineComments = (str: string): string => {
    let openIndex = firstIndexOf(str, "/*");
    let closeIndex = firstIndexOf(str, "*/");

    if (openIndex !== -1 && closeIndex !== -1) {
        return clearMultilineComments(
            str.substring(0, openIndex) +
                str.substring(closeIndex + 2, str.length - closeIndex - 2)
        );
    }

    return str;
};

export const getCaseCode = (
    switchCase: string,
    offset: number = 0
): Array<[string, string]> => {
    switchCase = clearWhitespace(switchCase);
    let res = readUntil(switchCase, "case", offset);
    let res2 = readUntil(switchCase, "default", offset);

    if (
        (res[1] > -1 && res[1] < res2[1] && res2[1] > -1) ||
        (res[1] > -1 && res2[1] < 0)
    ) {
        offset += 4;
        let condition = readUntil(switchCase, ":", offset);
        offset += condition[0].length;
        offset++;
        let code = readUntil(switchCase, "case", offset);
        let code2 = readUntil(switchCase, "default", offset);

        if ((code2[1] > -1 && code2[1] < code[1]) || code[1] < 0) {
            code = code2;
        }

        if (code[1] < 0) {
            code = [switchCase.substring(offset), 0];
        }

        offset += code[0].length;

        let rest = getCaseCode(switchCase, offset);
        rest.push([clearWhitespace(condition[0]), clearWhitespace(code[0])]);
        return rest;
    } else if (
        (res2[1] > -1 && res2[1] < res[1] && res[1] > -1) ||
        (res2[1] > -1 && res[1] < 0)
    ) {
        offset += 7;
        let condition = readUntil(switchCase, ":", offset);
        offset += condition[0].length;
        offset++;
        let code = readUntil(switchCase, "case", offset);
        let code2 = readUntil(switchCase, "default", offset);

        if ((code2[1] < code[1] && code2[1] > -1) || code[1] < 0) {
            code = code2;
        }

        if (code[1] < 0) {
            code = [switchCase.substring(offset), 0];
        }

        offset += code[0].length;

        let rest = getCaseCode(switchCase, offset);
        rest.push(["", code[0]]);
        return rest;
    }

    return [];
};

export const readUntil = (
    str: string,
    until: string,
    offset: number = 0,
    ignoreInner: string = "\"'"
): [string, number] => {
    let bracket = 0;
    let normalBracket = 0;
    let res = "";
    let isQuotation: Record<string, boolean> = {};

    for (let i = offset; i < str.length; i++) {
        if (
            str[i] === "{" &&
            (!isQuotation[str[i]] ||
                (isQuotation[str[i]] && !isQuotation[str[i]]))
        ) {
            bracket++;
        }

        if (
            str[i] === "}" &&
            (!isQuotation[str[i]] ||
                (isQuotation[str[i]] && !isQuotation[str[i]]))
        ) {
            bracket--;
        }

        if (
            str[i] === "(" &&
            (!isQuotation[str[i]] ||
                (isQuotation[str[i]] && !isQuotation[str[i]]))
        ) {
            normalBracket++;
        }

        if (
            str[i] === ")" &&
            (!isQuotation[str[i]] ||
                (isQuotation[str[i]] && !isQuotation[str[i]]))
        ) {
            normalBracket--;
        }

        if (ignoreInner.includes(str[i])) {
            if (isQuotation[str[i]]) {
                isQuotation[str[i]] = !isQuotation[str[i]];
            } else {
                isQuotation[str[i]] = true;
            }
        }

        res += str[i];

        if (
            res.endsWith(until) &&
            bracket === 0 &&
            normalBracket === 0 &&
            !Object.values(isQuotation).some((value) => value)
        ) {
            return [
                res.substring(0, res.length - until.length),
                i + 1 - until.length,
            ];
        }
    }

    return ["", -1];
};

export const getBetweenBrackets = (
    str: string,
    offset: number = 0,
    bracketOpen: string = "(",
    bracketClose: string = ")",
    ignoreInner: string = "\"'"
): [string, number] => {
    let bracket = 0;
    let innerBrackets = "";
    let wasInner = false;
    let isQuotation: Record<string, boolean> = {};

    for (let i = offset; i < str.length; i++) {
        if (
            str[i] === bracketOpen &&
            (!isQuotation[str[i]] ||
                (isQuotation[str[i]] && !isQuotation[str[i]]))
        ) {
            bracket++;
            wasInner = true;
        }

        if (
            str[i] === bracketClose &&
            (!isQuotation[str[i]] ||
                (isQuotation[str[i]] && !isQuotation[str[i]]))
        ) {
            bracket--;
        }

        if (ignoreInner.includes(str[i])) {
            if (isQuotation[str[i]]) {
                isQuotation[str[i]] = !isQuotation[str[i]];
            } else {
                isQuotation[str[i]] = true;
            }
        }

        if (bracket !== 0) {
            innerBrackets += str[i];
        }

        if (
            bracket === 0 &&
            wasInner &&
            !Object.values(isQuotation).some((value) => value)
        ) {
            return [innerBrackets.substring(1), i + 1];
        }
    }

    return ["", -1];
};

export const firstIndexOf = (
    value: string,
    until: string,
    offset: number = 0,
    ignoreInner: string = "\"'"
): number => {
    let isQuotation: Record<string, boolean> = {};
    let res = "";

    for (let i = offset; i < value.length; i++) {
        if (ignoreInner.includes(value[i])) {
            if (isQuotation[value[i]]) {
                isQuotation[value[i]] = !isQuotation[value[i]];
            } else {
                isQuotation[value[i]] = true;
            }
        }

        res += value[i];

        if (
            res.endsWith(until) &&
            !Object.values(isQuotation).some((value) => value)
        ) {
            return i - until.length + 1;
        }
    }

    return -1;
};

export const clearWhitespace = (command: string): string => {
    command = command.trim();
    return command;
};

export const clearDoubleSpace = (str: string): string => {
    let index = firstIndexOf(str, "  ");
    if (index > -1) {
        return clearDoubleSpace(str.slice(0, index) + str.slice(index + 1));
    }
    return str;
};

export const isLetterOrNumber = (str: string): boolean => {
    return (
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".indexOf(
            str
        ) >= 0
    );
};

export const isPunctuation = (str: string): boolean => {
    return (
        !isLetterOrNumber(str) &&
        str !== " " &&
        str !== "\t" &&
        str !== "\r" &&
        str !== "\n"
    );
};

export const formatCommand = (command: string): string => {
    if (command.endsWith(";")) {
        command = command.substring(0, command.length - 1);
    }

    command = command.replaceAll("&", "&#38;");
    command = command.replaceAll('"', "&#34;&#34;");
    command = command.replaceAll("<", "&#60;");
    command = command.replaceAll(">", "&#62;");
    command = command.replaceAll("|", "&#124;");

    return command;
};
