import React, { useState } from 'react';
import styles from './Hyperlink.module.scss';

type HyperlinkProps = {
    href: string;
    newTab?: boolean;
    text: string;
    textStyle?: boolean;
}

export default function Hyperlink(props: HyperlinkProps) {
    return (
        <a className={props.textStyle == true ? styles.hyperlinkTextStyle : styles.hyperlink} href={props.href} target={props.newTab == true ? "_blank" : "_self"}>
            {props.text}
        </a>
    );
}