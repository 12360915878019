import { BlogArticle } from "../../Data/BlogArticles";
import BlogAuthorFinnFreitag from "../../Data/BlogAuthorProps";
import { AuthorProps } from "../../Pages/BlogPage/BlogAuthor";
import BlogJavaCode from "../../Pages/BlogPage/BlogJavaCode";
import BlogPage from "../../Pages/BlogPage/BlogPage";
import BlogPicture from "../../Pages/BlogPage/BlogPicture";

export default function InsertionSortBlogPage(): BlogArticle {
    var url: string = "insertionsort";
    var title: string = "Insertion Sort";
    var imageUrl: string = "/assets/blog/insertionsort/header.jpg";
    var author: AuthorProps = BlogAuthorFinnFreitag();
    var keywords: string = "sort, algorithm, insertion";
    var description: string = "Insertion sort is one of the simplest sorting algorithms, often introduced early in computer science education. While it may not be the most efficient method for large datasets, its straightforward approach provides a solid foundation for understanding more complex sorting techniques.";
    var releaseDate: string = "25.05.2024";
    return {
        Title: title,
        UrlName: url,
        ImageUrl: imageUrl,
        Keywords: keywords,
        Description: description,
        ReleaseDate: releaseDate,
        Author: author,
        ArticlePage: (
            <BlogPage
                title={title}
                description={description}
                keywords={keywords}
                releaseDate={releaseDate}
                imageUrl={imageUrl}
                author={author}>
                <div>

<h2>How Insertion Sort Works</h2>
<p>
Insertion sort operates by iterating through the input
list and inserting each current value into its correct
position within the already sorted portion of the list.
This process continues until the entire list is sorted.
</p>

<h3>Step-by-step breakdown:</h3>
<ol>
<li><strong>Start with the first element:</strong><br />Consider the first element as a sorted portion.</li>
<li><strong>Move to the next element:</strong><br />Take the next element and compare it with the elements in the sorted portion.</li>
<li><strong>Find the correct position:</strong><br />Insert the current element into its correct position within the sorted portion.</li>
<li><strong>Repeat:</strong><br />Move to the next element and repeat the process until the entire list is sorted.</li>
</ol>

<BlogPicture
    imageUrl="/assets/blog/insertionsort/insertionsortdark.gif"
    altText="Insertionsort Animation"
    height={200}
    description="Animation of Insertion Sort" />

<h2>Performance and Practicality</h2>
<p>
Insertion sort is not the most efficient sorting algorithm,
particularly for large datasets. Its time complexity
is <i>O{"("}n<sup>2</sup>{")"}</i>, meaning that the time
required to sort the list grows quadratically with the number
of elements. However, insertion sort can be quite efficient
for small datasets or lists that are already nearly sorted.
</p>

<h3>When to Use Insertion Sort</h3>
<ul>
<li><strong>Educational Purposes:</strong><br />Ideal for teaching basic sorting concepts and algorithmic thinking.</li>
<li><strong>Small Datasets:</strong><br />Suitable for small lists where its simplicity outweighs its inefficiency.</li>
<li><strong>Fixed Memory Usage:</strong><br />Insertion sort has the advantage of requiring a constant amount of memory, making it suitable in environments where memory usage is a concern.</li>
</ul>

<h3>Alternatives to Insertion Sort</h3>
<p>For practical purposes and larger datasets, more efficient sorting algorithms are preferred:</p>
<ul>
<li><strong>Quicksort:</strong><br />A fast, comparison-based sorting algorithm that uses divide-and-conquer.</li>
<li><strong>Timsort:</strong><br />A hybrid sorting algorithm derived from merge sort and insertion sort, used in Python's built-in sort function.</li>
<li><strong>Merge Sort:</strong><br />A stable, comparison-based sorting algorithm that also uses divide-and-conquer.</li>
</ul>

<h2>Insertion Sort Code {"("}Java{")"}</h2>
<BlogJavaCode>
{`public static void insertionSort(int[] array) {
    int n = array.length;
    for (int j = 1; j < n; j++) {
        int key = array[j];
        int k = j - 1;
        while (k >= 0 && array[k] > key) {
            array[k + 1] = array[k];
            k = k - 1;
        }
        array[k + 1] = key;
    }
}`}
</BlogJavaCode>

<h2>Insertion Sort Structogram</h2>
<BlogPicture
    imageUrl="/assets/blog/insertionsort/insertionsortdark.png"
    altText="Insertionsort Structogram"
    height={600}
    description="Structogram of Insertion Sort" />

<h2>Conclusion</h2>
<p>
While insertion sort may not be the best choice for large
datasets due to its <i>O{"("}n<sup>2</sup>{")"}</i> time complexity, it remains an
important algorithm in the realm of computer science education.
Its simple yet effective approach to sorting provides a
stepping stone to understanding more advanced algorithms like timsort.
Whether you're a beginner learning about sorting or an educator
explaining fundamental concepts, insertion sort serves as a valuable tool.
</p>

                </div>
            </BlogPage>
    )};
}