import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./GamesPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import {Helmet} from 'react-helmet';

export default function GamesPage(){
    return(
        <BasePage>
            <Helmet>
                <title>Games | Finn Freitag</title>
                <meta name="description" content="Games, puzzles and more by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, games" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardDouble}>
                <Card>
                    <h1 className={styles.headline}>Games</h1>
                    <ul>
                        <li><Hyperlink href="/games/snake/snake.html" text="Snake" /></li>
                        {/*<li><Hyperlink href="/games/tetris/tetris.html" text="Tetris" /></li>*/}
                        <p></p>
                        <li><Hyperlink href="/games/sudoku" text="Sudoku" /></li>
                        <li><Hyperlink href="/games/haschiwokakero" text="Haschiwokakero" /></li>
                        <p></p>
                        <li><Hyperlink href="/games/brailletrainer" text="Braille Trainer" /></li>
                        <li><Hyperlink href="/games/morsetrainer" text="Morse Trainer" /></li>
                        <li><Hyperlink href="/games/sgatrainer" text="SGA Trainer" /></li>
                    </ul>
                </Card>
            </div>
        </BasePage>
    );
}