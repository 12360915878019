import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import styles from "./BlogAuthor.module.scss";

export type AuthorProps = {
    author: string,
    authorUrl: string,
    authorImageUrl: string,
    authorSocial: string,
    authorSocialUrl: string,
    authorDescription: string
}

export default function BlogAuthor(props: AuthorProps){
    return (
        <div className={styles.authorbox}>
            <img src={props.authorImageUrl} alt="Image of author" className={styles.image} />
            <div className={styles.text}>
                <div>
                    <Hyperlink href={props.authorUrl} text={props.author} />{" "}
                    <Hyperlink href={props.authorSocialUrl} text={props.authorSocial} newTab textStyle />
                </div>
                <div>{props.authorDescription}</div>
            </div>
        </div>
    );
}