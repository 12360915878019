import { useEffect, useState } from "react";
import styles from "./SocialButton.module.scss";

type SocialButtonProps = {
    href: string;
    text: string;
    color: string;
}

export default function SocialButton(props: SocialButtonProps){
    const [uniqueId] = useState(`socialbtn-${Math.random().toString(36).substr(2, 9)}`);
    useEffect(()=>{
        var link = document.getElementById(uniqueId) as HTMLLinkElement;
        link.style.backgroundColor = props.color;
    }, []);
    return (
        <a href={props.href} id={uniqueId} className={styles.link} target="_blank">{props.text}</a>
    );
}