import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./Page404.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import SVGCard from "../../Components/SVGCard/SVGCard";
import { Helmet } from "react-helmet";

export default function Page404(){
    return(
        <BasePage>
            <Helmet>
                <title>404 | Finn Freitag</title>
                <meta name="description" content="Page not found!" />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, personal, 404, page not found" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardHalf}>
                <Card>
                    <div className={styles.innerCard}>
                        <h1 className={styles.fourzerofour}>404</h1>
                        <div className={styles.text}>It seems like this page does not exist!</div>
                    </div>
                </Card>
            </div>
            <SVGCard src="/assets/svgs/error.svg" />
        </BasePage>
    );
}