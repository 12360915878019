import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./JavaToStructogramPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import buttonStyles from "../../Components/Button/Button.module.scss";
import Hyperlink from "../../Components/Hyperlink/Hyperlink";
import { addXMLHeader } from "./converter";
import { getXMLFromJava } from "./converter";
import {Helmet} from 'react-helmet';

export default function JavaToStructogramPage(){
    return(
        <BasePage>
            <Helmet>
                <title>Java to Structogram converter | Finn Freitag</title>
                <meta name="description" content="Java to structogram converter by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, java, structogram, converter, tool" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardDouble}>
                <Card>
                    <textarea className={cardStyles.textboxMonospace} id="textarea" placeholder="Paste code here..." autoFocus />
                </Card>
            </div>
            <div className={cardStyles.cardSingle}>
                <Card>
                    <div className={styles.controlbox}>
                        <h1 className={styles.headline}>Java to <Hyperlink href="https://structorizer.fisch.lu/index.php" text="structogram" newTab textStyle /> converter</h1>
                        <a id="downloadbtn" className={styles.downloadbtn} download="structogram.nsd"><button className={buttonStyles.button} onClick={convert}>Download <i>structogram.nsd</i></button></a>
                    </div>
                </Card>
            </div>
        </BasePage>
    );
}

function convert(){
    var textarea = (document.getElementById("textarea")) as HTMLInputElement;
    var xml = addXMLHeader(getXMLFromJava(textarea.value));
    //textarea.value = xml;
    downloader(xml,"text/xml");
    (document.getElementById("downloadbtn") as HTMLLinkElement).style.display = "block";
}

function downloader(data: string, type: string) {
	let blob = new Blob([data], {type});
	let url = window.URL.createObjectURL(blob);
	(document.getElementById("downloadbtn") as HTMLLinkElement).href = url;
	window.URL.revokeObjectURL(url);
}