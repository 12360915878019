import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./CodeFormatterPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import buttonStyles from "../../Components/Button/Button.module.scss";
import { formatjson, formatjs, formatxml } from "./formatter";
import {Helmet} from 'react-helmet';

export default function CodeFormatterPage(){
    return(
        <BasePage>
            <Helmet>
                <title>JSON Formatter | Finn Freitag</title>
                <meta name="description" content="JSON formatter by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, json, formatter, tool" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardDouble}>
                <Card>
                    <textarea className={cardStyles.textboxMonospace} id="textarea" placeholder="Paste code here..." autoFocus />
                </Card>
            </div>
            <div className={cardStyles.cardSingle}>
                <Card>
                    <div className={styles.controlbox}>
                        <h1 className={styles.headline}>JSON Formatter</h1>
                        <button className={buttonStyles.button} onClick={format}>Format</button>
                    </div>
                </Card>
            </div>
        </BasePage>
    );
}

function format(){
    var textarea = (document.getElementById("textarea")) as HTMLInputElement;
    textarea.value = formatjson(textarea.value);
}