import { Exception } from "sass";

var Radians: boolean = true;
var InsertLostTimesSymbols = true;
var AllowCustomFunctions = true;

export function setRadians(val: boolean){
    Radians = val;
}

export function setInserLostTimesSymbols(val: boolean){
    InsertLostTimesSymbols = val;
}

export function setAllowCustomFunctions(val: boolean){
    AllowCustomFunctions = val;
}

export interface IFormula {
    calc(): number;
    ToString(): string;
    OverwriteVariable(name: string, value: IFormula): void;
    ReplaceVariable(name: string, value: IFormula): void;
    Derive(varName: string): IFormula;
    ContainsVariable(varName: string): boolean;
    Clone(): IFormula;
}

export interface IFunction extends IFormula {
    TryInit(name: string, args: IFormula[]): boolean;
}

export interface IOperator extends IFormula {
    readonly OperatorName: string;
    Formula1: IFormula;
    Formula2: IFormula;
    readonly priority: Priority;
    TryInit(Operator: string, formula1: IFormula, formula2: IFormula): boolean;
}

export interface IBracket extends IFormula {
    readonly OpenSymbol: string;
    readonly CloseSymbol: string;
    TryInit(open: string, close: string, formula: IFormula): boolean;
}

export enum Priority
{
    Dash,
    Dot
}



class FNull implements IFormula {
    Derive(varName: string): IFormula {
        throw new Error("Method not implemented.");
    }
    ContainsVariable(varName: string): boolean {
        throw new Error("Method not implemented.");
    }
    calc(): number {
        throw new Error("Method not implemented.");
    }
    ToString(): string {
        throw new Error("Method not implemented.");
    }
    OverwriteVariable(name: string, value: IFormula): void {
        throw new Error("Method not implemented.");
    }
    ReplaceVariable(name: string, value: IFormula): void {
        throw new Error("Method not implemented.");
    }
    Clone(): IFormula {
        throw new Error("Method not implemented.");
    }
}

export class FNumber implements IFormula {
    num: number = 0;

    calc(): number {
        return this.num;
    }

    ToString(): string {
        return "(" + this.num + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        
    }

    ReplaceVariable(name: string, value: IFormula): void {
        
    }

    Clone(): IFormula {
        var fnum = new FNumber();
        fnum.num = this.num;
        return fnum;
    }

    Derive(varName: string): IFormula {
        return this.Clone();
    }

    ContainsVariable(varName: string): boolean {
        return false;
    }
}

class FVariable implements IFormula {
    content: IFormula = new FNumber();
    symbol: string = "x";

    calc(): number {
        return this.content.calc();
    }

    ToString(): string {
        return "(" + this.symbol + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        if(name.toLowerCase() === this.symbol.toLowerCase()) this.content = value;
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.content instanceof FVariable && (this.content as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.content = value;
    }

    Clone(): IFormula {
        var fvar = new FVariable();
        fvar.symbol = this.symbol;
        fvar.content = this.content.Clone();
        return fvar;
    }

    Derive(varName: string): IFormula {
        return this.Clone();
    }
    
    ContainsVariable(varName: string): boolean {
        return this.symbol.toLowerCase() === varName.toLowerCase();
    }
}

class FSubtraction implements IOperator {
    OperatorName: string = "-";
    Formula1: IFormula = new FNumber();
    Formula2: IFormula = new FNumber();
    priority: Priority = Priority.Dash;

    TryInit(Operator: string, formula1: IFormula, formula2: IFormula): boolean {
        if(this.OperatorName === Operator){
            this.Formula1 = formula1;
            this.Formula2 = formula2;
            return true;
        }
        return false;
    }

    calc(): number {
        return this.Formula1.calc() - this.Formula2.calc();
    }

    ToString(): string {
        return "(" + this.Formula1.ToString() + this.OperatorName + this.Formula2.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.Formula1.OverwriteVariable(name, value);
        this.Formula2.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.Formula1 instanceof FVariable && (this.Formula1 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula1 = value;
        if(this.Formula2 instanceof FVariable && (this.Formula2 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula2 = value;
    }

    Clone(): IFormula {
        var fsub = new FSubtraction();
        fsub.Formula1 = this.Formula1.Clone();
        fsub.Formula2 = this.Formula2.Clone();
        return fsub;
    }

    Derive(varName: string): IFormula {
        if(this.Formula1.ContainsVariable(varName)){
            if(this.Formula2.ContainsVariable(varName)){
                var fsub = new FSubtraction();
                fsub.Formula1 = this.Formula1.Derive(varName);
                fsub.Formula2 = this.Formula2.Derive(varName);
                return fsub;
            }else{
                return this.Formula1.Derive(varName);
            }
        }else{
            if(this.Formula2.ContainsVariable(varName)){
                var fsub = new FSubtraction();
                fsub.Formula1 = new FNumber();
                fsub.Formula2 = this.Formula2.Derive(varName);
                return fsub;
            }else{
                return this.Clone();
            }
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.Formula1.ContainsVariable(varName) || this.Formula2.ContainsVariable(varName);
    }
}

class FAddition implements IOperator {
    OperatorName: string = "+";
    Formula1: IFormula = new FNumber();
    Formula2: IFormula = new FNumber();
    priority: Priority = Priority.Dash;

    TryInit(Operator: string, formula1: IFormula, formula2: IFormula): boolean {
        if(this.OperatorName === Operator){
            this.Formula1 = formula1;
            this.Formula2 = formula2;
            return true;
        }
        return false;
    }

    calc(): number {
        return this.Formula1.calc() + this.Formula2.calc();
    }

    ToString(): string {
        return "(" + this.Formula1.ToString() + this.OperatorName + this.Formula2.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.Formula1.OverwriteVariable(name, value);
        this.Formula2.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.Formula1 instanceof FVariable && (this.Formula1 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula1 = value;
        if(this.Formula2 instanceof FVariable && (this.Formula2 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula2 = value;
    }

    Clone(): IFormula {
        var fadd = new FAddition();
        fadd.Formula1 = this.Formula1.Clone();
        fadd.Formula2 = this.Formula2.Clone();
        return fadd;
    }

    Derive(varName: string): IFormula {
        if(this.Formula1.ContainsVariable(varName)){
            if(this.Formula2.ContainsVariable(varName)){
                var fadd = new FAddition();
                fadd.Formula1 = this.Formula1.Derive(varName);
                fadd.Formula2 = this.Formula2.Derive(varName);
                return fadd;
            }else{
                return this.Formula1.Derive(varName);
            }
        }else{
            if(this.Formula2.ContainsVariable(varName)){
                return this.Formula2.Derive(varName);
            }else{
                return this.Clone();
            }
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.Formula1.ContainsVariable(varName) || this.Formula2.ContainsVariable(varName);
    }
}

class FMultiplication implements IOperator {
    OperatorName: string = "*";
    Formula1: IFormula = new FNumber();
    Formula2: IFormula = new FNumber();
    priority: Priority = Priority.Dot;

    TryInit(Operator: string, formula1: IFormula, formula2: IFormula): boolean {
        if(this.OperatorName === Operator){
            this.Formula1 = formula1;
            this.Formula2 = formula2;
            return true;
        }
        return false;
    }

    calc(): number {
        return this.Formula1.calc() * this.Formula2.calc();
    }

    ToString(): string {
        return "(" + this.Formula1.ToString() + this.OperatorName + this.Formula2.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.Formula1.OverwriteVariable(name, value);
        this.Formula2.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.Formula1 instanceof FVariable && (this.Formula1 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula1 = value;
        if(this.Formula2 instanceof FVariable && (this.Formula2 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula2 = value;
    }

    Clone(): IFormula {
        var fmul = new FMultiplication();
        fmul.Formula1 = this.Formula1.Clone();
        fmul.Formula2 = this.Formula2.Clone();
        return fmul;
    }

    Derive(varName: string): IFormula {
        if(this.Formula1.ContainsVariable(varName)){
            if(this.Formula2.ContainsVariable(varName)){
                var fmul1 = new FMultiplication();
                fmul1.Formula1 = this.Formula1.Derive(varName);
                fmul1.Formula2 = this.Formula2.Clone();
                var fmul2 = new FMultiplication();
                fmul2.Formula1 = this.Formula1.Clone();
                fmul2.Formula2 = this.Formula2.Derive(varName);
                var fadd = new FAddition();
                fadd.Formula1 = fmul1;
                fadd.Formula2 = fmul2;
                return fadd;
            }else{
                var fmul = new FMultiplication();
                fmul.Formula1 = this.Formula1.Derive(varName);
                fmul.Formula2 = this.Formula2.Clone();
                return fmul;
            }
        }else{
            if(this.Formula2.ContainsVariable(varName)){
                var fmul = new FMultiplication();
                fmul.Formula1 = this.Formula1.Clone();
                fmul.Formula2 = this.Formula2.Derive(varName);
                return fmul;
            }else{
                return this.Clone();
            }
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.Formula1.ContainsVariable(varName) || this.Formula2.ContainsVariable(varName);
    }
}

class FDivision implements IOperator {
    OperatorName: string = "/";
    Formula1: IFormula = new FNumber();
    Formula2: IFormula = new FNumber();
    priority: Priority = Priority.Dot;

    TryInit(Operator: string, formula1: IFormula, formula2: IFormula): boolean {
        if(this.OperatorName === Operator){
            this.Formula1 = formula1;
            this.Formula2 = formula2;
            return true;
        }
        return false;
    }

    calc(): number {
        return this.Formula1.calc() / this.Formula2.calc();
    }

    ToString(): string {
        return "(" + this.Formula1.ToString() + this.OperatorName + this.Formula2.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.Formula1.OverwriteVariable(name, value);
        this.Formula2.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.Formula1 instanceof FVariable && (this.Formula1 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula1 = value;
        if(this.Formula2 instanceof FVariable && (this.Formula2 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula2 = value;
    }

    Clone(): IFormula {
        var fdiv = new FDivision();
        fdiv.Formula1 = this.Formula1.Clone();
        fdiv.Formula2 = this.Formula2.Clone();
        return fdiv;
    }

    Derive(varName: string): IFormula {
        if(this.Formula1.ContainsVariable(varName)){
            if(this.Formula2.ContainsVariable(varName)){
                var fmul1 = new FMultiplication();
                fmul1.Formula1 = this.Formula1.Derive(varName);
                fmul1.Formula2 = this.Formula2.Clone();
                var fmul2 = new FMultiplication();
                fmul2.Formula1 = this.Formula1.Clone();
                fmul2.Formula2 = this.Formula2.Derive(varName);
                var fsub = new FSubtraction();
                fsub.Formula1 = fmul1;
                fsub.Formula2 = fmul2;
                var fnum = new FNumber();
                fnum.num = 2;
                var fexp = new FExponentiation();
                fexp.Formula1 = this.Formula2.Clone();
                fexp.Formula2 = fnum;
                var fdiv = new FDivision();
                fdiv.Formula1 = fsub;
                fdiv.Formula2 = fexp;
                return fdiv;
            }else{
                var fnum = new FNumber();
                fnum.num = 1;
                var fdiv = new FDivision();
                fdiv.Formula1 = fnum;
                fdiv.Formula2 = this.Formula2.Clone();
                var fmul = new FMultiplication();
                fmul.Formula1 = this.Formula1.Derive(varName);
                fmul.Formula2 = fdiv;
                return fmul;
            }
        }else{
            if(this.Formula2.ContainsVariable(varName)){
                var fnum = new FNumber();
                fnum.num = -1;
                var fexp = new FExponentiation();
                fexp.Formula1 = this.Formula2.Clone();
                fexp.Formula2 = fnum;
                var fmul = new FMultiplication();
                fmul.Formula1 = this.Formula1.Clone();
                fmul.Formula2 = fexp.Derive(varName);
                return fmul;
            }else{
                return this.Clone();
            }
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.Formula1.ContainsVariable(varName) || this.Formula2.ContainsVariable(varName);
    }
}

class FModulo implements IOperator {
    OperatorName: string = "%";
    Formula1: IFormula = new FNumber();
    Formula2: IFormula = new FNumber();
    priority: Priority = Priority.Dot;

    TryInit(Operator: string, formula1: IFormula, formula2: IFormula): boolean {
        if(this.OperatorName === Operator){
            this.Formula1 = formula1;
            this.Formula2 = formula2;
            return true;
        }
        return false;
    }

    calc(): number {
        return this.Formula1.calc() % this.Formula2.calc();
    }

    ToString(): string {
        return "(" + this.Formula1.ToString() + this.OperatorName + this.Formula2.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.Formula1.OverwriteVariable(name, value);
        this.Formula2.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.Formula1 instanceof FVariable && (this.Formula1 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula1 = value;
        if(this.Formula2 instanceof FVariable && (this.Formula2 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula2 = value;
    }

    Clone(): IFormula {
        var fmod = new FModulo();
        fmod.Formula1 = this.Formula1.Clone();
        fmod.Formula2 = this.Formula2.Clone();
        return fmod;
    }

    Derive(varName: string): IFormula {
        return new FNull();
    }

    ContainsVariable(varName: string): boolean {
        return this.Formula1.ContainsVariable(varName) || this.Formula2.ContainsVariable(varName);
    }
}

class FExponentiation implements IOperator {
    OperatorName: string = "^";
    Formula1: IFormula = new FNumber();
    Formula2: IFormula = new FNumber();
    priority: Priority = Priority.Dot;

    TryInit(Operator: string, formula1: IFormula, formula2: IFormula): boolean {
        if(this.OperatorName === Operator){
            this.Formula1 = formula1;
            this.Formula2 = formula2;
            return true;
        }
        return false;
    }

    calc(): number {
        return Math.pow(this.Formula1.calc(), this.Formula2.calc());
    }

    ToString(): string {
        return "(" + this.Formula1.ToString() + this.OperatorName + this.Formula2.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.Formula1.OverwriteVariable(name, value);
        this.Formula2.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.Formula1 instanceof FVariable && (this.Formula1 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula1 = value;
        if(this.Formula2 instanceof FVariable && (this.Formula2 as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.Formula2 = value;
    }

    Clone(): IFormula {
        var fexp = new FExponentiation();
        fexp.Formula1 = this.Formula1.Clone();
        fexp.Formula2 = this.Formula2.Clone();
        return fexp;
    }

    Derive(varName: string): IFormula {
        throw new Error("Method not implemented.");
    }

    ContainsVariable(varName: string): boolean {
        return this.Formula1.ContainsVariable(varName) || this.Formula2.ContainsVariable(varName);
    }
}

class FSum implements IFunction {
    from: IFormula = new FNumber();
    to: IFormula = new FNumber();
    formula: IFormula = new FNumber();
    symbol: string = "x";

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "sum") return false;
        if(args.length !== 4) return false;
        if(!(args[0] instanceof FVariable)) return false;
        this.symbol = (args[0] as FVariable).symbol;
        this.from = args[1];
        this.to = args[2];
        this.formula = args[3];
        return true;
    }

    calc(): number {
        var res: number = 0;
        for(var counter: number = Math.floor(this.from.calc()); counter <= Math.floor(this.to.calc()); counter++){
            var fnum = new FNumber();
            fnum.num = counter;
            this.formula.OverwriteVariable(this.symbol, fnum);
            res += this.formula.calc();
        }
        return res;
    }

    ToString(): string {
        return "sum(" + this.symbol + ", " + this.from.ToString() + ", " + this.to.ToString() + ", " + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.from.OverwriteVariable(name, value);
        this.to.OverwriteVariable(name, value);
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.from instanceof FVariable && (this.from as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.from = value;
        if(this.to instanceof FVariable && (this.to as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.to = value;
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fsum = new FSum();
        fsum.symbol = this.symbol;
        fsum.from = this.from.Clone();
        fsum.to = this.to.Clone();
        fsum.formula = this.formula.Clone();
        return fsum;
    }

    Derive(varName: string): IFormula {
        return new FNull();
    }

    ContainsVariable(varName: string): boolean {
        return this.from.ContainsVariable(varName) || this.to.ContainsVariable(varName) || this.formula.ContainsVariable(varName);
    }
}

class FProduct implements IFunction {
    from: IFormula = new FNumber();
    to: IFormula = new FNumber();
    formula: IFormula = new FNumber();
    symbol: string = "x";

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "product") return false;
        if(args.length !== 4) return false;
        if(!(args[0] instanceof FVariable)) return false;
        this.symbol = (args[0] as FVariable).symbol;
        this.from = args[1];
        this.to = args[2];
        this.formula = args[3];
        return true;
    }

    calc(): number {
        var res: number = 1;
        for(var counter: number = Math.floor(this.from.calc()); counter <= Math.floor(this.to.calc()); counter++){
            var fnum = new FNumber();
            fnum.num = counter;
            this.formula.OverwriteVariable(this.symbol, fnum);
            res *= this.formula.calc();
        }
        return res;
    }

    ToString(): string {
        return "product(" + this.symbol + ", " + this.from.ToString() + ", " + this.to.ToString() + ", " + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.from.OverwriteVariable(name, value);
        this.to.OverwriteVariable(name, value);
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.from instanceof FVariable && (this.from as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.from = value;
        if(this.to instanceof FVariable && (this.to as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.to = value;
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fpro = new FProduct();
        fpro.symbol = this.symbol;
        fpro.from = this.from.Clone();
        fpro.to = this.to.Clone();
        fpro.formula = this.formula.Clone();
        return fpro;
    }

    Derive(varName: string): IFormula {
        return new FNull();
    }

    ContainsVariable(varName: string): boolean {
        return this.from.ContainsVariable(varName) || this.to.ContainsVariable(varName) || this.formula.ContainsVariable(varName);
    }
}

class FLogarithm implements IFunction {
    base: IFormula = new FNumber();
    num: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name !== "log") return false;
        if(args.length !== 2) return false;
        this.base = args[0];
        this.num = args[1];
        return true;
    }

    calc(): number {
        return Math.log(this.num.calc()) / Math.log(this.base.calc());
    }

    ToString(): string {
        return "log(" + this.base + ", " + this.num + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.base.OverwriteVariable(name, value);
        this.num.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.base instanceof FVariable && (this.base as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.base = value;
        if(this.num instanceof FVariable && (this.num as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.num = value;
    }

    Clone(): IFormula {
        var flog = new FLogarithm();
        flog.base = this.base.Clone();
        flog.num = this.num.Clone();
        return flog;
    }

    Derive(varName: string): IFormula {
        if(this.num.ContainsVariable(varName)){
            if(this.base.ContainsVariable(varName)){
                return new FNull();
            }else{
                var fe = new FNumber();
                fe.num = Math.E;
                var flog = new FLogarithm();
                flog.base = fe;
                flog.num = this.base.Clone();
                var fmul = new FMultiplication();
                fmul.Formula1 = this.num.Clone();
                fmul.Formula2 = flog;
                var fnum = new FNumber();
                fnum.num = 1;
                var fdiv = new FDivision();
                fdiv.Formula1 = fnum;
                fdiv.Formula2 = fmul;
                return fdiv;
            }
        }else{
            if(this.base.ContainsVariable(varName)){
                return new FNull();
            }else{
                return new FNull();
            }
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.base.ContainsVariable(varName) || this.num.ContainsVariable(varName);
    }
}

class FAbsolute implements IFunction {
    value: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name !== "abs") return false;
        if(args.length !== 1) return false;
        this.value = args[0];
        return true;
    }

    calc(): number {
        return Math.abs(this.value.calc());
    }

    ToString(): string {
        return "abs(" + this.value.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.value.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.value instanceof FVariable && (this.value as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.value = value;
    }

    Clone(): IFormula {
        var fabs = new FAbsolute();
        fabs.value = this.value.Clone();
        return fabs;
    }

    Derive(varName: string): IFormula {
        return new FNull();
    }

    ContainsVariable(varName: string): boolean {
        return this.value.ContainsVariable(varName);
    }
}

class FSine implements IFunction {
    formula: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "sin") return false;
        if(args.length !== 1) return false;
        this.formula = args[0];
        return true;
    }

    calc(): number {
        if(Radians){
            return Math.sin(this.formula.calc());
        }else{
            return Math.sin(this.formula.calc() / 180 * Math.PI);
        }
    }

    ToString(): string {
        return "sin(" + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fsin = new FSine();
        fsin.formula = this.formula.Clone();
        return fsin;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            var fcos = new FCosine();
            fcos.formula = this.formula.Clone();
            var fmul = new FMultiplication()
            fmul.Formula1 = this.formula.Derive(varName);
            fmul.Formula2 = fcos;
            return fmul;
        }else{
            return this.Clone();
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FCosine implements IFunction {
    formula: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "cos") return false;
        if(args.length !== 1) return false;
        this.formula = args[0];
        return true;
    }

    calc(): number {
        if(Radians){
            return Math.cos(this.formula.calc());
        }else{
            return Math.cos(this.formula.calc() / 180 * Math.PI);
        }
    }

    ToString(): string {
        return "cos(" + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fcos = new FCosine();
        fcos.formula = this.formula.Clone();
        return fcos;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            var fsin = new FSine();
            fsin.formula = this.formula.Clone();
            var fsub = new FSubtraction();
            fsub.Formula1 = new FNumber();
            fsub.Formula2 = fsin;
            var fmul = new FMultiplication()
            fmul.Formula1 = this.formula.Derive(varName);
            fmul.Formula2 = fsub;
            return fmul;
        }else{
            return this.Clone();
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FTangent implements IFunction {
    formula: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "tan") return false;
        if(args.length !== 1) return false;
        this.formula = args[0];
        return true;
    }

    calc(): number {
        if(Radians){
            return Math.tan(this.formula.calc());
        }else{
            return Math.tan(this.formula.calc() / 180 * Math.PI);
        }
    }

    ToString(): string {
        return "tan(" + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var ftan = new FTangent();
        ftan.formula = this.formula.Clone();
        return ftan;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            var fcos = new FCosine();
            fcos.formula = this.formula.Clone();
            var fnum = new FNumber();
            fnum.num = 1;
            var fnum2 = new FNumber();
            fnum2.num = 2;
            var fexp = new FExponentiation();
            fexp.Formula1 = fcos;
            fexp.Formula2 = fnum2;
            var fdiv = new FDivision();
            fdiv.Formula1 = fnum;
            fdiv.Formula2 = fexp;
            var fmul = new FMultiplication()
            fmul.Formula1 = this.formula.Derive(varName);
            fmul.Formula2 = fdiv;
            return fmul;
        }else{
            return this.Clone();
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FAsine implements IFunction {
    formula: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "asin") return false;
        if(args.length !== 1) return false;
        this.formula = args[0];
        return true;
    }

    calc(): number {
        if(Radians){
            return Math.asin(this.formula.calc());
        }else{
            return Math.asin(this.formula.calc()) / Math.PI * 180;
        }
    }

    ToString(): string {
        return "asin(" + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fasin = new FAsine();
        fasin.formula = this.formula.Clone();
        return fasin;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            var fnum = new FNumber();
            fnum.num = 1;
            var fnum2 = new FNumber();
            fnum2.num = 2;
            var fexp = new FExponentiation();
            fexp.Formula1 = this.formula.Clone();
            fexp.Formula2 = fnum2;
            var fsub = new FSubtraction();
            fsub.Formula1 = fnum;
            fsub.Formula2 = fexp;
            var fsqrt = new FSquareRoot();
            fsqrt.formula = fsub;
            var fdiv = new FDivision();
            fdiv.Formula1 = fnum.Clone();
            fdiv.Formula2 = fsqrt;
            var fmul = new FMultiplication();
            fmul.Formula1 = fdiv;
            fmul.Formula2 = this.formula.Derive(varName);
            return fmul;
        }else{
            return this.Clone();
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FAcosine implements IFunction {
    formula: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "acos") return false;
        if(args.length !== 1) return false;
        this.formula = args[0];
        return true;
    }

    calc(): number {
        if(Radians){
            return Math.acos(this.formula.calc());
        }else{
            return Math.acos(this.formula.calc()) / Math.PI * 180;
        }
    }

    ToString(): string {
        return "acos(" + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var facos = new FAcosine();
        facos.formula = this.formula.Clone();
        return facos;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            var fnum = new FNumber();
            fnum.num = 1;
            var fnum2 = new FNumber();
            fnum2.num = 2;
            var fexp = new FExponentiation();
            fexp.Formula1 = this.formula.Clone();
            fexp.Formula2 = fnum2;
            var fsub = new FSubtraction();
            fsub.Formula1 = fnum;
            fsub.Formula2 = fexp;
            var fsqrt = new FSquareRoot();
            fsqrt.formula = fsub;
            var fdiv = new FDivision();
            fdiv.Formula1 = fnum.Clone();
            fdiv.Formula2 = fsqrt;
            var fsub2 = new FSubtraction();
            fsub2.Formula1 = new FNumber();
            fsub2.Formula2 = fdiv;
            var fmul = new FMultiplication();
            fmul.Formula1 = fsub2;
            fmul.Formula2 = this.formula.Derive(varName);
            return fmul;
        }else{
            return this.Clone();
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FAtangent implements IFunction {
    formula: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "atan") return false;
        if(args.length !== 1) return false;
        this.formula = args[0];
        return true;
    }

    calc(): number {
        if(Radians){
            return Math.atan(this.formula.calc());
        }else{
            return Math.atan(this.formula.calc()) / Math.PI * 180;
        }
    }

    ToString(): string {
        return "atan(" + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fatan = new FAtangent();
        fatan.formula = this.formula.Clone();
        return fatan;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            var fnum = new FNumber();
            fnum.num = 1;
            var fnum2 = new FNumber();
            fnum2.num = 2;
            var fexp = new FExponentiation();
            fexp.Formula1 = this.formula.Clone();
            fexp.Formula2 = fnum2;
            var fadd = new FAddition();
            fadd.Formula1 = fnum;
            fadd.Formula2 = fexp;
            var fdiv = new FDivision();
            fdiv.Formula1 = fnum.Clone();
            fdiv.Formula2 = fadd;
            var fmul = new FMultiplication();
            fmul.Formula1 = fdiv;
            fmul.Formula2 = this.formula.Derive(varName);
            return fmul;
        }else{
            return this.Clone();
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FSquareRoot implements IFunction {
    formula: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "sqrt") return false;
        if(args.length !== 1) return false;
        this.formula = args[0];
        return true;
    }

    calc(): number {
        return Math.sqrt(this.formula.calc());
    }

    ToString(): string {
        return "sqrt(" + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fsqrt = new FSquareRoot();
        fsqrt.formula = this.formula.Clone();
        return fsqrt;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            var fnum = new FNumber();
            fnum.num = 1;
            var fnum2 = new FNumber();
            fnum2.num = 2;
            var fsqrt = new FSquareRoot();
            fsqrt.formula = this.formula.Clone();
            var fmul = new FMultiplication();
            fmul.Formula1 = fnum2;
            fmul.Formula2 = fsqrt;
            var fdiv = new FDivision();
            fdiv.Formula1 = fnum;
            fdiv.Formula2 = fmul;
            var fmul2 = new FMultiplication();
            fmul2.Formula1 = fdiv;
            fmul2.Formula2 = this.formula.Derive(varName);
            return fmul2;
        }else{
            return this.Clone();
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FSquareRootX implements IFunction {
    base: IFormula = new FNumber();
    formula: IFormula = new FNumber();

    TryInit(name: string, args: IFormula[]): boolean {
        if(name.toLowerCase() !== "sqrt") return false;
        if(args.length !== 2) return false;
        this.base = args[0];
        this.formula = args[1];
        return true;
    }

    calc(): number {
        return Math.pow(this.formula.calc(), 1 / this.base.calc());
    }

    ToString(): string {
        return "sqrt(" + this.base.ToString + ", " + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fsqrt = new FSquareRootX();
        fsqrt.formula = this.formula.Clone();
        return fsqrt;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            if(this.base.ContainsVariable(varName)){
                return new FNull();
            }else{
                var fnum = new FNumber();
                fnum.num = 1;
                var fdiv = new FDivision();
                fdiv.Formula1 = fnum;
                fdiv.Formula2 = this.base.Clone();
                var fsub = new FSubtraction();
                fsub.Formula1 = fnum.Clone();
                fsub.Formula2 = this.base.Clone();
                var fdiv2 = new FDivision();
                fdiv2.Formula1 = fsub;
                fdiv2.Formula2 = this.base.Clone();
                var fexp = new FExponentiation();
                fexp.Formula1 = this.formula.Clone();
                fexp.Formula2 = fdiv2;
                var fmul = new FMultiplication();
                fmul.Formula1 = fdiv;
                fmul.Formula2 = fexp;
                return fmul;
            }
        }else{
            if(this.base.ContainsVariable(varName)){
                return new FNull();
            }else{
                return new FNull();
            }
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName) || this.base.ContainsVariable(varName);
    }
}

export class FCustomFunction implements IFunction {
    name: string = "";
    paramNames: string[] = [];
    formula: IFormula = new FNull();
    args: IFormula[] = [];

    TryInit(name: string, args: IFormula[]): boolean {
        if(this.name !== name) return false;
        if(this.paramNames.length !== args.length) return false;
        this.args = args;
        return true;
    }

    calc(): number {
        if(this.args.length != this.paramNames.length) throw new Error("Broken custom function!");
        for(var i = 0; i < this.paramNames.length; i++){
            this.formula.OverwriteVariable(this.paramNames[i], this.args[i]);
        }
        return this.formula.calc();
    }

    ToString(): string {
        return this.formula.ToString();
    }

    OverwriteVariable(name: string, value: IFormula): void {
        for(var i = 0; i < this.args.length; i++){
            this.args[i].OverwriteVariable(name, value);
        }
    }

    ReplaceVariable(name: string, value: IFormula): void {
        for(var i = 0; i < this.args.length; i++){
            if(this.args[i] instanceof FVariable && (this.args[i] as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.args[i] = value;
        }
    }

    Clone(): IFormula {
        var fcus = new FCustomFunction();
        fcus.name = this.name;
        fcus.formula = this.formula.Clone();
        fcus.paramNames = this.paramNames;
        fcus.args = [];
        for(var i = 0; i < this.args.length; i++){
            fcus.args.push(this.args[i].Clone());
        }
        return fcus;
    }

    Derive(varName: string): IFormula {
        return this.formula.Derive(varName);
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FParentheses implements IBracket {
    OpenSymbol: string = "(";
    CloseSymbol: string = ")";
    formula: IFormula = new FNumber();

    TryInit(open: string, close: string, formula: IFormula): boolean {
        if(open !== this.OpenSymbol) return false;
        if(close !== this.CloseSymbol) return false;
        this.formula = formula;
        return true;
    }

    calc(): number {
        return this.formula.calc();
    }

    ToString(): string {
        return "(" + this.formula.ToString() + ")";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var fpar = new FParentheses();
        fpar.formula = this.formula.Clone();
        return fpar;
    }

    Derive(varName: string): IFormula {
        if(this.formula.ContainsVariable(varName)){
            return this.formula.Derive(varName);
        }else{
            return this.Clone();
        }
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FRound implements IBracket {
    OpenSymbol: string = "[";
    CloseSymbol: string = "]";
    formula: IFormula = new FNumber();

    TryInit(open: string, close: string, formula: IFormula): boolean {
        if(open !== this.OpenSymbol) return false;
        if(close !== this.CloseSymbol) return false;
        this.formula = formula;
        return true;
    }

    calc(): number {
        return Math.round(this.formula.calc());
    }

    ToString(): string {
        return "[" + this.formula.ToString() + "]";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var frou = new FRound();
        frou.formula = this.formula.Clone();
        return frou;
    }

    Derive(varName: string): IFormula {
        return new FNull();
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FRoundUp implements IBracket {
    OpenSymbol: string = "⌈";
    CloseSymbol: string = "⌉";
    formula: IFormula = new FNumber();

    TryInit(open: string, close: string, formula: IFormula): boolean {
        if(open !== this.OpenSymbol) return false;
        if(close !== this.CloseSymbol) return false;
        this.formula = formula;
        return true;
    }

    calc(): number {
        return Math.round(this.formula.calc() + 0.5);
    }

    ToString(): string {
        return "⌈" + this.formula.ToString() + "⌉";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var frouu = new FRoundUp();
        frouu.formula = this.formula.Clone();
        return frouu;
    }

    Derive(varName: string): IFormula {
        return new FNull();
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}

class FRoundDown implements IBracket {
    OpenSymbol: string = "⌊";
    CloseSymbol: string = "⌋";
    formula: IFormula = new FNumber();

    TryInit(open: string, close: string, formula: IFormula): boolean {
        if(open !== this.OpenSymbol) return false;
        if(close !== this.CloseSymbol) return false;
        this.formula = formula;
        return true;
    }

    calc(): number {
        return Math.round(this.formula.calc() - 0.5);
    }

    ToString(): string {
        return "⌊" + this.formula.ToString() + "⌋";
    }

    OverwriteVariable(name: string, value: IFormula): void {
        this.formula.OverwriteVariable(name, value);
    }

    ReplaceVariable(name: string, value: IFormula): void {
        if(this.formula instanceof FVariable && (this.formula as FVariable).symbol.toLowerCase() === name.toLowerCase()) this.formula = value;
    }

    Clone(): IFormula {
        var froud = new FRoundDown();
        froud.formula = this.formula.Clone();
        return froud;
    }

    Derive(varName: string): IFormula {
        return new FNull();
    }

    ContainsVariable(varName: string): boolean {
        return this.formula.ContainsVariable(varName);
    }
}



export class Registry{
    static Functions: IFunction[] = [
        new FSum(),
        new FProduct(),
        new FLogarithm(),
        new FAbsolute(),
        new FSine(),
        new FCosine(),
        new FTangent(),
        new FAsine(),
        new FAcosine(),
        new FAtangent(),
        new FSquareRoot(),
        new FSquareRootX(),
    ];
    static CustomFunctions: IFunction[] = [];
    static Operators: IOperator[] = [
        new FSubtraction(),
        new FAddition(),
        new FMultiplication(),
        new FDivision(),
        new FModulo(),
        new FExponentiation(),
    ];
    static Brackets : IBracket[] = [
        new FParentheses(),
        new FRound(),
        new FRoundUp(),
        new FRoundDown(),
    ];
}



export function defineFunction(formula: string): boolean {
    try{
        const abc = "abcdefghijklmnopqrstuvwxyz";

        formula = formula.toLowerCase();
        formula = formula.replaceAll(" ", "");
        formula = formula.replaceAll("\r", "");
        formula = formula.replaceAll("\n", "");
        formula = formula.replaceAll("\t", "");

        if(!formula.includes("=")) return false;
        var parts = formula.split("=");
        if(parts.length !== 2) return false;
        var head = parts[0];
        if(!head.endsWith(")")) return false;
        var form: IFormula = parse(parts[1]);
        var name: string = "";
        for(var i = 0; i < head.length; i++){
            if(abc.includes(head.charAt(i))){
                name += head.charAt(i);
            }else{
                i = head.length;
            }
        }
        if(name.length < 2) return false;
        head = head.slice(name.length);
        if(head.charAt(0) !== "(") return false;
        head = head.slice(1,head.length - 1);
        var params = head.split(",");
        for(var i = 0; i < params.length; i++){
            if(params[i].length !== 1) return false;
        }
        var fcus = new FCustomFunction();
        fcus.formula = form;
        fcus.name = name;
        fcus.paramNames = params;
        Registry.CustomFunctions.push(fcus);
        return true;
    }catch{
        return false;
    }
}

export function parse(formula: string): IFormula {
    try{
        const abc = "abcdefghijklmnopqrstuvwxyz";
        const nums = "1234567890";

        formula = formula.toLowerCase();
        formula = formula.replaceAll(" ", "");
        formula = formula.replaceAll("\r", "");
        formula = formula.replaceAll("\n", "");
        formula = formula.replaceAll("\t", "");
        formula = formula.replaceAll("π", "(" + Math.PI + ")");
        formula = formula.replaceAll("\\pi", "(" + Math.PI + ")");
        formula = formula.replaceAll("\\e", "(" + Math.E + ")");
        formula = formula.replaceAll("\\", "/");
        formula = formula.replaceAll("÷", "/");
        formula = formula.replaceAll("×", "*");
        while(formula.indexOf("?") >= 0){
            formula = formula.replace("?", "(" + Math.random() + ")");
        }

        if(formula.startsWith("-")){
            formula = "0" + formula;
        }

        var opStr: string = "";
        for(var i = 0; i < Registry.Operators.length; i++){
            opStr += Registry.Operators[i].OperatorName;
        }
        var brOpen: string = "";
        var brClose: string = "";
        for(var i = 0; i < Registry.Brackets.length; i++){
            brOpen += Registry.Brackets[i].OpenSymbol;
            brClose += Registry.Brackets[i].CloseSymbol;
        }

        var bracket: number = 0;
        var readingFunc: boolean = false;
        var firstParam: boolean = true;
        var lastCharIndex: number = -2;
        var lastNumberIndex: number = -2;
        var currentNumber: string = "";
        var currentName: string = "";
        var objs: any[] = [];
        for(var i = 0; i < formula.length; i++){
            var cc = formula.charAt(i); // cc for currentCahr
            if(readingFunc){
                if(abc.includes(cc)) currentName += cc;
                if(cc === "("){
                    var bracketOld: number = bracket + 1;
                    var subFormula: string = "";
                    var args: IFormula[] = [];
                    do{
                        if(cc === "") throw new Error("Unknown formula format!");
                        if(cc === "," && bracketOld === bracket){
                            if(firstParam) subFormula = subFormula.slice(1);
                            firstParam = false;
                            args.push(parse(subFormula));
                            subFormula = "";
                        }else{
                            if(cc === "(") bracket++;
                            if(cc === ")") bracket--;
                            subFormula += cc;
                        }
                        i++;
                        cc = formula.charAt(i);
                    } while(bracket >= bracketOld);
                    if(subFormula.startsWith("(")) subFormula = subFormula.substring(1);
                    subFormula = subFormula.slice(0, subFormula.length - 1);
                    args.push(parse(subFormula));
                    i--;
                    cc = formula.charAt(i);
                    var subFormulaObj: IFormula = TryMatchFunction(currentName, args);
                    if(subFormulaObj instanceof FNull && AllowCustomFunctions){
                        subFormulaObj = TryMatchCustomFunction(currentName, args);
                    }
                    objs.push(subFormulaObj);
                    readingFunc = false;
                    currentName = "";
                    firstParam = true;
                }
            }else{
                if(!abc.includes(cc) && lastCharIndex === i - 1){
                    var v = new FVariable();
                    v.symbol = formula.charAt(i - 1);
                    currentName = "";
                    objs.push(v);
                }
                if(!nums.includes(cc) && cc !== "." && lastNumberIndex === i - 1){
                    var n = new FNumber();
                    n.num = ParseNumber(currentNumber);
                    objs.push(n);
                    currentNumber = "";
                }
                if(brOpen.includes(cc)){
                    var open = cc;
                    var close = ")";
                    var bracketOld: number = bracket + 1;
                    var subFormula: string = "";
                    do{
                        if(cc === "") throw new Error("Unknown formula format!");
                        if(brOpen.includes(cc)) bracket++;
                        if(brClose.includes(cc)){
                            bracket--;
                            close = cc;
                        }
                        subFormula += cc;
                        i++;
                        cc = formula.charAt(i);
                    } while(bracket >= bracketOld);
                    subFormula = subFormula.substring(1, subFormula.length - 1);
                    var subFormulaObj: IFormula = TryMatchBracket(open, close, parse(subFormula));
                    if(subFormulaObj instanceof FNull) subFormulaObj = new FNumber();
                    objs.push(subFormulaObj);
                    i--;
                    cc = formula.charAt(i);
                }
                if(opStr.includes(cc)){
                    objs.push(cc);
                }
                if(abc.includes(cc)){
                    currentName += cc;
                    if(lastCharIndex === i - 1){
                        readingFunc = true;
                    }
                    lastCharIndex = i;
                }
                if(nums.includes(cc) || cc === "."){
                    currentNumber += cc;
                    lastNumberIndex = i;
                }
            }
        }
        if(lastNumberIndex === formula.length - 1 && currentNumber !== "") {
            var fnum: FNumber = new FNumber();
            fnum.num = ParseNumber(currentNumber)
            objs.push(fnum);
        }
        if(lastCharIndex === formula.length - 1){
            var fvar: FVariable = new FVariable();
            fvar.symbol = formula.charAt(formula.length - 1)
            objs.push(fvar);
        }

        /*var run: boolean = true;
        while(run){
            var minusIndex: number =  objs.indexOf("-");
            if(minusIndex !== -1){
                var fsub = new FSubtraction();
                fsub.Formula2 = objs[minusIndex + 1] as IFormula;
                if(minusIndex === 0 || (objs[minusIndex - 1] as IFormula) instanceof FNull){
                    objs[minusIndex + 1] = fsub;
                    objs.splice(minusIndex, 1);
                }else{
                    objs[minusIndex + 1] = fsub;
                    objs[minusIndex] = "+";
                }
            }else{
                run = false;
            }
        }*/

        if(InsertLostTimesSymbols){
            var operatorRequired: boolean = false;
            for(var i = 0; i < objs.length; i++){
                if(operatorRequired) {
                    if(typeof objs[i] !== "string"){
                        objs.splice(i, 0, "*");
                    }
                }
                operatorRequired = !operatorRequired;
            }
        }

        var priDot: string[] = [];
        var priDash: string[] = [];
        for(var i = 0; i < Registry.Operators.length; i++){
            if(Registry.Operators[i].priority == Priority.Dot){
                priDot.push(Registry.Operators[i].OperatorName);
            }else{
                priDash.push(Registry.Operators[i].OperatorName);
            }
        }

        var firstDot = FirstIndexOf(objs, priDot);
        while(firstDot !== -1){
            var form: IFormula = TryMatchOperator(objs[firstDot] as string, objs[firstDot - 1] as IFormula, objs[firstDot + 1] as IFormula);
            if(form instanceof FNull) throw new Error("Unknown formula format!");
            objs.splice(firstDot, 2);
            objs[firstDot - 1] = form;
            firstDot = FirstIndexOf(objs, priDot);
        }

        var firstDash = FirstIndexOf(objs, priDash);
        while(firstDash !== -1){
            var form: IFormula = TryMatchOperator(objs[firstDash] as string, objs[firstDash - 1] as IFormula, objs[firstDash + 1] as IFormula);
            if(form instanceof FNull) throw new Error("Unknown formula format!");
            objs.splice(firstDash, 2);
            objs[firstDash - 1] = form;
            firstDash = FirstIndexOf(objs, priDash);
        }
        if(objs.length > 1) throw new Error("Unknown formula format!");
        return objs[0] as IFormula;
    }
    catch (e) {
        throw new Error("Unknown formula format!");
    }
}

function ParseNumber(num: string): number {
    var parts = num.split(".");
    if(parts.length == 1) return +parts[0];
    var before: number = +parts[0];
    var after: number = +parts[1];
    return before + (after / Math.pow(10, parts[1].length));
}

function TryMatchFunction(name: string, args: IFormula[]): IFormula {
    var res: IFormula = new FNull();
    Registry.Functions.forEach(instance => {
        var formula: IFunction = instance.Clone() as IFunction;
        if(formula.TryInit(name, args)) res = formula;
    });
    return res;
}

function TryMatchCustomFunction(name: string, args: IFormula[]): IFormula {
    var res: IFormula = new FNull();
    Registry.CustomFunctions.forEach(instance => {
        var formula: IFunction = instance.Clone() as IFunction;
        if(formula.TryInit(name, args)) res = formula;
    });
    return res;
}

function TryMatchOperator(symbol: string, formula1: IFormula, formula2: IFormula): IFormula {
    var res: IFormula = new FNull();
    Registry.Operators.forEach(instance => {
        var formula: IOperator = instance.Clone() as IOperator;
        if(formula.TryInit(symbol, formula1, formula2)) res = formula;
    });
    return res;
}

function TryMatchBracket(open: string, close: string, content: IFormula): IFormula {
    var res: IFormula = new FNull();
    Registry.Brackets.forEach(instance => {
        var formula: IBracket = instance.Clone() as IBracket;
        if(formula.TryInit(open, close, content)) res = formula;
    });
    return res;
}

function FirstIndexOf(objs: any[], search: any[]): number {
    for(var i = 0; i < objs.length; i++){
        if(search.includes(objs[i])) return i;
    }
    return -1;
}

export function calculate(formula: string): number {
    return parse(formula).calc();
}