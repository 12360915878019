import Card from "../../Components/Card/Card";
import BasePage from "../BasePage/BasePage";
import styles from "./CalculatorPage.module.scss"
import cardStyles from "../../Components/Card/CardStyle.module.scss"
import buttonStyles from "../../Components/Button/Button.module.scss"
import { getHelp } from "./calculatorHelp"
import { FCustomFunction, IFormula, Registry, calculate, defineFunction, parse, setAllowCustomFunctions, setInserLostTimesSymbols, setRadians } from "./parser"
import { useEffect, useState, KeyboardEvent } from "react";
import Button from "../../Components/Button/Button";
import Switch from "../../Components/Switch/Switch";
import { plot } from "./plotter";
import {Helmet} from 'react-helmet';

export default function CalculatorPage(){
    const [hint, setHint] = useState((<span>Click a command button to see an info!</span>));
    const [historyHTML, setHistoryHTML] = useState([(<span>History...</span>)]);
    const [isCommandHintVisible, setIsCommandHintVisible] = useState(true);
    const [isGeneralHintVisible, setIsGeneralHintVisible] = useState(false);
    const [isSettingsVisible, setIsSettingsHintVisible] = useState(false);
    const [history, setHistory] = useState<string[]>([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [first, setFirst] = useState(true);
    const [plotB, setPlotB] = useState(true);
    function enter(value: string, hint: string){
        var formulaTB = document.getElementById("formulaTB") as HTMLTextAreaElement;
        var selStart = formulaTB.selectionStart;
        formulaTB.value = formulaTB.value.substring(0, formulaTB.selectionStart) + value + formulaTB.value.substring(formulaTB.selectionStart);
        formulaTB.selectionStart = selStart + value.length;
        formulaTB.selectionEnd = selStart + value.length;
        setHint(getHelp(hint));
        showCommandHint();
        formulaTB.focus();
    }
    let plotFunction = (formula: IFormula, paramName: string) => {
        return () => plotF(formula, paramName);
    };
    const keydown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
        var formulaTB = document.getElementById("formulaTB") as HTMLTextAreaElement;
        if(e.key === "Enter"){
            var formulaR = formulaTB.value;
            formulaR = formulaR.replaceAll("\n", "");
            formulaR = formulaR.replaceAll("\r", "");
            formulaR = formulaR.replaceAll("\t", "");
            var formulas = formulaR.split(";");
            var allSuccess = true;
            var additions: JSX.Element[] = [];
            for(var i = 0; i < formulas.length; i++){
                var formula = formulas[i];
                if(formula === "") continue;
                var success = false;
                try{
                    if(formula.includes("=")){
                        if(formula.charAt(1) === "("){
                            //historyTB.innerHTML += "Function names must have at least 2 letters!<br /><br />";
                            additions.push(<p>Function names must have at least 2 letters!</p>);
                        }else{
                            if(defineFunction(formula)){
                                var fcus = Registry.CustomFunctions[Registry.CustomFunctions.length - 1] as FCustomFunction;
                                //historyTB.innerHTML += "Definition:<br />" + formula + "<br /><br />";
                                if(fcus.paramNames.length === 1 && plotB){
                                    additions.push(<p>Definition:<br/>{formula}<br/><button className={buttonStyles.button} onClick={plotFunction(fcus.formula, fcus.paramNames[0])}>Plot</button></p>);
                                }else{
                                    additions.push(<p>Definition:<br/>{formula}</p>);
                                }
                                success = true;
                            }else{
                                throw new Error();
                            }
                        }
                    }else{
                        //historyTB.innerHTML += formula + "<br />= " + calculate(formula) + "<br /><br />";
                        additions.push(<p>{formula}<br/>={calculate(formula)}</p>);
                        setHistory(history.concat([formula]));
                        success = true;
                    }
                }catch{
                    //historyTB.innerHTML += "Parsing error!<br /><br />";
                    additions.push(<p>Parsing error!</p>);
                }
                if(success){
                    preventClose();
                }else{
                    allSuccess = false;
                }
            }
            setHistoryHTML(historyHTML.concat(additions));
            if(allSuccess){
                formulaTB.value = "";
            }
            setHistoryIndex(-1);
            formulaTB.focus();
        }
        if(e.key === "ArrowUp"){
            setHistoryIndex(historyIndex + 1);
        }
        if(e.key === "ArrowDown"){
            setHistoryIndex(historyIndex - 1);
        }
    }
    useEffect(()=>{
        var historyTB = document.getElementById("historyTB") as HTMLDivElement;
        historyTB.scrollTop = historyTB.scrollHeight;
    }, [historyHTML]);
    useEffect(()=>{
        var formulaTB = document.getElementById("formulaTB") as HTMLTextAreaElement;
        if(historyIndex >= history.length) setHistoryIndex(history.length - 1);
        if(historyIndex < -1) setHistoryIndex(-1);
        if(historyIndex >= 0){
            formulaTB.value = history[history.length - 1 - historyIndex];
        }else{
            formulaTB.value = "";
        }
    }, [historyIndex]);
    function preventClose(){
        if(!first) return;
        setFirst(false);
        window.addEventListener("beforeunload", function (e) {
            var confirmationMessage = "Are you sure you want to leave this page? Your calculations will be lost!";
    
            (e || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
        });
    }
    function showCommandHint(){
        setIsCommandHintVisible(true);
        setIsGeneralHintVisible(false);
        setIsSettingsHintVisible(false);
        var formulaTB = document.getElementById("formulaTB") as HTMLTextAreaElement;
        formulaTB.focus();
    }
    function showGeneralHint(){
        setIsCommandHintVisible(false);
        setIsGeneralHintVisible(true);
        setIsSettingsHintVisible(false);
        var formulaTB = document.getElementById("formulaTB") as HTMLTextAreaElement;
        formulaTB.focus();
    }
    function showSettingsHint(){
        setIsCommandHintVisible(false);
        setIsGeneralHintVisible(false);
        setIsSettingsHintVisible(true);
        var formulaTB = document.getElementById("formulaTB") as HTMLTextAreaElement;
        formulaTB.focus();
    }
    function plotF(formula: IFormula, variable: string){
        var htb = document.getElementById("historyTB");
        if(htb === null) return;
        var canvElem = document.getElementById("canvasDiv");
        if(canvElem === null) return;
        htb.style.display = "none";
        canvElem.style.display = "block";
        var canv = document.getElementById("graph");
        if(canv !== null) plot(canv as HTMLCanvasElement, formula, variable);
        var formulaTB = document.getElementById("formulaTB") as HTMLTextAreaElement;
        formulaTB.focus();
    }
    function back(){
        var htb = document.getElementById("historyTB");
        if(htb === null) return;
        var canvElem = document.getElementById("canvasDiv");
        if(canvElem === null) return;
        htb.style.display = "block";
        canvElem.style.display = "none";
        var formulaTB = document.getElementById("formulaTB") as HTMLTextAreaElement;
        formulaTB.focus();
    }
    useEffect(() => {
        var canv = document.getElementById("canvasDiv");
        var graph = document.getElementById("graph") as HTMLCanvasElement;
        if(graph === null) return;
        const dpi = window.devicePixelRatio;
        graph.width = graph.clientWidth * dpi;
        graph.height = graph.clientHeight * dpi - 7;
        graph.getContext("2d")?.scale(dpi, dpi);
        if(canv === null) return;
        canv.style.display = "none";
    }, []);
    function showPlotButton(val: boolean){
        setPlotB(val);
    }
    return(
        <BasePage>
            <Helmet>
                <title>Calculator | Finn Freitag</title>
                <meta name="description" content="Advanced calculator by Finn Freitag." />
                <meta name="keywords" content="finn, freitag, finn freitag, finnfreitag, website, SEO, personal, calculator, advanced, tool" />
                <meta property="og:image" content="https://finnfreitag.com/logo512.png" />
                <meta property="og:url" content="https://finnfreitag.com/" />
            </Helmet>
            <div className={cardStyles.cardSingle}>
                <h1 style={{display: "none"}}>Calculator</h1>
                <div className={styles.horizontalCardHolder}>
                    <div className={styles.historyHolder}>
                        <Card>
                            <div className={isCommandHintVisible ? styles.hint : styles.hinthidden} id="commandhelp">
                                {hint}
                            </div>
                            <div className={isGeneralHintVisible ? styles.hint : styles.hinthidden} id="generalhelp">
<strong>Tutorial</strong><br/><br/>
This calculator application offers two input syntax modes for your convenience.<br /><br/>
The first mode allows you to simply input a formula, such as '2*{"("}3+4{")"}', and then press 'Enter' to compute the result. <br /><br/>
Alternatively, the second mode enables you to define custom functions, like 'add{"("}x{")"}=x+5'.
Once defined, you can seamlessly integrate these functions into subsequent formulas.
It's important to note that function names must consist of at least two letters.
                            </div>
                            <div className={isSettingsVisible ? styles.hint : styles.hinthidden} id="settings">
                                <div className={styles.settings}>
                                    Degree / Radians:
                                    <Switch checked onClick={setRadians} />
                                    Set '×'-symbol automatically:
                                    <Switch checked onClick={setInserLostTimesSymbols} />
                                    Allow custom functions:
                                    <Switch checked onClick={setAllowCustomFunctions} />
                                    Show "Plot" button:
                                    <Switch checked onClick={showPlotButton} />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={styles.textboxHolder}>
                        <Card>
                            <div className={styles.menu}>
                                <button className={buttonStyles.button} onClick={showCommandHint}>Command help</button>
                                <button className={buttonStyles.button} onClick={showGeneralHint}>General help</button>
                                <button className={buttonStyles.button} onClick={showSettingsHint}>Settings</button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className={cardStyles.cardSingle}>
                <div className={styles.horizontalCardHolder}>
                    <div className={styles.historyHolder}>
                        <Card>
                            <div className={cardStyles.textboxMonospace} id="historyTB">
                                {historyHTML.map((value)=>{return value;})}
                            </div>
                            <div className={cardStyles.filling} id="canvasDiv">
                                <div className={styles.graphHolder}>
                                    <canvas id="graph" className={styles.canvas}></canvas>
                                    <div className={styles.back}>
                                        <button className={buttonStyles.button} onClick={back}>{"<-"}</button>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={styles.textboxHolder}>
                        <Card>
                            <textarea className={cardStyles.textboxMonospace} id="formulaTB" placeholder="Enter formula..." autoFocus onKeyUp={keydown} />
                        </Card>
                    </div>
                </div>
            </div>
            <div className={cardStyles.cardSingle}>
                <div className={styles.maxheight}>
                    <Card>
                        <div className={styles.scroll}>
                            <div className={styles.buttonRasterColumn}>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("1", "1")}>1</div>
                                    <div className={buttonStyles.button} onClick={() => enter("2", "2")}>2</div>
                                    <div className={buttonStyles.button} onClick={() => enter("3", "3")}>3</div>
                                    <div className={buttonStyles.button} onClick={() => enter("4", "4")}>4</div>
                                    <div className={buttonStyles.button} onClick={() => enter("5", "5")}>5</div>
                                </div>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("6", "6")}>6</div>
                                    <div className={buttonStyles.button} onClick={() => enter("7", "7")}>7</div>
                                    <div className={buttonStyles.button} onClick={() => enter("8", "8")}>8</div>
                                    <div className={buttonStyles.button} onClick={() => enter("9", "9")}>9</div>
                                    <div className={buttonStyles.button} onClick={() => enter("0", "0")}>0</div>
                                </div>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("+", "+")}>+</div>
                                    <div className={buttonStyles.button} onClick={() => enter("-", "-")}>-</div>
                                    <div className={buttonStyles.button} onClick={() => enter("*", "*")}>×</div>
                                    <div className={buttonStyles.button} onClick={() => enter("/", "/")}>÷</div>
                                    <div className={buttonStyles.button} onClick={() => enter("%", "%")}>mod</div>
                                </div>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("sin(", "sin")}>sin{"("}▯{")"}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("cos(", "cos")}>cos{"("}▯{")"}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("tan(", "tan")}>tan{"("}▯{")"}</div>
                                </div>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("asin(", "asin")}>sin<sup>-1</sup>{"("}▯{")"}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("acos(", "acos")}>cos<sup>-1</sup>{"("}▯{")"}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("atan(", "atan")}>tan<sup>-1</sup>{"("}▯{")"}</div>
                                </div>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("^", "^")}>▯<sup>▯</sup></div>
                                    <div className={buttonStyles.button} onClick={() => enter("sqrt(", "sqrt")}>√{"("}▯{")"}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("log(", "log")}>log<sub>▯</sub>{"("}▯{")"}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("abs(", "abs")}>|▯|</div>
                                    <div className={buttonStyles.button} onClick={() => enter("sum(", "sum")}>∑{"("}▯,▯,▯,▯{")"}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("product(", "product")}>∏{"("}▯,▯,▯,▯{")"}</div>
                                </div>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("π", "π")}>π</div>
                                    <div className={buttonStyles.button} onClick={() => enter("\\e", "e")}>e</div>
                                    <div className={buttonStyles.button} onClick={() => enter("?", "?")}>?</div>
                                </div>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("(", "()")}>{"("}</div>
                                    <div className={buttonStyles.button} onClick={() => enter(")", "()")}>{")"}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("[", "[]")}>{"["}</div>
                                    <div className={buttonStyles.button} onClick={() => enter("]", "[]")}>{"]"}</div>
                                </div>
                                <div className={styles.buttonRaster}>
                                    <div className={buttonStyles.button} onClick={() => enter("⌊", "⌊⌋")}>⌊</div>
                                    <div className={buttonStyles.button} onClick={() => enter("⌋", "⌊⌋")}>⌋</div>
                                    <div className={buttonStyles.button} onClick={() => enter("⌈", "⌈⌉")}>⌈</div>
                                    <div className={buttonStyles.button} onClick={() => enter("⌉", "⌈⌉")}>⌉</div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </BasePage>
    );
}